import React, { useState, useCallback, useEffect, useRef } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import "bootstrap/dist/css/bootstrap.min.css";
import url from "assets/js/config.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import BugReport from "@material-ui/icons/BugReport";
import Select from "react-select";
import $ from "jquery";
import MicroModal from "micromodal";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

var ht;
var is_catering;
var catering_type;
var capacity;
var that;
var business_description;
var establishment_type;
var specialization_area;
var alt_msisdn;
var email_id;
var website;
var no_of_employees;
var year_of_establishment;
var business_name;
var state;
var address;
var address1;
var name;
var images1;
var msisdn;
var experience_area;
var city;
var locality;
var pin;
var is_catering = "no";
var select_options;
var grand_data;

var job_title;
var skill_set;
var experience;
var locality;
var city;

const mystyle = {
  width: "250px",
  fontSize: "14px",
};
const mystyle1 = {
  fontSize: "14px",
};
const FileInput = (props) => (
  <input type="file" name="file" multiple value={props.value} />
);

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

var form_value = 0;

var that;
export default class Welcome extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    document
      .querySelector("#btnPostDailypurchase")
      .addEventListener("click", function(e) {
        document.querySelector("#tblGetDailypurchase").style.display = "none";
        document.querySelector("#tblPostDailypurchase").style.display = "block";
        document.querySelector("#btnBack").style.display = "block";
      });

    document.querySelector("#btnBack").addEventListener("click", function(e) {
      document.querySelector("#tblGetDailypurchase").style.display = "block";
      document.querySelector("#tblPostDailypurchase").style.display = "none";
      document.querySelector("#btnBack").style.display = "none";
    });
  }

  render() {
    return (
      <div>
        <button
          className="btn btn-primary"
          id="btnBack"
          style={{
            float: "left",
            fontSize: "18px",
            marginBottom: "20px",
            display: "none",
          }}
        >
          Back
        </button>
        <button
          className="btn btn-primary"
          id="btnPostDailypurchase"
          style={{ float: "right", fontSize: "18px", marginBottom: "20px" }}
        >
          Add Purchase Details
        </button>
        <br />
        <br />
        <div id="tblGetDailypurchase" style={{ display: "block" }}>
          <Table />
        </div>
        <div id="tblPostDailypurchase" style={{ display: "none" }}>
          <Post_dailypurchasedetails />
        </div>
      </div>
    );
  }
}

function VendorName() {
  const [open, setOpen] = React.useState(false);

  const [options, setOptions] = React.useState([]);

  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      var ootel_auth_token = localStorage.getItem("ootel_auth_token");

      var msisdn = localStorage.getItem("ootel_msisdn");

      var data = {
        auth_token: ootel_auth_token,

        column: "id",

        direction: "desc",

        length: "1000",

        role: "hotel_owner",

        msisdn: msisdn,

        start: "0",

        search: "",
      };

      const response = await fetch(url.server_url + "/get_myvendors", {
        method: "POST",

        headers: { "Content-Type": "application/json" },

        body: JSON.stringify(data),
      });

      await sleep(1e3);

      const vendor = await response.json();

      if (active) {
        setOptions(vendor.data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="vendor_name"
      style={{ width: 350 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) =>
        option.vendor_name === value.vendor_name
      }
      getOptionLabel={(option) => option.vendor_name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter Name..."
          variant="outlined"
          InputProps={{
            ...params.InputProps,

            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    backgroundColor="white"
                    size={20}
                  />
                ) : null}

                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

class Post_dailypurchasedetails extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    // new get_multi().get_data(function(data) {
    //   grand_data = data;
    // });

    try {
      document
        .querySelector("#add_dailypurchase")
        .addEventListener("click", function(e) {
          e.preventDefault();

          var purchase_date = document.querySelector("#purchase_date").value;
          var item_name = document.querySelector("#item_name").value;
          var vendor_name = document.querySelector("#vendor_name").value;
          var quantity = document.querySelector("#quantity").value;
          var unit_rate = document.querySelector("#unit_rate").value;
          var total = document.querySelector("#total").value;
          var purchase_date = document.querySelector("#purchase_date").value;
          var payment = document.querySelector("#payment").value;
          var payment_date = document.querySelector("#payment_date").value;
          var payment_remarks = document.querySelector("#payment_remarks")
            .value;
          var balance = 0;
          if (Number(payment) > 0) {
            balance = Number(total) - Number(payment);
          } else {
            balance = Number(total);
          }
          try {
            if (
              purchase_date == "" ||
              item_name == "" ||
              vendor_name == "" ||
              quantity == "" ||
              unit_rate == "" ||
              total == ""
            ) {
              alert("All fields are Required");
              return;
            }
          } catch (e) {
            alert("All fields are Required");
            return;
          }

          var d = {
            purchase_date: purchase_date,
            item_name: item_name,
            vendor_name: vendor_name,
            quantity: quantity,
            unit_rate: unit_rate,
            total: total,
            dailypurchaseid: 0,
            payment: payment,
            payment_date: payment_date,
            payment_remarks: payment_remarks,
            balance: balance,
          };
          var x = new add(d);
          x.get_data();
        });
    } catch (e) {}
  }
  render() {
    return (
      <div>
        <h6>Daily Purchase Details</h6>

        <GridContainer>
          <GridItem xs={12} sm={6} md={5}>
            <table
              id="dailypurchase_table"
              border="0"
              style={{ width: "100%", fontSize: "13px" }}
            >
              <thead>
                <tr>
                  <th>
                    Date{" "}
                    <span
                      style={{
                        fontSize: "22px",
                      }}
                    >
                      *
                    </span>{" "}
                    :
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="date" id="purchase_date" />
                  </th>
                </tr>
                <br />
                <tr>
                  <th>
                    Item Name{" "}
                    <span
                      style={{
                        fontSize: "22px",
                      }}
                    >
                      *
                    </span>{" "}
                    :
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="text"
                      id="item_name"
                      placeholder="Enter Name..."
                      style={{ width: "280px" }}
                    />
                  </th>
                </tr>
                <br />
                <tr>
                  <th>
                    Vendor Name{" "}
                    <span
                      style={{
                        fontSize: "22px",
                      }}
                    >
                      *
                    </span>{" "}
                    :
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* <input
                      type="text"
                      id="vendor_name"
                      placeholder="Enter Name..."
                      style={{ width: "280px" }}
                    /> */}
                    <div
                      id="divClass"
                      width="280px"
                      style={{
                        float: "right",

                        marginRight: "70PX",

                        marginTop: "-20px",
                        width: "280px",
                        backgroundColor: "#ffffff !important",
                      }}
                    >
                      <VendorName />
                    </div>
                  </th>
                </tr>
                <br />
                <tr>
                  <th>
                    Quantity{" "}
                    <span
                      style={{
                        fontSize: "22px",
                      }}
                    >
                      *
                    </span>{" "}
                    :
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="text"
                      id="quantity"
                      placeholder="Enter Quantity..."
                      style={{ width: "280px" }}
                    />
                  </th>
                </tr>
                <br />
                <tr>
                  <th>
                    Unit Rate :
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="text"
                      id="unit_rate"
                      placeholder="Enter Rate..."
                      style={{ width: "280px" }}
                    />
                  </th>
                </tr>
                <br />
              </thead>
              <tbody></tbody>
            </table>
          </GridItem>
          <GridItem xs={12} sm={6} md={1}></GridItem>
          <GridItem xs={12} sm={6} md={5}>
            <table
              id="dailypurchase_table"
              border="0"
              style={{ width: "100%", fontSize: "13px" }}
            >
              <thead>
                <tr>
                  <th>
                    Total{" "}
                    <span
                      style={{
                        fontSize: "22px",
                      }}
                    >
                      *
                    </span>{" "}
                    :
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="text"
                      id="total"
                      placeholder="Enter Total Amount..."
                      style={{ width: "280px" }}
                    />
                  </th>
                </tr>

                <br />
                <tr>
                  <th>
                    Payment :
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="text"
                      id="payment"
                      placeholder="Enter Paying Amount..."
                      style={{ width: "280px" }}
                    />
                  </th>
                </tr>
                <br />
                <tr>
                  <th>
                    Payment Date :
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="date" id="payment_date" />
                  </th>
                </tr>
                <br />
                <tr>
                  <th>
                    Payment Remarks :
                    <textarea
                      id="payment_remarks"
                      className="form-control"
                      placeholder="Enter Remarks..."
                      style={{
                        width: "280px",
                        float: "right",
                        marginRight: "10px",
                      }}
                    />
                  </th>
                </tr>
                <br />
                <tr>
                  <th style={{ paddingTop: "32px", paddingLeft: "150px" }}>
                    <button
                      className="btn btn-primary"
                      id="add_dailypurchase"
                      style={{ width: "150px", fontSize: "16px" }}
                    >
                      Post
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

var d;
class App extends React.Component {
  constructor(props) {
    super(props);
    try {
      for (var i = 0; i < props.data.length; i++) {
        props.data[i].label = props.data[i].name;
      }
    } catch (e) {}

    this.options = props.data;
    this.item = "";
    that = this;
  }
  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    d = selectedOption;
    console.log(`Option selected:`, selectedOption);
    try {
      document.querySelector("#purchase_date").value =
        selectedOption.purchase_date;
      document.querySelector("#item_name").value = selectedOption.item_name;
      document.querySelector("#vendor_name").value = selectedOption.vendor_name;
      document.querySelector("#quantity").value = selectedOption.quantity;
      document.querySelector("#unit_rate").value = selectedOption.unit_rate;
      document.querySelector("#total").value = selectedOption.total;
      document.querySelector("#payment").value = selectedOption.payment;
      document.querySelector("#payment_date").value =
        selectedOption.payment_date;
      document.querySelector("#payment_remarks").value =
        selectedOption.payment_remarks;
    } catch (e) {}
  };
  get_data() {
    return d;
  }
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.options}
      />
    );
  }
}
class add extends React.Component {
  constructor(props) {
    super(props);
    this.purchase_date = props.purchase_date;
    this.item_name = props.item_name;
    this.vendor_name = props.vendor_name;
    this.quantity = props.quantity;
    this.unit_rate = props.unit_rate;
    this.total = props.total;
    this.dailypurchaseid = props.dailypurchaseid;
    this.balance = props.balance;
    this.payment = props.payment;
    this.payment_date = props.payment_date;
    this.payment_remarks = props.payment_remarks;
  }
  get_data() {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var msisdn = localStorage.getItem("ootel_msisdn");
    var postData = {
      purchase_date: this.purchase_date,
      item_name: this.item_name,
      vendor_name: this.vendor_name,
      msisdn: msisdn,
      auth_token: ootel_auth_token,
      quantity: this.quantity,
      date: this.date,
      unit_rate: this.unit_rate,
      total: this.total,
      dailypurchase_id: this.dailypurchaseid,
      balance: this.balance,
      payment: this.payment,
      payment_date: this.payment_date,
      payment_remarks: this.payment_remarks,
    };
    // alert(postData);
    const rawResponse = fetch(url.server_url + "/add_post_dailypurchase", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((data) => {
        try {
          if (data.error.length != 0) {
            alert(data.error);
            return;
          }
        } catch (e) {}
        alert(data.data);
        document.location.reload();
      })
      .catch((err) => {
        console.error("Error: ", err);
      });
  }
}

class get_search_data extends React.Component {
  constructor(page, per_page, column, direction, search) {
    super(page, per_page, column, direction, search);

    this.search = search;
    this.column = column;
    this.direction = direction;
    this.per_page = per_page;
    this.page = page;

    that = this;
  }

  get_data() {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var msisdn = localStorage.getItem("ootel_msisdn");
    var role = localStorage.getItem("ootel_role");

    var search_value;

    var postData = {
      auth_token: ootel_auth_token,
      search: this.search,
      column: this.column,
      direction: this.direction,
      per_page: this.per_page,
      role: "hotel_owner",
      msisdn: msisdn,
      page: this.page,
    };
    var tab = $("#table").DataTable();
    try {
      tab.destroy();
    } catch (e) {}

    tab = $("#table").DataTable({
      processing: true,
      serverSide: true,
      bPaginate: true, // Pagination True
      sPaginationType: "full_numbers", // And its type.
      iDisplayLength: 10,
      //Initial no order.
      search: {
        search: "",
      },
      ajax: {
        //  "url": "get_completed_tests_for_print_backup.php",
        url: url.server_url + "/get_post_dailypurchase",

        type: "POST",
        dataType: "JSON",
        data: {
          auth_token: ootel_auth_token,
          column: this.column,
          direction: this.direction,
          per_page: this.per_page,
          role: "hotel_owner",
          msisdn: msisdn,
          page: this.page,
        },

        dataSrc: function(json1) {
          var return_data = new Array();
          var data = json1.data;
          var data1 = json1.data1;
          var k = 1;
          for (var i = 0; i < data.length; i++) {
            return_data.push({
              sino: i + 1,
              date: data[i].post_date,
              item_name: data[i].item_name,
              vendor_name: data[i].vendor_name,
              quantity: data[i].quantity,
              unit_rate: data[i].unit_rate,
              total: data[i].total,
              payment: data[i].payment,
              balance:
                Number(data[i].balance) >= 0
                  ? data[i].balance
                  : "<button class='btn btn-primary payment' action='adjustment' dailypurchaseid='" +
                    data[i].id +
                    "' balance = '" +
                    data[i].balance +
                    "' vendor='" +
                    data[i].vendor_name +
                    "'>Adjust (" +
                    data[i].balance +
                    ")</button>",
              action:
                (Number(data[i].balance) > 0
                  ? "<button class='btn btn-primary payment' action='payment' dailypurchaseid='" +
                    data[i].id +
                    "' balance = '" +
                    data[i].balance +
                    "'>Payment</button>  "
                  : "") +
                "         <button class='btn btn-primary payment_history' action='payment_history' dailypurchaseid='" +
                data[i].id +
                "'>Payment History</button>",

              recordsTotal: 11,

              recordsFiltered: 11,
            });
          }
          $("#table11_filter")
            .find("input")
            .css({ width: "700px", "margin-left": "-80%" });
          $("#table11_filter")
            .find("input")
            .attr("placeholder", "Search  Item");

          return return_data;
        },
      },
      createdRow: function(row, data, index) {},
      columnDefs: [
        {
          targets: [0, 1], //first column / numbering column
          orderable: false, //set not orderable
        },
      ],
      columns: [
        { data: "sino" },
        { data: "date" },
        { data: "item_name" },
        { data: "vendor_name" },
        { data: "quantity" },
        { data: "unit_rate" },
        { data: "total" },
        { data: "payment" },
        { data: "balance" },
        { data: "action" },
      ],
    });
  }
}

class Table extends React.Component {
  componentDidMount() {
    var c = new get_search_data("1", "10", "id", "desc");
    c.get_data();

    MicroModal.init({
      onShow: (modal) => console.info("${modal.id} is shown"),
      onClose: (modal) => console.info("${modal.id} is hidden"),
      openTrigger: "data-custom-open", // [3]
      closeTrigger: "data-custom-close", // [4]
      disableScroll: true, // [5]
      disableFocus: false, // [6]
      awaitOpenAnimation: false, // [7]
      awaitCloseAnimation: false, // [8]
      debugMode: true, // [9]
    });
    document.querySelector("#table").addEventListener("click", function(e) {
      var dailypurchaseid = e.target.getAttribute("dailypurchaseid");
      var action = e.target.getAttribute("action");
      if (
        dailypurchaseid != null &&
        (action == "payment" || action == "adjustment")
      ) {
        document.querySelector("#balance1").value = e.target.getAttribute(
          "balance"
        );
        document
          .querySelector("#modal10Fields")
          .setAttribute("dailypurchaseid", dailypurchaseid);
        document.querySelector("#modal10Fields").setAttribute("action", action);
        if (action == "payment") {
          document.querySelector("#divPayTo").style.display = "none";
        } else if (action == "adjustment") {
          document.querySelector("#divPayTo").style.display = "block";
          var ootel_auth_token = localStorage.getItem("ootel_auth_token");
          var msisdn = localStorage.getItem("ootel_msisdn");
          var vendor = e.target.getAttribute("vendor");
          var postData = {
            msisdn: msisdn,
            auth_token: ootel_auth_token,
            daily_purchase_id: dailypurchaseid,
            ispayment: 0,
            vendor: vendor,
          };
          const rawResponse = fetch(
            url.server_url + "/get_dailypurchase_payments",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(postData),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              try {
                if (data.error.length != 0) {
                  alert(data.error);
                  return;
                }
              } catch (e) {}
              //Add options
              var data = data.data;
              document.querySelector("#optPayTo").innerHTML = "";
              document
                .querySelector("#optPayTo")
                .append(new Option("<--Select-->", "0"));
              for (var i = 0; i < data.length; i++) {
                document
                  .querySelector("#optPayTo")
                  .append(
                    new Option(
                      data[i].payment_date +
                        "-" +
                        data[i].vendor_name +
                        "-" +
                        data[i].item_name +
                        "- Balance:" +
                        data[i].balance,
                      data[i].id
                    )
                  );
              }
            })
            .catch((err) => {
              console.error("Error: ", err);
            });
        }
        MicroModal.show("modal-10");
      } else if (dailypurchaseid != null && action == "payment_history") {
        var ootel_auth_token = localStorage.getItem("ootel_auth_token");
        var msisdn = localStorage.getItem("ootel_msisdn");

        var tab = $("#tblPaymenthistory").DataTable();
        try {
          tab.destroy();
        } catch (e) {}

        tab = $("#tblPaymenthistory").DataTable({
          processing: true,
          serverSide: true,
          searching: false,
          paging: false,
          info: false,
          ajax: {
            url: url.server_url + "/get_dailypurchase_payments",
            type: "POST",
            dataType: "JSON",
            data: {
              auth_token: ootel_auth_token,
              msisdn: msisdn,
              daily_purchase_id: dailypurchaseid,
              ispayment: 1,
            },

            dataSrc: function(result) {
              var return_data = new Array();
              var data = result.data;
              for (var i = 0; i < data.length; i++) {
                return_data.push({
                  sino: i + 1,
                  paymentdate: data[i].payment_date,
                  payment: data[i].payment,
                  remarks: data[i].payment_remarks,
                });
              }
              return return_data;
            },
          },
          createdRow: function(row, data, index) {},
          columnDefs: [
            {
              targets: [0, 1], //first column / numbering column
              orderable: false, //set not orderable
            },
          ],
          columns: [
            { data: "sino" },
            { data: "paymentdate" },
            { data: "payment" },
            { data: "remarks" },
          ],
        });

        MicroModal.show("modal-12");
      }
    });
    document
      .querySelector("#btnPaymentClose")
      .addEventListener("click", function(e) {
        e.preventDefault();
        MicroModal.close("modal-10");
      });

    
    document
      .querySelector("#export_report")
      .addEventListener("click", function (e) {
        e.preventDefault();

        
          var ootel_auth_token = localStorage.getItem("ootel_auth_token");
          var msisdn = localStorage.getItem("ootel_msisdn");

          var csvData = [];
          var fileName = "Daily Purchase"

          var postData = {
              auth_token: ootel_auth_token,
              column: "id",
              search: "",
              direction: "desc",
              length: 1,
              role: "hotel_owner",
              msisdn: msisdn,
              start: 0,
              page: 1,  
              per_page: 1,
              isExport: "Y",
          };
       
          const rawResponse1 = fetch(url.server_url + "/get_post_dailypurchase", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }).then((res) => res.json())
            .then((result) => {
          
              var data = result.data;
              var data1 = result.data1;
              // alert(data.length);
                for (var i = 0; i < data.length; i++) {
                  csvData.push({
                        SiNo: i + 1,
                        Date: data[i].post_date,
                        ItemName: data[i].item_name,
                        VendorName: data[i].vendor_name,
                        Quantity: data[i].quantity,
                        UnitRate: data[i].unit_rate,
                        Total: data[i].total,
                        Payment: data[i].payment,
                  });
                          
                };
         
              console.log(csvData);
                const fileType =
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const fileExtension = ".xlsx";

                const ws = XLSX.utils.json_to_sheet(csvData);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, {
                  bookType: "xlsx",
                  type: "array",
                });
                const exceldata = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(exceldata, fileName + fileExtension);
              
            }).catch((err) => {
              console.error("Error: ", err);
            });
        
      });
    
    
    document
      .querySelector("#btnSubmitPayment")
      .addEventListener("click", function(e) {
        e.preventDefault();

        var payment = document.querySelector("#payment1").value;
        var payment_date = document.querySelector("#payment_date1").value;

        try {
          if (payment == "" || payment_date == "") {
            alert("Payment and date fields are mandatory");
            return;
          }
          var action = document
            .querySelector("#modal10Fields")
            .getAttribute("action");
          var daily_purchase_from_id = document
            .querySelector("#modal10Fields")
            .getAttribute("dailypurchaseid");
          var daily_purchase_to_id = document
            .querySelector("#modal10Fields")
            .getAttribute("dailypurchaseid");
          var payment_remarks = document.querySelector("#payment_remarks1")
            .value;
          var old_balance = document.querySelector("#balance1").value;
          var balance = 0;
          var balance1 = 0;
          if (Number(payment) > 0) {
            balance =
              action == "payment"
                ? Number(old_balance) - Number(payment)
                : Number(old_balance) + Number(payment);
          }
          if (action == "adjustment") {
            daily_purchase_to_id = document.querySelector("#optPayTo").value;
            var drpDown = document.getElementById("optPayTo");
            var old_balance1 = drpDown.options[
              drpDown.selectedIndex
            ].text.split(":")[1];
            if (Number(payment) > 0) {
              balance1 = Number(old_balance1) - Number(payment);
            }
          }
        } catch (e) {
          alert("All fields are Required");
          return;
        }

        var ootel_auth_token = localStorage.getItem("ootel_auth_token");
        var msisdn = localStorage.getItem("ootel_msisdn");
        var postData = {
          msisdn: msisdn,
          auth_token: ootel_auth_token,
          daily_purchase_from_id: daily_purchase_from_id,
          daily_purchase_to_id: daily_purchase_to_id,
          balance: balance,
          payment: payment,
          payment_date: payment_date,
          payment_remarks: payment_remarks,
          balance1: balance1,
        };
        const rawResponse = fetch(
          url.server_url + "/add_dailypurchase_payments",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }
        )
          .then((res) => res.json())
          .then((data) => {
            try {
              if (data.error.length != 0) {
                alert(data.error);
                return;
              }
            } catch (e) {}
            alert(data.data);
            document.location.reload();
          })
          .catch((err) => {
            console.error("Error: ", err);
          });
      });
  }

  render() {
    return (
      <div className="cl">
        <table
          className="table table-striped table-bordered"
          id="table"
          width="0px"
        >
          <thead style={{ width: "0px" }}>
            <tr style={{ backgroundColor: "#8fcbcd", width: "0px" }}>
              <th>Si No</th>
              <th>Post Date</th>
              <th>Item Name</th>
              <th>Vendor Name</th>
              <th>Quantity</th>
              <th>Unit Rate</th>
              <th>Total</th>
              <th>Paid</th>
              <th>Balance</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>

        <br />
        <br />
        <button
          id="export_report"
          className="btn btn-primary"
          style={{ width: "110px", fontSize: "15px", float: "right" }}
        >
          Export Excel
        </button>
        <br />
        <br />
        <div
          className="modal micromodal-slide"
          id="modal-10"
          aria-hidden="true"
        >
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              style={{ width: "100%" }}
              className="modal__container"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-10-title"
            >
              <header className="modal__header">
                <h4 v="modal__title" id="modal-10-title">
                  Payment
                </h4>
                <button
                  className="modal__close"
                  aria-label="Close modal"
                  data-micromodal-close
                ></button>
              </header>
              <main className="modal__content" id="modal-10-content">
                <form id="Payment_form" method="post">
                  <fieldset id="modal10Fields">
                    <br />
                    Balance :
                    <br />
                    <input
                      type="text"
                      id="balance1"
                      className="form-control"
                      disabled="disabled"
                    />
                    <br />
                    <div id="divPayTo">
                      Pay To:
                      <br />
                      <select id="optPayTo" className="form-control">
                        <option value="0">--select---</option>
                      </select>
                      <br />
                    </div>
                    Payment:
                    <br />
                    <input type="text" id="payment1" className="form-control" />
                    <br />
                    Payment Date :
                    <br />
                    <input
                      type="date"
                      id="payment_date1"
                      className="form-control"
                    />
                    <br />
                    <br />
                    Payment Remarks :
                    <br />
                    <input
                      type="text"
                      id="payment_remarks1"
                      className="form-control"
                    />
                    <br />
                  </fieldset>
                  <button class="btn btn-primary" id="btnSubmitPayment">
                    Submit
                  </button>
                  <button class="btn btn-primary" id="btnPaymentClose">
                    Close
                  </button>
                </form>
              </main>
            </div>
          </div>
        </div>

        <div
          className="modal micromodal-slide"
          id="modal-12"
          aria-hidden="true"
        >
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              style={{ width: "100%" }}
              className="modal__container"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-12-title"
            >
              <header className="modal__header">
                <h4 v="modal__title" id="modal-12-title">
                  Payment History
                </h4>
                <button
                  className="modal__close"
                  aria-label="Close modal"
                  data-micromodal-close
                ></button>
              </header>
              <main className="modal__content" id="modal-12-content">
                <table
                  className="table table-striped table-bordered"
                  id="tblPaymenthistory"
                >
                  <thead>
                    <tr style={{ backgroundColor: "#8fcbcd" }}>
                      <th>Si No</th>
                      <th>Date</th>
                      <th>Paid</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </main>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
class clk {
  hasClass(elem, className) {
    try {
      return elem.className.split(" ").indexOf(className) > -1;
    } catch (e) {
      return -1;
    }
  }
}
