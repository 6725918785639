import React, { useState, useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import ReactDOM from "react-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import avatar from "assets/img/faces/marc.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import url from "assets/js/config.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import BugReport from "@material-ui/icons/BugReport";
import MultiStep from "react-multistep";
//import {Basic_details} from "views/hotel_owners/UserProfile/profile_template.js";
import { Multiselect } from "multiselect-react-dropdown";
import { Photo_details } from "views/hotel_owners/UserProfile/photo_gallery.js";
import Select from "react-select";
import SearchBar from "material-ui-search-bar";
import $ from "jquery";
import DataTable from "datatables.net";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

//import {Basic_details_edit,Business_details_edit,Contact_details_edit} from "views/UserProfile/profile_template_edit.js";
const onChange = (e) => {
  console.log(e.target.files);
};

var ht;
var is_catering;
var catering_type;
var capacity;
var that;
var business_description;
var establishment_type;
var specialization_area;
var alt_msisdn;
var email_id;
var website;
var no_of_employees;
var year_of_establishment;
var business_name;
var state;
var address;
var address1;
var name;
var images1;
var msisdn;
var experience_area;
var city;
var locality;
var pin;
var is_catering = "no";
var select_options;
var grand_data;
const mystyle = {
  width: "250px",
  fontSize: "14px",
};
const mystyle1 = {
  fontSize: "14px",
};
const FileInput = (props) => (
  <input type="file" name="file" multiple value={props.value} />
);

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

var form_value = 0;

var that;
export default class Welcome extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <CustomTabs
          title=""
          headerColor="primary"
          tabs={[
            {
              tabName: "Add Products",
              tabIcon: BugReport,
              tabContent: (
                <div id="card">
                  <Rate_card />
                </div>
              ),
            },

            {
              tabName: "View Products",
              tabIcon: BugReport,
              tabContent: (
                <div>
                  <Table />
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

class Rate_card extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    new get_multi().get_data(function(data) {
      grand_data = data;
      ReactDOM.render(
        <App data={data} />,
        document.getElementById("multi_select")
      );
    });

    try {
      document
        .querySelector("#add_work")
        .addEventListener("click", function(e) {
          e.preventDefault();

          var item = new App("");
          item = item.get_data();

          var description = document.querySelector("#description").value;
          var uom = document.querySelector("#uom").value;
          var admin_rate = document.querySelector("#admin_rate").value;
          var my_rate = document.querySelector("#my_rate").value;
          try {
            if (
              item.name == "" ||
              description == "" ||
              uom == "" ||
              admin_rate == "" ||
              my_rate == ""
            ) {
              alert("All fields are Required");
              return;
            } else if (!/^[0-9]+$/.test(my_rate)) {
              alert("Rate Should be Numeric");
              return;
            }
          } catch (e) {
            alert("All fields are Required");
            return;
          }

          var d = { rate: my_rate, item: item.name, id: item.id };
          var x = new add(d);
          x.get_data();
        });
    } catch (e) {}
  }
  render() {
    return (
      <div>
        <h6>Item Details</h6>

			<GridContainer>
		  <GridItem xs={12} sm={6} md={3}></GridItem>
          <GridItem xs={12} sm={6} md={5} >
            <table
              id="experience_table"
              border="0"
              style={{ width: "100%", fontSize: "13px" }}
            >
              <thead>
                <tr>
                  <th>
                    Item Name
                    <div id="multi_select"></div>
                  </th>
                </tr>
                <br />
               

				<tr>
                  <th>
					UOM:
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;									
					&nbsp;&nbsp;				 
					<input
                      disabled
                      type="text"
                      id="uom"
                      style={{ width: "200px" }}
                    />
                  </th>
                </tr>
                <br />
                <tr>
                  <th>
					Admin Rate(Rs) :
					&nbsp;&nbsp;&nbsp;
                    <input
                      disabled
                      type="text"
                      id="admin_rate"
                      style={{ width: "200px" }}
                    />
                  </th>
                </tr>
				<br />
				<tr>
                  <th>
					Description :
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <textarea disabled id="description" />
                  </th>
                </tr>
                <br />
                <tr>
                  <th>
					My Rate(Rs) :
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="text"
                      id="my_rate"
                      style={{ width: "200px" }}
                    />
                  </th>
				</tr>
				<br />			
                <tr>
                  <th style={{ paddingTop: "32px", paddingLeft: "150px"  }}>
                    <button className="btn btn-primary" id="add_work" style={{ width: "150px", fontSize: "16px"}}>
                      ADD
                    </button>
                  </th>
							</tr>
              </thead>
              <tbody></tbody>
            </table>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
          
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

class get_multi {
  get_data(cb) {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var postData = { auth_token: ootel_auth_token };
    const rawResponse1 = fetch(url.server_url + "/get_vendor_supplier_data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (typeof data.error != "undefined") {
          try {
            alert(data.error);
          } catch (e) {
            console.log(e.message);
          }
        } else {
          return cb(data.data);
        }
      })
      .catch((err) => {
        console.error("Error: ", err);
      });
  }
}
var d;
class App extends React.Component {
  constructor(props) {
    super(props);
    try {
      for (var i = 0; i < props.data.length; i++) {
        props.data[i].label = props.data[i].name;
      }
    } catch (e) {}

    this.options = props.data;
    this.item = "";
    that = this;
  }
  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    d = selectedOption;
    console.log(`Option selected:`, selectedOption);
    try {
      document.querySelector("#description").value = selectedOption.description;
      document.querySelector("#uom").value = selectedOption.unit;
      document.querySelector("#admin_rate").value = selectedOption.admin_rate;
    } catch (e) {}
  };
  get_data() {
    return d;
  }
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.options}
      />
    );
  }
}
class add extends React.Component {
  constructor(props) {
    super(props);
    this.item = props.item;
    this.rate = props.rate;
    this.id = props.id;
  }
  get_data(cb) {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var msisdn = localStorage.getItem("ootel_msisdn");
    var postData = {
      item: this.item,
      rate: this.rate,
      msisdn: msisdn,
      auth_token: ootel_auth_token,
      id: this.id,
    };
    const rawResponse = fetch(url.server_url + "/add_rate_card", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((data) => {
        try {
          if (data.error.length != 0) {
            alert(data.error);
            return;
          }
        } catch (e) {}
        alert(data.data);
        document.location.reload();
      })
      .catch((err) => {
        console.error("Error: ", err);
      });
  }
}

class get_search_data extends React.Component {
  constructor(page, per_page, column, direction, search) {
    super(page, per_page, column, direction, search);

    this.search = search;
    this.column = column;
    this.direction = direction;
    this.per_page = per_page;
    this.page = page;

    that = this;
  }
  componentDidMount() {}
  get_data() {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var msisdn = localStorage.getItem("ootel_msisdn");
    var role = localStorage.getItem("ootel_role");

    var search_value;

    var postData = {
      auth_token: ootel_auth_token,
      search: this.search,
      column: this.column,
      direction: this.direction,
      per_page: this.per_page,
      role: "vendor",
      msisdn: msisdn,
      page: this.page,
    };
    var tab = $("#table").DataTable();
    try {
      tab.destroy();
    } catch (e) {}

    tab = $("#table").DataTable({
      processing: true,
      serverSide: true,
      bPaginate: true, // Pagination True
      sPaginationType: "full_numbers", // And its type.
      iDisplayLength: 10,
      //Initial no order.
      search: {
        search: "",
      },
      ajax: {
        //  "url": "get_completed_tests_for_print_backup.php",
        url: url.server_url + "/get_rate_card",

        type: "POST",
        dataType: "JSON",
        data: {
          auth_token: ootel_auth_token,
          column: this.column,
          direction: this.direction,
          per_page: this.per_page,
          role: "vendor",
          msisdn: msisdn,
          page: this.page,
        },

        dataSrc: function(json1) {
          var return_data = new Array();
          var data = json1.data;
          var data1 = json1.data1;
          var k = 1;
          for (var i = 0; i < data.length; i++) {
            var x = "<input type='text' value='" + data[i].my_rate + "'>";
            if (data[i].status == "active") {
              var act =
                "Active <input type='radio' checked value='" +
                data[i].name +
                "'>" +
                "Inactive <input type='radio' value='" +
                data[i].name +
                "'>";
            } else {
              var act =
                "Active <input type='radio' value='" +
                data[i].name +
                "'>" +
                "Inactive <input type='radio' checked value='" +
                data[i].name +
                "'>";
            }

            var color = "";
            if (data[i].status == "active") {
              color = "";
            } else {
              color = "color: red";
            }

            return_data.push({
              name: "<span style='" + color + "'>" + data[i].name + "</span>",
              description:
                "<span style='" +
                color +
                "'>" +
                data[i].description +
                "</span>",
              unit: "<span style='" + color + "'>" + data[i].unit + "</span>",
              admin_rate:
                "<span style='" + color + "'>" + data[i].admin_rate + "</span>",
              my_rate: "<span style='" + color + "'>" + x + "</span>",
              status:
                "<span style='" + color + "'>" + data[i].status + "</span>",
              action:
                "<button class='update' max_rate='" +
                data[i].admin_rate +
                "' value='" +
                data[i].id +
                "'>Update</button>" +
                "<select class='make_active_inactive' ids='" +
                data[i].id +
                "'><option value=''>--select--</option><option value='active'>Active</option><option value='inactive'>Inactive</option></select>",

              recordsTotal: 11,

              recordsFiltered: 11,
            });
          }
          $("#table11_filter")
            .find("input")
            .css({ width: "700px", "margin-left": "-80%" });
          $("#table11_filter")
            .find("input")
            .attr("placeholder", "Search  Item");

          return return_data;
        },
      },
      createdRow: function(row, data, index) {},
      columnDefs: [
        {
          targets: [0, 1], //first column / numbering column
          orderable: false, //set not orderable
        },
      ],
      columns: [
        { data: "name" },
        { data: "description" },

        { data: "unit" },
        { data: "admin_rate" },
        { data: "my_rate" },
        { data: "status" },
        { data: "action" },
      ],
    });
  }
}

class Table extends React.Component {
  componentDidMount() {
    var c = new get_search_data("1", "10", "id", "desc");
    c.get_data();

    document.querySelector("#table").addEventListener(
      "click",
      function(e) {
        if (new clk().hasClass(e.target, "update")) {
          var elem = e.target;
          var z = elem.className.split(" ");

          var id = e.target.getAttribute("value");

          var rate = e.target.parentNode.parentNode.querySelector("input")
            .value;
          var max_rate = e.target.getAttribute("max_rate");

          var ootel_auth_token = localStorage.getItem("ootel_auth_token");

          var postData = {
            max_rate: max_rate,
            auth_token: ootel_auth_token,
            id: id,
            rate: rate,
          };
          const rawResponse = fetch(url.server_url + "/update_rate_card", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          })
            .then((res) => res.json())
            .then((data) => {
              try {
                if (data.error.length != 0) {
                  alert(data.error);
                  return;
                }
              } catch (e) {}
              alert(data.data);

              var c = new get_search_data("1", "10", "id", "desc");
              c.get_data();
            })
            .catch((err) => {
              console.error("Error: ", err);
            });
        }
      },
      false
    );

    document
      .querySelector("#export_report")
      .addEventListener("click", function (e) {
        e.preventDefault();

        
          var ootel_auth_token = localStorage.getItem("ootel_auth_token");
          var msisdn = localStorage.getItem("ootel_msisdn");

          var csvData = [];
          var fileName = "Rate Card"

          var postData = {
              auth_token: ootel_auth_token,
              column: "id",
              search: "",
              direction: "desc",
              length: 1,
              role: "vendor",
              msisdn: msisdn,
              start: 0,
              page: 1,  
              per_page: 1,
              isExport: "Y",
          };
       
          const rawResponse1 = fetch(url.server_url + "/get_rate_card", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }).then((res) => res.json())
            .then((result) => {
          
              var data = result.data;
              var data1 = result.data1;
              // alert(data.length);
                for (var i = 0; i < data.length; i++) {
                  csvData.push({
                          SiNo: i + 1,
                          Name: data[i].name,
                          Description: data[i].description,
                          Unit: data[i].unit,
                          AdminRate: data[i].admin_rate,
                          // my_rate: "<span style='" + color + "'>" + x + "</span>",
                          Status: data[i].status,
                    });
                          
                };
         
              console.log(csvData);
                const fileType =
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const fileExtension = ".xlsx";

                const ws = XLSX.utils.json_to_sheet(csvData);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, {
                  bookType: "xlsx",
                  type: "array",
                });
                const exceldata = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(exceldata, fileName + fileExtension);
              
            }).catch((err) => {
              console.error("Error: ", err);
            });
        
      });

    document.querySelector("#table").addEventListener(
      "change",
      function(e) {
        if (new clk().hasClass(e.target, "make_active_inactive")) {
          var elem = e.target;
          var z = elem.className.split(" ");

          var status = e.target.value;
          var id = e.target.getAttribute("ids");

          var ootel_auth_token = localStorage.getItem("ootel_auth_token");

          var postData = {
            auth_token: ootel_auth_token,
            id: id,
            status: status,
          };
          const rawResponse = fetch(
            url.server_url + "/active_inactive_rate_card",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(postData),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              try {
                if (data.error.length != 0) {
                  alert(data.error);
                  return;
                }
              } catch (e) {}
              alert(data.data);

              var c = new get_search_data("1", "10", "id", "desc");
              c.get_data();
            })
            .catch((err) => {
              console.error("Error: ", err);
            });
        }
      },
      false
    );
  }

  render() {
    return (
      <div className="cl">
        <table className="table table-striped table-bordered" id="table">
          <thead>
            <tr style={{ backgroundColor: "#8fcbcd" }}>
              <th>Item Name</th>
              <th>Description</th>
              <th>UOM</th>
              <th>Admin Rate(Rs)</th>
              <th>My Rate(Rs)</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>

            <br />
            <br />
            <button
              id="export_report"
              className="btn btn-primary"
              style={{ width: "110px", fontSize: "15px", float: "right" }}
            >
              Export Excel
            </button>
            <br />
            <br />
      </div>
    );
  }
}
class clk {
  hasClass(elem, className) {
    try {
      return elem.className.split(" ").indexOf(className) > -1;
    } catch (e) {
      return -1;
    }
  }
}
