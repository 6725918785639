import React,{useState, useEffect} from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import url from "assets/js/config.js";

// *********** icons for dashboard inner boxs ***********//
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";

// *********** Table Scrollbar ************ //
import TableScrollbar from "react-table-scrollbar";

// ********** News Ticker **************//
import Ticker from "react-ticker";
import Marquee from "react-marquee-master";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { bugs, website, server } from "variables/general.js";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { css } from "jquery";

const useStyles = makeStyles(styles);

function insertStyle(css) {
  if (!css) {
    return;
  }
  if (typeof window === "undefined") {
    return;
  }

  var style = document.createElement("style");

  style.setAttribute("type", "text/css");
  style.innerHTML = css;
  document.head.appendChild(style);
  return css;
}

insertStyle(
  '.mqItem{ padding-top:10px;margin:5x;border:2px solid orange;border-radius:8px;height:40px;}\n .marquee-container {\n  overflow: hidden;\n  position: relative;\n}\n.marquee-container .marquee {\n  position: absolute; \n width: 100%; margin-top:20px;color:white;font:bold;\n}\n.marquee-container::before, .marquee-container::after {\n  content: " ";\n  position: absolute;\n  z-index: 1;\n}\n.marquee-container:not(.horizontal)::before, .marquee-container:not(.horizontal)::after {\n  height: 10%;\n  width: 100%;\n}\n.marquee-container:not(.horizontal)::before {\n background: purple;\n  background: linear-gradient(180deg, white 0%, rgba(120, 104, 236, 0.6) 80%, rgba(120, 104, 236, 0) 100%);\n  top: 0;\n}\n.marquee-container:not(.horizontal)::after {\n background: purple;\n  background: linear-gradient(360deg, white 0%, rgba(120, 104, 236, 0.6) 80%, rgba(120, 104, 236, 0) 100%);\n  bottom: 0;\n}\n.marquee-container.horizontal::before, .marquee-container.horizontal::after {\n  height: 100%;\n  width: 5%;\n}\n.marquee-container.horizontal::before {\n background: purple;\n  background: linear-gradient(90deg, white 0%, rgba(120, 104, 236, 0.6) 80%, rgba(120, 104, 236, 0) 100%);\n  left: 0;\n}\n.marquee-container.horizontal::after {\n background: purple;\n  background: linear-gradient(270deg, white 0%, rgba(120, 104, 236, 0.6) 80%, rgba(120, 104, 236, 0) 100%);\n  right: 0;\n}\n.marquee-container.horizontal .marquee {\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  white-space: nowrap;\n}'
);

let todaysPriceList = [];

todaysPriceList.push(
  <div class="mqItem">
    <div class="row">
      <div class="col-sm">&nbsp;&nbsp;1</div>
      <div class="col-sm">Onion</div>
      <div class="col-sm">1Kg</div>
      <div class="col-sm">Rs 33</div>
    </div>
  </div>
);
todaysPriceList.push(
  <div class="mqItem">
    <div class="row">
      <div class="col-sm">&nbsp;&nbsp;2</div>
      <div class="col-sm">Tomoto</div>
      <div class="col-sm">1Kg</div>
      <div class="col-sm">Rs 33</div>
    </div>
  </div>
);
todaysPriceList.push(
  <div class="mqItem">
    <div class="row">
      <div class="col-sm">&nbsp;&nbsp;3</div>
      <div class="col-sm">Brinjal</div>
      <div class="col-sm">1Kg</div>
      <div class="col-sm">Rs 33</div>
    </div>
  </div>
);
todaysPriceList.push(
  <div class="mqItem">
    <div class="row">
      <div class="col-sm">&nbsp;&nbsp;4</div>
      <div class="col-sm">Carrrot</div>
      <div class="col-sm">1Kg</div>
      <div class="col-sm">Rs 33</div>
    </div>
  </div>
);
todaysPriceList.push(
  <div class="mqItem">
    <div class="row">
      <div class="col-sm">&nbsp;&nbsp;5</div>
      <div class="col-sm">Beans</div>
      <div class="col-sm">1Kg</div>
      <div class="col-sm">Rs 33</div>
    </div>
  </div>
);
todaysPriceList.push(
  <div class="mqItem">
    <div class="row">
      <div class="col-sm">&nbsp;&nbsp;6</div>
      <div class="col-sm">Apple</div>
      <div class="col-sm">1Kg</div>
      <div class="col-sm">Rs 33</div>
    </div>
  </div>
);

todaysPriceList.push(
  <div class="mqItem">
    <div class="row">
      <div class="col-sm">&nbsp;&nbsp;7</div>
      <div class="col-sm">Beet root</div>
      <div class="col-sm">1Kg</div>
      <div class="col-sm">Rs 33</div>
    </div>
  </div>
);



const GeAnnouncmentsFromAPI = () => {
  const [announcments, setAnnouncments] = useState("");
  useEffect(() => {
    async function fetchData() {
      var ootel_auth_token = localStorage.getItem("ootel_auth_token");
      var msisdn = localStorage.getItem("ootel_msisdn");
      var postData = {
        msisdn: msisdn,
        auth_token: ootel_auth_token,
        roles: "hotel_owner",
      };
      var arr=[];
      const announcmentsFromAPI = await fetch(url.server_url + "/get_announcement_byrole", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      }).then((res) => res.json())
      .then((data) => {
        try {
          if (data.error.length != 0) {
            alert(data.error);
            return;
          }
        } catch (e) {}
        if (data.data.length > 0) {
           arr=[]
          for (var i = 0; i < data.data.length; i++) {
            arr.push(data.data[i].content);
          }              
        }
      })
      .catch((err) => {
        console.error("Error: ", err);
      });
      setAnnouncments(arr);
    }     
    fetchData();
  }, []);
  // A placeholder is needed, to tell react-ticker, that width and height might have changed
  // It uses MutationObserver internally
  //alert(announcments);
  return announcments ? (
   
    <p style={{ whiteSpace: "nowrap" }}>{ announcments.join(" , ")} , </p>
  ) : (
    <p style={{ visibility: "hidden" }}>Placeholder</p>
  );
};



export default function Dashboard() {
  const classes = useStyles();
  return (
    <div>
      <div
        style={{
          backgroundColor: "lightgray",
          paddingTop: "5px",
          height: "42px",
          border: "3px solid #26537c",
          borderRadius: "8px",
        }}
      >
        <Ticker offset="run-in" speed={5}>
          {({}) => <GeAnnouncmentsFromAPI/>}
        </Ticker>
      </div>

      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <a href="employees">
            <Card chart style={{ backgroundColor: "#187da0" }}>
              <CardHeader stats icon>
                <span stats icon style={{ float: "right", marginTop: "55px" }}>
                  <CardIcon
                    color="info"
                    style={{
                      background:
                        "repeating-radial-gradient(rgb(138 182 242 / 38%), transparent 100px)",
                      boxShadow:
                        "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgb(193 106 0 / 40%)",
                    }}
                  >
                    <PeopleAltIcon />
                  </CardIcon>
                </span>
                <h6
                  className={classes.cardTitle}
                  style={{ float: "left", paddingTop: "60px", color: "white" }}
                >
                  Employees
                </h6>
              </CardHeader>
              <CardBody>
                {/* <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p> */}
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> updated 4 minutes ago
                </div>
              </CardFooter>
            </Card>
          </a>
          <br></br>
          <a href="service_providers">
            <Card chart style={{ backgroundColor: "fuchsia" }}>
              <CardHeader stats icon>
                <span stats icon style={{ float: "right", marginTop: "55px" }}>
                  <CardIcon
                    color="info"
                    style={{
                      background:
                        "repeating-radial-gradient(rgb(255 119 174), transparent 100px)",
                      boxShadow:
                        "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgb(193 0 124 / 40%)",
                    }}
                  >
                    <SettingsApplicationsIcon />
                  </CardIcon>
                </span>
                <h6
                  className={classes.cardTitle}
                  style={{ float: "left", paddingTop: "46px", color: "white" }}
                >
                  <span style={{ paddingRight: "28px" }}>Service</span> <br />
                  Providers
                </h6>
              </CardHeader>
              <CardBody>
                {/* <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p> */}
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> updated 4 minutes ago
                </div>
              </CardFooter>
            </Card>
          </a>
        </GridItem>
        <GridItem xs={12} sm={12} md={1}></GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <a href="vendors">
            <Card chart style={{ backgroundColor: "gold" }}>
              <CardHeader stats icon>
                <span stats icon style={{ float: "right", marginTop: "55px" }}>
                  <CardIcon
                    color="info"
                    style={{
                      background:
                        "repeating-radial-gradient(rgb(255 210 51), transparent 100px)",
                      boxShadow:
                        "rgba(0, 0, 0, 0.14) 0px 4px 20px 0px, rgb(174 193 0 / 40%) 0px 7px 10px -5px",
                    }}
                  >
                    <RestaurantMenuIcon />
                  </CardIcon>
                </span>
                <h6
                  className={classes.cardTitle}
                  style={{ float: "left", paddingTop: "60px", color: "white" }}
                >
                  Vendors
                </h6>
              </CardHeader>
              <CardBody>
                {/*<p className={classes.cardCategory}>Last Campaign Performance</p> */}
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> campaign sent 2 days ago
                </div>
              </CardFooter>
            </Card>
          </a>
          <br></br>
          <a href="#">
            <Card chart style={{ backgroundColor: "#f5302e" }}>
              <CardHeader stats icon>
                <span stats icon style={{ float: "right", marginTop: "55px" }}>
                  <CardIcon
                    color="info"
                    style={{
                      background:
                        "repeating-radial-gradient(rgb(250 96 96), transparent 100px)",
                      boxShadow:
                        "rgba(0, 0, 0, 0.14) 0px 4px 20px 0px, rgb(233 49 7 / 40%) 0px 7px",
                    }}
                  >
                    <Accessibility />
                  </CardIcon>
                </span>
                <h6
                  className={classes.cardTitle}
                  style={{ float: "left", paddingTop: "46px", color: "white" }}
                >
                  <span style={{ paddingRight: "55px" }}>Recent</span>
                  <br /> Engagement
                </h6>
              </CardHeader>
              <CardBody>
                {/*<p className={classes.cardCategory}>Last Campaign Performance</p> */}
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> campaign sent 2 days ago
                </div>
              </CardFooter>
            </Card>
          </a>
        </GridItem>
        <GridItem xs={12} sm={12} md={1}></GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card style={{ backgroundColor: "#7868ec" }}>
            <CardHeader color="warning">
              <h5 className={classes.cardTitleWhite}>Today's Price List</h5>
              {/* <p className={classes.cardCategoryWhite}>Newly Updated List</p> */}
            </CardHeader>
            <CardBody>
              <Marquee
                height="300"
                marqueeItems={todaysPriceList}
                delay="100"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
