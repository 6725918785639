import React from "react";
import ReactDOM from "react-dom";
import { useForm } from 'react-hook-form'
// @material-ui/core components

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import avatar from "assets/img/faces/marc.jpg";
import 'bootstrap/dist/css/bootstrap.min.css';
import url from "assets/js/config.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import BugReport from "@material-ui/icons/BugReport";

import ReactTable from 'react-table-6';

import 'react-table-6/react-table.css';

import 'assets/css/styles.css';
import  {Photo_details} from "views/hotel_owners/UserProfile/photo_gallery.js";
import MicroModal from 'micromodal'; 
import { Multiselect } from 'multiselect-react-dropdown';
var that;
var ht;
var is_catering;
var catering_type;
var capacity;
var that;
var business_description;
var establishment_type;
var specialization_area;
var alt_msisdn;
var email_id;
var website;
var no_of_employees;
var year_of_establishment;
var business_name;
var state;
var address;
var address1;
var name;
	var images1;
	var msisdn;
	var experience_area;
	var city;
	var locality;
	var pin;
var is_catering="no";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};


var that;	
var columns;
export default class Welcome extends React.Component {
      constructor(props) {
       super(props);
	   that=this;
       this.state = {
          data: props.value,
          loading: false,
          pages: 0,
          pagesize:10,
        fetch_flag:1,
        
          showModal_data:false
         
       };
       
   }
    
   
   componentDidMount() {
        
       MicroModal.init({
  onShow: modal => console.info('${modal.id} is shown'), 
  onClose: modal => console.info('${modal.id} is hidden'), 
  openTrigger: 'data-custom-open', // [3]
  closeTrigger: 'data-custom-close', // [4]
  disableScroll: true, // [5]
  disableFocus: false, // [6]
  awaitOpenAnimation: false, // [7]
  awaitCloseAnimation: false, // [8]
  debugMode: true // [9]
});        
   
   
   new multi_select().get_multi();
   
   
   document.querySelector("#search").addEventListener("click",function(){
	   var data=new get_search_data("","","","").get_data();
	 
	   
	   
   });
    //
     

  
   }   
       
       
       
        
        
        
      
        
  render() {
      const mystyle = { 
      width: "300px",
      fontSize:"14px",
      
    };
   
      
    const { data } = this.state;
 
      const overlayClassName = this.state.showModal_data
      ? "modal fade show"
      : "modal fade";
      
     
    return <div> 
            <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title=""
            headerColor="primary"
            tabs={[
              {
                tabName: "Supplier Details",
                tabIcon: BugReport,
                tabContent: (
                        <div>
						  { new Search().render()}
					  
						
						
                       <div id='table_data'> { new table(this.state.data,this.state.count).render()}
					  
					   </div>
                             
      </div>
                                )
              },
              
            ]}
          />
           
            


        
        </GridItem>
     
    
      </GridContainer>
            
             <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            
            <div style={{width:"100%"}} className="modal micromodal-slide" id="modal-1" aria-hidden="true">
    <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
      <div style={{maxWidth:"1000px",width:"1000px"}}className="modal__container" role="dialog" aria-modal="true" aria-labelledby="modal-1-title">
        
        <main className="modal__content" id="modal-1-content">
		{new Counter_edit().render(this.state.data)}
  
   
        </main>
        <footer className="modal__footer">
        
          <button className="modal__btn" data-micromodal-close aria-label="Close this dialog window">Close</button>
        </footer>
      </div>
    </div>
  </div>
  
        </GridItem>
     
    
      </GridContainer>
            
            
                    
                    </div>;
  }
}
  
  var that1;
  class table extends React.Component{
	  constructor(props,props1){
		  super(props,props1);
		    this.data=props;
		   this.count=props1;
		 
		   
		 this.pages=Math.ceil(this.count/10);
		   
		   this.mystyle1 = {
      
      fontSize:"14px",
      
    };
		 that1=this; 
		  
	  }
	  render(){
		 
		 return <ReactTable style={this.mystyle1}
columns={[
                             {
                               Header: "Sl No",
                               accessor: "sl_no",
                                filterable: false,
								    className:"-striped -highlight"
                             },
							 
							  {
                               Header: "Photo",
                               accessor: "photo",
                                filterable: false,
								    className:"-striped -highlight",
									Cell: cellInfo   => (
								   
									<a href={url.image_url+"/"+(cellInfo.original.photo).split("<>")[0]} target='_new'><img src={url.image_url+"/"+(cellInfo.original.photo).split("<>")[0]} style={{width:"200px"}}/>
									</a>
									)
									
                             },
							 
							 
                             {
                               Header: "Name",
                               accessor: "name",
							    filterable: false,
						    className:"-striped -highlight"
                               
                             },
							  {
                               Header: "Locality",
                               accessor: "locality",
							       className:"-striped -highlight",
                                filterable: false,
								  
                              },
							 {
                               Header: "Items",
                               accessor: "area_of_experience",
							       className:"-striped -highlight",
                                filterable: false,
								 Cell: cellInfo   => ( <div dangerouslySetInnerHTML={{__html: cellInfo.original.area_of_experience}} />  ), 
                                  
                              },
							  {
                               Header: "Details",
                               accessor: "details",
							       className:"-striped -highlight",
                                filterable: false,
                                   Cell: cellInfo   => (
								   (localStorage.getItem("payment_status")=="yes")?
									<button className='view_details' value={cellInfo.original.id}>View Contacts</button>:"You are not paid member" )
									 
                              }
                             
                            ]}
  data={this.data} // should default to []
pages={this.pages}
  
  pageSize={1}
        loading={that.state.loading}
  manual
   filterable={ true}
   


          
                        
        
  
  onPageChange={(pageIndex) => {new get_search_data("","",pageIndex,"").get_data();that.state.fetch_flag=0}} 
  onPageSizeChange={(pageSize, pageIndex) => {new get_search_data("","",pageIndex,pageSize).get_data();that.state.fetch_flag=0}} 
  onSortedChange={(newSorted, column, shiftKey) => {}}
  onExpandedChange={(newExpanded, index, event) => {}} 
  //onFilteredChange={(filtered, column) => {new get_search_data(filtered,"","","").get_data();;that.state.fetch_flag=0}} 
  
  
/>; 
		  
		 
	  }
  }
  
  
  
  class Counter_edit extends React.Component {
  render() {
    return <div>
	 <h6>Basic Details</h6>
	
	<GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <b> Name:</b><span  id='name'></span>
<br/>
		  
				 <b> Mobile:</b><span  id='msisdn'></span>
                <br/>
                
                <b> Alternative Mobile:</b><span  id='alt_msisdn'></span>
				<br/>
              
                 <b> Land Line Number:</b><span  id='landline'></span>
                <br/>
            
                 
                 <b> Business Name:</b><span  id='business_name'></span>
                 <br/>
				
				
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
		<b> Locality:</b><span  id='locality'></span>
                 <br/>
          <b> Landmark:</b><span  id='landmark'></span>
                 <br/>
                
                 
                  
				  <b> State:</b><span  id='state'></span>
                  
                 <br/>
                 
                  <b>City:</b><span  id='city'></span>
                 <br/>
                 <b>   PIN:</b><span  id='pin'></span>
                <br/>
                
                
        </GridItem>
      </GridContainer>
	  <hr/>
	    <h6>Business Details</h6>
	  <GridContainer>
  
  <GridItem xs={6} sm={6} md={6}>
  

  
          <b> Year of Establishment:</b><span  id='year_of_establishment'></span>
                 <br/>
                
                 
                
                 
                 <b> GST Number:</b><span  id='gst_no'></span>
                 <br/>
              <b> Major Supply Line:</b>
                 <div id='area_of_experience'   ></div>
        </GridItem>
  
           </GridContainer>
	  
	   <hr/>
	 
	 <h6>Photo Details</h6>
	  
	  <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
           
				 
   <b>Photos:</b>
				 
                   <div id='photo_details'></div>
   	<br/>
		
        </GridItem>
        
      </GridContainer>
	  
	  </div>;
  }
}
  
  
    class Search extends React.Component {
  render() {
    return <div>
	Search:<div  id='search_data' style={{width:"400px",display:"inline-block"}}></div>&nbsp;&nbsp;&nbsp;<button style={{display:"inline-block"}} className='btn btn-primary' id='search'>Search</button>
	<br/>	<br/>
	</div>;
  }
	}

class multi_select extends React.Component{
	get_multi(){
		 var ootel_auth_token=localStorage.getItem('ootel_auth_token');  
	  var postData={"auth_token":ootel_auth_token};
		  
	 const  rawResponse1 =  fetch(url.server_url+"/get_supplier_experience_area", {
    method: 'POST',
	  headers: {
      
      'Content-Type': 'application/json' 
    },
	body: JSON.stringify(postData),
   
  }).then(res => res.json())
    .then(data => {
        if(typeof data.error!="undefined"){
            try{
            alert(data.error);
   
        }catch(e){
            console.log(e.message);
        }
        }else{
        
		 
		
		
	ReactDOM.render(
 <Multiselect
options={ data.data} // Options to display in the dropdown
//selectedValues={} // Preselected value to persist in dropdown
onSelect={this.onSelect_area_of_experience} // Function will trigger on select event
onRemove={this.onRemove_area_of_experience} // Function will trigger on remove event
displayValue="name" // Property name to display in the dropdown options
/>,
  document.getElementById("search_data")
);
        
document.querySelector("#multiselectContainerReact").querySelector("div").style.border="none";

	document.querySelector("#multiselectContainerReact").querySelector("div").querySelector("input").style.width="100%";
 
		}
        
      
        
        
        
        
  }).catch(err => {
        console.error('Error: ', err);
    });;
	  
   	
	}
	
	 onSelect_area_of_experience(selectedList, selectedItem) {
			   that.experience_area="[]";
   that.experience_area=(selectedList);
   
   
}
 
onRemove_area_of_experience(selectedList, removedItem) {
	that.experience_area="[]";
  that.experience_area=selectedList;
  
}
	
	}
	
	class get_search_data extends React.Component{
		
		constructor(filtered,column,pageIndex,pageSize){
			super(filtered,column,pageIndex,pageSize);
			this.filtered=filtered;
			this.column=column;
			this.pageIndex=pageIndex;
			this.pageSize=pageSize;
			
			
			
			
			
		}
		
		get_data(){
			
			
               var ootel_auth_token=localStorage.getItem("ootel_auth_token");
			   var role=localStorage.getItem("ootel_role");
              var postData={"search":that.experience_area,
				  "auth_token":ootel_auth_token,filtered:this.filtered,column:this.column,pageIndex:this.pageIndex
              ,pageSize:this.pageSize,"role":"supplier"};
        const  rawResponse =  fetch(url.server_url+"/get_vendor_supplier_details", {
    method: 'POST',
    headers: {
      
      'Content-Type': 'application/json' 
    },
    body: JSON.stringify(postData)
  }).then(res => res.json())
    .then(data => {
   try{     
if(data.error.length!=0){
	
	
		var X=new table(this.state.data,this.state.count).render();
		ReactDOM.render(
  <X/>,
  document.getElementById("table_data")
);
	
	
    alert(data.error);return;
}
   }catch(e){
       
   }
   try{
	    for(var i1=0;i1<data.data.length;i1++){
		
		
		
		var x;
		try{
			  x=JSON.parse(data.data[i1].area_of_experience);
			
		
  
   var d="";
   for(var i=0;i<x.length;i++){
	   d=d+x[i].name+"<br/>"
   }
   data.data[i1].area_of_experience=d;
   }catch(e){
			x=[];
		}
	}
   }catch(e){
	   
   }
   var length=0;
   try{
	     if(data.data.length){}
	   length=data.data.length;
   }catch(e){
	     length=0;
   }
  
   
	that.setState({
            data: data.data,
			count:data.count,
            pages: Math.ceil(data.count/10),
            loading: false,
            pagesize:length,
           fetch_flag:0,
           showModal_data:false
            
        })
	
   
  
 var classname = document.getElementsByClassName("view_details");


  
for (var i = 0; i < classname.length; i++) {
    
    
    classname[i].addEventListener('click', function(e) {
   new view_details(this.value).click_fun(); 
   
  
   }); 
}


   
  
  
   
   
   
 
    }).catch(err => {
        console.error('Error: ', err);
    });
        
		 
		
		}
		
		
		
		
		
		
	}
	
	class view_details extends React.Component{
		constructor(props){
			super(props);
			this.id=props
		}
		  click_fun(){
           
         
         
              try{
   var classname = document.getElementsByClassName("view_details");


  

    

  

       
   
 
    var postData={id:this.id,auth_token:localStorage.getItem("ootel_auth_token"),"contact":"no"};
    
   
      const  rawResponse =  fetch(url.server_url+"/get_employee_specification", {
    method: 'POST',
    headers: {
      
      'Content-Type': 'application/json' 
    },
    body: JSON.stringify(postData)
  }).then(res => res.json())
    .then(data => {
   try{     
if(data.error.length!=0){
    alert(data.error);return;
}
   }catch(e){
       
   }
   
   
   try{
 var data1=data.data;
    	
  name= data1[0].name;
  business_name= data1[0].business_name;
 capacity= data1[0].capacity;
    
 var pan_number= data1[0].pan_no;
   var adhaar_number= data1[0].aadhar_no;
 specialization_area= data1[0].specialization_area;

  var nominee= data1[0].nominee;
   email_id= data1[0].email_id;
 website= data1[0].website; 
    	
  var blood_group= data1[0].blood_group;
    
     msisdn= data1[0].msisdn; 
	var gender= data1[0].gender; 
var nominee_relation= data1[0].nominee_relation;   	
		var emergency_msisdn=data1[0].emergency_msisdn; 
		
		  address= data1[0].address;
		    state= data1[0].state; 
			  city= data1[0].city;
		
		pin= data1[0].pin;
		locality= data1[0].locality;
		
		var address_permanent= data1[0].address_permanent;
		   var state_permanent= data1[0].state_permanent; 
			  var city_permanent= data1[0].city_permanent;
		
		var pin_permanent= data1[0].pin_permanent;
		var locality_permanent= data1[0].locality_permanent;
		var alt_msisdn= data1[0].alt_msisdn;
		var landline= data1[0].landline;
		var landmark= data1[0].landmark;
		var year_of_establishment= data1[0].year_of_establishment;
		var gst_no= data1[0].gst_no;
		var dob= data1[0].dob;
		document.querySelector("#name").value=name;
		document.querySelector("#msisdn").value=msisdn;
		document.querySelector("#alt_msisdn").value=alt_msisdn;

		document.querySelector("#landline").value=landline;
		
		document.querySelector("#business_name").value=business_name;

		document.querySelector("#landmark").value=landmark;
		
	
	
		
		document.querySelector("#state").value=state;
		
		document.querySelector("#locality").value=locality;
		document.querySelector("#pin").value=pin;
		document.querySelector("#city").value=city;
		
		
		document.querySelector("#year_of_establishment").value=year_of_establishment;
		document.querySelector("#gst_no").value=gst_no;
		
		
		
		
		
		
		try{
		 var	images1= data1[0].photo.split("<>");
		var	photos=[];
	for(var i=0;i<images1.length;i++){
				if(images1[i]!=""){
					photos.push(
				{
					"src":url.image_url+"/"+images1[i],
					"width":1,
					height:1
				
				});
				}
				
			}
			ReactDOM.render(
  <Photo_details data={photos}/>,
  document.getElementById("photo_details")
);			
			
		}catch(e){
			
		}
		
		//For view profile
		document.querySelector("#name").innerHTML=name;
		document.querySelector("#msisdn").innerHTML=msisdn;
		document.querySelector("#alt_msisdn").innerHTML=alt_msisdn;
		document.querySelector("#landline").innerHTML=landline;
		document.querySelector("#business_name").innerHTML=business_name;
		document.querySelector("#landmark").innerHTML=landmark;
		
		
	
		
		document.querySelector("#state").innerHTML=state;
		
		document.querySelector("#locality").innerHTML=locality;
		document.querySelector("#pin").innerHTML=pin;
		document.querySelector("#city").innerHTML=city;
		
		
		document.querySelector("#year_of_establishment").innerHTML=year_of_establishment;
		document.querySelector("#gst_no").innerHTML=gst_no;
		
		
		var html="";
		var x=data1[0].area_of_experience
		
		x=JSON.parse(x);
		for(var i=0;i<x.length;i++){
			html=html+x[i].name+"<br/>"
		}
		
		
		document.querySelector("#area_of_experience").innerHTML=html;
		 MicroModal.show('modal-1'); 
		
        
    }catch(e){
        console.log(e.message);
    }
    }).catch(err => {
        console.error('Error: ', err);
    });
       }catch(e){
		   
	   }
		
		
	}
	
	}
	
	
	
	

	
	
	