import React, { useState, useCallback, useEffect, useRef } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import "bootstrap/dist/css/bootstrap.min.css";
import url from "assets/js/config.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import BugReport from "@material-ui/icons/BugReport";
import Select from "react-select";
import $ from "jquery";
import MicroModal from "micromodal";

// icons for referral link
import WhatsApp from "@material-ui/icons/WhatsApp";
import Twitter from "@material-ui/icons/Twitter";
import Facebook from "@material-ui/icons/Facebook";


import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

var ht;
var is_catering;
var catering_type;
var capacity;
var that;
var business_description;
var establishment_type;
var specialization_area;
var alt_msisdn;
var email_id;
var website;
var no_of_employees;
var year_of_establishment;
var business_name;
var state;
var address;
var address1;
var name;
var images1;
var msisdn;
var experience_area;
var city;
var locality;
var pin;
var is_catering = "no";
var select_options;
var grand_data;

const mystyle = {
  width: "250px",
  fontSize: "14px",
};
const mystyle1 = {
  fontSize: "14px",
};
const FileInput = (props) => (
  <input type="file" name="file" multiple value={props.value} />
);

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

var form_value = 0;

var that;

var share = app => () => {
  
  // Getting reflink from txt box
  var refLink = document.querySelector("#txtRefLink").value;
  //alert(refLink);
  //alert(app);
  // Opening URL
  switch(app)
  {
    case "whatsapp":
      window.open(
          "whatsapp://send?text=" + refLink,

          // This is what makes it 
          // open in a new window.
          '_blank' 
      );
      break;
      case "twitter":
      window.open(
          "https://twitter.com/share?url=" + refLink,

          // This is what makes it 
          // open in a new window.
          '_blank' 
      );
      break;
      case "facebook":
      window.open(
          "https://www.facebook.com/sharer/sharer.php?u=" + refLink,

          // This is what makes it 
          // open in a new window.
          '_blank' 
      );
      break;
  }
}



export default class Welcome extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    var msisdn = localStorage.getItem("ootel_msisdn");
    var refurl = url.portal_url + "/register?sid=" + msisdn;
    document.querySelector("#lnkRef").setAttribute("href", refurl);
    document.querySelector("#txtRefLink").value = refurl;
  }

  render() {
    return (
      <div>
        <CustomTabs
          title=""
          headerColor="primary"
          tabs={[
            {
              tabName: "Link For Referral",
              tabIcon: BugReport,
              tabContent: (

                <div style={{backgroundColor:"#E3E2DE", height:"70px",padding:"10px",verticalAlign:"middle"}}>        
                    <b style={{ padding: "10px" }}> Refferal Link : </b>
                    <a href="" id="lnkRef" target="_blank">
                    <input type="text" id="txtRefLink" readOnly="true" style={{padding:"10px",width:"500px", color: "blue"}} value=""></input>         
                    </a>
                    <a style={{ padding: "5px" }} href="#" onClick={share('whatsapp')}>
                      <WhatsApp  style = {{fill:"#4FCE5D",fontSize:"3em"}}/>
                    </a>
                    <a style={{padding:"5px"}} href="#" onClick={share('twitter')}>
                      <Twitter  style = {{fill:"#1DA1F2",fontSize:"3em"}}/>
                    </a>
                    <a style={{padding:"5px"}} href="#" onClick={share('facebook')}>
                      <Facebook  style = {{fill:"#4267B2",fontSize:"3em"}}/>
                    </a>
                </div>



                // <div>
                //   <a href="" id="lnkRef" target="_blank">
                //     <h6 style={{ color: "blue" }}>Referral Link</h6>
                //   </a>

                //   <div style={{ float: "right", marginTop: "-40px" }}>
                //     <a href="#" target="_blank" style={{ color: "green;" }}>
                //       <WhatsAppIcon />
                //     </a>
                //     <a href="#" target="_blank" style={{ color: "blue;" }}>
                //       <TwitterIcon color="primary" />
                //     </a>
                //     <a href="#" target="_blank" style={{ color: "red;" }}>
                //       <FacebookIcon />
                //     </a>
                //   </div>
                // </div>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

class clk {
  hasClass(elem, className) {
    try {
      return elem.className.split(" ").indexOf(className) > -1;
    } catch (e) {
      return -1;
    }
  }
}
