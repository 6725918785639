import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import EmailIcon from '@material-ui/icons/Email';
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
import LayersIcon from '@material-ui/icons/Layers';
import ListAltIcon from '@material-ui/icons/ListAlt';
// core components/views for Admin layout
import DashboardPage from "views/hotel_owners/Dashboard/Dashboard.js";
import UserProfile from "views/hotel_owners/UserProfile/UserProfile.js";
import employees from "views/hotel_owners/Employee/Employee.js";
import vendors from "views/hotel_owners/Vendors/vendors.js";
import service_providers from "views/hotel_owners/Service_providers/service_providers.js";
import contact_history from "views/hotel_owners/Contact_history/contact_history.js";
import catering_order from "views/hotel_owners/Catering_order/catering_order.js";
import logout from "views/hotel_owners/Logout/logout.js";
import job_details from "views/hotel_owners/Job_details/job_details.js";
import classifieds from "views/hotel_owners/Classifieds/classifieds.js";
import classifiedsviewall from "views/hotel_owners/Classifieds/classifiedsviewall.js";
import complaint_box from "views/complaint_box.js";
import reportingall from "views/hotel_owners/Utilities/reportingall.js";
import current_employees from "views/hotel_owners/My_organisation/current_employees.js";
import my_vendors from "views/hotel_owners/My_organisation/my_vendors.js";
import daily_purchase from "views/hotel_owners/My_organisation/daily_purchase.js";
import employee_salary from "views/hotel_owners/My_organisation/employee_salary.js";
import daily_business from "views/hotel_owners/My_organisation/daily_business.js";
import ob_cash from "views/hotel_owners/My_organisation/ob_cash.js";
import sub_users from "views/hotel_owners/My_organisation/sub_users.js";
import employee_availability from "views/hotel_owners/Employee_availability/employee_availability.js";

import DashboardPage_employee from "views/employees/Dashboard/Dashboard.js";
import UserProfile_employee from "views/employees/UserProfile/UserProfile.js";
import employees_employee from "views/employees/Employee/Employee.js";
import vendors_employee from "views/employees/Vendors/vendors.js";
import service_providers_employee from "views/employees/Service_providers/service_providers.js";
import logout_employee from "views/employees/Logout/logout.js";
import localjob_detailsempy from "views/employees/Job_details/localjob_detailsempy.js";
import abroadjob_detailsempy from "views/employees/Job_details/abroadjob_detailsempy.js";
import reporting_employee from "views/employees/Utilities/reporting.js";
import referral_link from "views/employees/Referral_link/referral_link.js";
import rewards from "views/employees/Rewards/rewards.js";


import DashboardPage_vendor from "views/vendors/Dashboard/Dashboard.js";
import UserProfile_vendor from "views/vendors/UserProfile/UserProfile.js";
import employees_vendor from "views/vendors/Employee/Employee.js";
import vendors_vendor from "views/vendors/Vendors/vendors.js";
import service_providers_vendor from "views/vendors/Service_providers/service_providers.js";
import logout_vendor from "views/vendors/Logout/logout.js";
import rate_card from "views/vendors/rate_card.js";
import request_history from "views/vendors/request_history.js";
import reporting_vendors from "views/vendors/Utilities/reporting.js";


import DashboardPage_supplier from "views/suppliers/Dashboard/Dashboard.js";
import UserProfile_supplier from "views/suppliers/UserProfile/UserProfile.js";
import employees_supplier from "views/suppliers/Employee/Employee.js";
import vendors_supplier from "views/suppliers/Vendors/vendors.js";
import service_providers_supplier from "views/suppliers/Service_providers/service_providers.js";
import logout_supplier from "views/suppliers/Logout/logout.js";
import reporting_supplier from "views/suppliers/Utilities/reporting.js";


var auth_token=localStorage.getItem("ootel_auth_token");
var role=localStorage.getItem("ootel_role");
var dashboardRoutes="";
const dashboardRoutes_hotel = [ 
  {
    path: "/dashboard",
    name: "Dashboard",
   
    icon: Dashboard,
    component: DashboardPage,
    layout: "/hotel_owners",
    "items":[]
          
    
  },
  {
    path: "/user",
    name: "Profile",
   
    icon: Person,
    component: UserProfile,
    layout: "/hotel_owners",
     "items":[]
  },
  {
    path: "/employees",
    name: "Employees",
    
    icon: "content_paste",
    component: employees,
    layout: "/hotel_owners",
     "items":[]
  },
  { 
    path: "/vendors",
    name: "Vendors",
    
    icon: LibraryBooks,
    component: vendors,
    layout: "/hotel_owners",
     "items":[]
  },
  {
    path: "/service_providers",
    name: "Service Providers",
   
    icon: BubbleChart,
    component: service_providers,
    layout: "/hotel_owners",
    "items":[
           {
    path: "/vendors",
    name: "Vendors",
    
    icon: LibraryBooks,
    component: vendors,
    layout: "/hotel_owners"
  } 
    ]
    
  },
  
  {
    path: '/contact_history',
    name: "Contact History",
   
    icon: LocationOn, 
  component: contact_history,
    layout: "/hotel_owners",
     "items":[]
    
  },

   {
    path: '/catering_order',
    name: "Catering Order",
   
    icon: LocationOn, 
  component: catering_order,
    layout: "/hotel_owners",
     "items":[]
    
  },

   {
    path: '/current_employees',
    name: "Current Employees",
   
    icon: LocationOn, 
  component: current_employees,
    layout: "/hotel_owners",
     "items":[]
    
  },
    {
    path: '/employee_salary',
    name: "Employee Salary",
   
    icon: LocationOn, 
  component: employee_salary,
    layout: "/hotel_owners",
     "items":[]
    
  },
      {
    path: '/my_vendors',
    name: "My Vendors",
   
    icon: LocationOn, 
  component: my_vendors,
    layout: "/hotel_owners",
     "items":[]
    
  },
       {
    path: '/daily_purchase',
    name: "Daily Purchase",
   
    icon: LocationOn, 
  component: daily_purchase,
    layout: "/hotel_owners",
     "items":[]
    
  },
        {
    path: '/daily_business',
    name: "Daily Business",
   
    icon: LocationOn, 
  component: daily_business,
    layout: "/hotel_owners",
     "items":[]
    
  },
        {
    path: '/ob_cash',
    name: "OB Cash",
   
    icon: LocationOn, 
  component: ob_cash,
    layout: "/hotel_owners",
     "items":[]
    
  },
     {
    path: '/sub_users',
    name: "Users",
   
    icon: LocationOn, 
  component: sub_users,
    layout: "/hotel_owners",
     "items":[]
    
  },

   {
    path: '/complaint_box',
    name: "Complaint Box",
   
    icon: LocationOn, 
  component: complaint_box,
    layout: "/hotel_owners",
     "items":[]
    
  },
  
  {
    path: '/logout',
    name: "Logout",
   
    icon: LocationOn, 
  component: logout,
    layout: "/hotel_owners",
     "items":[]
    
  },

    { 
    path: "/job_details",
    name: "Job Details",
    
    icon: EmailIcon,
    component: job_details,
    layout: "/hotel_owners",
     "items":[]
  },
  
    { 
    path: "/classifieds",
    name: "Classifieds",
    
    icon: LayersIcon,
    component: classifieds,
    layout: "/hotel_owners",
     "items":[]
  },
    { 
    path: "/classifiedsviewall",
    name: "View Classifieds",
    
    icon: LayersIcon,
    component: classifiedsviewall,
    layout: "/hotel_owners",
     "items":[]
  },
       { 
    path: "/reportingall",
    name: "Reporting",
    
    icon: ListAltIcon,
    component: reportingall,
    layout: "/hotel_owners",
     "items":[]
  },
       
    {
    path: '/employee_availability',
    name: "Employee Availability",
   
    icon: LocationOn, 
  component: employee_availability,
    layout: "/hotel_owners",
     "items":[]
    
  },
    
    
];

const dashboardRoutes_employee = [ 
  {
    path: "/dashboard",
    name: "Dashboard",
   
    icon: Dashboard,
    component: DashboardPage_employee,
    layout: "/employees",
    "items":[]
          
    
  },
  {
    path: "/user",
    name: "Profile",
   
    icon: Person,
    component: UserProfile_employee,
    layout: "/employees",
     "items":[]
  },
  {
    path: "/employees",
    name: "Emoployees",
    
    icon: "content_paste",
    component: employees_employee,
    layout: "/employees",
     "items":[]
  },
  {
    path: "/vendors",
    name: "Vendors",
    
    icon: LibraryBooks,
    component: vendors_employee,
    layout: "/employees",
     "items":[]
  },
  {
    path: "/service_providers",
    name: "Service Providers",
   
    icon: BubbleChart,
    component: service_providers_employee,
    layout: "/employees",
    "items":[
           {
    path: "/vendors",
    name: "Vendors",
    
    icon: LibraryBooks,
    component: vendors_employee,
    layout: "/employees"
  } 
    ]
    
  },
    { 
    path: "/localjob_detailsempy",
    name: "Job Details",
    
    icon: EmailIcon,
    component: localjob_detailsempy,
    layout: "/employees",
     "items":[]
  },
    { 
    path: "/abroadjob_detailsempy",
    name: "Abroad Job Details",
    
    icon: EmailIcon,
    component: abroadjob_detailsempy,
    layout: "/employees",
     "items":[]
  },
      { 
    path: "/reporting",
    name: "Reporting",
    
    icon: ListAltIcon,
    component: reporting_employee,
    layout: "/employees",
     "items":[]
  },
    
  {
    path: '/complaint_box',
    name: "Complaint Box",
   
    icon: LocationOn, 
  component: complaint_box,
    layout: "/employees",
     "items":[]
    
  },

{
    path: '/referral_link',
    name: "Referral Link",
   
    icon: LocationOn, 
  component: referral_link,
    layout: "/employees",
     "items":[]
    
  },

{
    path: '/rewards',
    name: "Rewards",
   
    icon: LocationOn, 
  component: rewards,
    layout: "/employees",
     "items":[]
    
  },


  {
    path: '/logout',
    name: "Logout",
   
    icon: LocationOn, 
  component: logout_employee,
    layout: "/employees",
     "items":[]
    
  },
  
];



const dashboardRoutes_vendor = [ 
  {
    path: "/dashboard",
    name: "Dashboard",
   
    icon: Dashboard,
    component: DashboardPage_vendor,
    layout: "/vendors",
    "items":[]
          
    
  },
  {
    path: "/user",
    name: "Profile",
   
    icon: Person,
    component: UserProfile_vendor,
    layout: "/vendors",
     "items":[]
  },
  {
    path: "/employees",
    name: "Emoployees",
    
    icon: "content_paste",
    component: employees_vendor,
    layout: "/vendors",
     "items":[]
  },
  {
    path: "/vendors",
    name: "Vendors",
    
    icon: LibraryBooks,
    component: vendors_vendor,
    layout: "/vendors",
     "items":[]
  },
  {
    path: "/service_providers",
    name: "Service Providers",
   
    icon: BubbleChart,
    component: service_providers_vendor,
    layout: "/vendors",
    "items":[
           {
    path: "/vendors",
    name: "Vendors",
    
    icon: LibraryBooks,
    component: vendors_vendor,
    layout: "/vendors"
  } 
    ]
    
  },
  {
    path: '/rate_card',
    name: "Rate Card",
   
    icon: LocationOn, 
  component: rate_card,
    layout: "/vendors",
     "items":[]
    
  },
  
  {
    path: '/request_history',
    name: "Request History",
   
    icon: LocationOn, 
  component: request_history,
    layout: "/vendors",
     "items":[]
    
  },
  
  {
    path: '/complaint_box',
    name: "Complaint Box",
   
    icon: LocationOn, 
  component: complaint_box,
    layout: "/vendors",
     "items":[]
    
  },
  
  {
    path: '/logout',
    name: "Logout",
   
    icon: LocationOn, 
  component: logout_vendor,
    layout: "/vendors",
     "items":[]
    
  },

    { 
    path: "/reporting",
    name: "Reporting",
    
    icon: ListAltIcon,
    component: reporting_vendors,
    layout: "/vendors",
     "items":[]
  },
  
];




const dashboardRoutes_supplier = [ 
  {
    path: "/dashboard",
    name: "Dashboard",
   
    icon: Dashboard,
    component: DashboardPage_supplier,
    layout: "/suppliers",
    "items":[]
          
    
  },
  {
    path: "/user",
    name: "Profile",
   
    icon: Person,
    component: UserProfile_supplier,
    layout: "/suppliers",
     "items":[]
  },
  {
    path: "/employees",
    name: "Emoployees",
    
    icon: "content_paste",
    component: employees_supplier,
    layout: "/suppliers",
     "items":[]
  },
  {
    path: "/vendors",
    name: "Vendors",
    
    icon: LibraryBooks,
    component: vendors_supplier,
    layout: "/suppliers",
     "items":[]
  },
  {
    path: "/service_providers",
    name: "Service Providers",
   
    icon: BubbleChart,
    component: service_providers_supplier,
    layout: "/suppliers",
    "items":[
           {
    path: "/vendors",
    name: "Vendors",
    
    icon: LibraryBooks,
    component: vendors_supplier,
    layout: "/suppliers"
  } 
    ]
    
  },
  
  {
    path: '/complaint_box',
    name: "Complaint Box",
   
    icon: LocationOn, 
  component: complaint_box,
    layout: "/suppliers",
     "items":[]
    
  },

   { 
    path: "/reporting",
    name: "Reporting",
    
    icon: ListAltIcon,
    component: reporting_supplier,
    layout: "/suppliers",
     "items":[]
  },
  
  {
    path: '/logout',
    name: "Logout",
   
    icon: LocationOn, 
  component: logout_supplier,
    layout: "/suppliers",
     "items":[]
    
  },
  
];

if(role=="hotel_owner"){
	dashboardRoutes=dashboardRoutes_hotel;
	
}
if(role=="employee"){
	dashboardRoutes=dashboardRoutes_employee;
}

if(role=="vendor"){
	dashboardRoutes=dashboardRoutes_vendor;
}


if(role=="supplier"){
	dashboardRoutes=dashboardRoutes_supplier;
}
export default dashboardRoutes;
