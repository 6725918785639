import React, { useState, useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import ReactDOM from "react-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import avatar from "assets/img/faces/marc.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import url from "assets/js/config.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import BugReport from "@material-ui/icons/BugReport";
import MultiStep from "react-multistep";
//import {Basic_details} from "views/hotel_owners/UserProfile/profile_template.js";
import { Multiselect } from "multiselect-react-dropdown";
import { Photo_details } from "views/hotel_owners/UserProfile/photo_gallery.js";

//import {Basic_details_edit,Business_details_edit,Contact_details_edit} from "views/UserProfile/profile_template_edit.js";
const onChange = (e) => {
  console.log(e.target.files);
};

var ht;
var is_catering;
var catering_type;
var capacity;
var that;
var business_description;
var establishment_type;
var specialization_area;
var alt_msisdn;
var email_id;
var website;
var no_of_employees;
var year_of_establishment;
var business_name;
var state;
var address;
var address1;
var name;
var images1;
var file01;
var file02;
var msisdn;
var experience_area;
var city;
var locality;
var memberuniqid;
var pin;
var is_catering = "no";
const mystyle = {
  width: "250px",
  fontSize: "14px",
};
const mystyle1 = {
  fontSize: "14px",
};
const FileInput = (props) => (
  <input type="file" name="file" multiple value={props.value} />
);

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

var form_value = 0;

var that;
export default class Welcome extends React.Component {
  constructor(props) {
    super(props);

    var education = [];
    var specialization = [];
    var languages = [];

    this.state = {
      data: props.value,
      files_upload_aadhar_card: props.value,
      files_upload_pan_card: props.value,
    };

    this.handleFirstNameChanged = this.handleFirstNameChanged.bind(this);
    this.handle_hotel_type = this.handle_hotel_type.bind(this);
    this.handle_address = this.handle_address.bind(this);
    this.handle_address1 = this.handle_address1.bind(this);
    this.handle_city = this.handle_city.bind(this);
    this.handle_state = this.handle_state.bind(this);
    this.handle_pin = this.handle_pin.bind(this);
    this.handle_locality = this.handle_locality.bind(this);
    this.handle_year_of_establishment = this.handle_year_of_establishment.bind(
      this
    );
    this.handle_no_of_employees = this.handle_no_of_employees.bind(this);
    this.handle_business_description = this.handle_business_description.bind(
      this
    );
    this.handle_specialization_area = this.handle_specialization_area.bind(
      this
    );
    this.handle_catering_capacity = this.handle_catering_capacity.bind(this);
    this.handle_website = this.handle_website.bind(this);
    this.handle_email_id = this.handle_email_id.bind(this);
    this.handle_alt_msisdn = this.handle_alt_msisdn.bind(this);
    this.handle_msisdn = this.handle_msisdn.bind(this);
    this.handle_fname = this.handle_fname.bind(this);
    this.handle_capacity = this.handle_capacity.bind(this);
    this.handle_file = this.handle_file.bind(this);
    this.handle_file_aadhar_card = this.handle_file_aadhar_card.bind(this);
    this.handle_file_pan_card = this.handle_file_pan_card.bind(this);

    that = this;
  }

  handleFirstNameChanged(event) {
    //alert(event.target.value);
    this.setState({ name: event.target.value });
  }
  handle_hotel_type(event) {
    //alert(event.target.value);
    this.setState({ hoteltype: event.target.value });
  }
  handle_file(event) {
    //document.querySelector("#myform").value=event.target.value;
    if (this.state.files_upload === undefined) {
      this.state.files_upload = "";
    }
    //alert(event.target.files);

    this.setState({ files_upload: event.target.files });
  }

  // ************** KYC ********************//
  handle_file_aadhar_card(event) {
    //document.querySelector("#myform").value=event.target.value;
    if (this.state.files_upload_aadhar_card === undefined) {
      this.state.files_upload_aadhar_card = "";
    }
    //alert(event.target.files);

    this.setState({ files_upload_aadhar_card: event.target.files_aadhar_card });
  }
  handle_file_pan_card(event) {
    //document.querySelector("#myform").value=event.target.value;
    if (this.state.files_upload_pan_card === undefined) {
      this.state.files_upload_pan_card = "";
    }
    //alert(event.target.files);

    this.setState({ files_upload_pan_card: event.target.files_pan_card });
  }

  handle_address(event) {
    //alert(event.target.value);
    this.setState({ address: event.target.value });
  }
  handle_address1(event) {
    //alert(event.target.value);
    this.setState({ address1: event.target.value });
  }
  handle_city(event) {
    //alert(event.target.value);
    this.setState({ city: event.target.value });
  }
  handle_state(event) {
    //alert(event.target.value);
    this.setState({ state: event.target.value });
  }
  handle_pin(event) {
    //alert(event.target.value);
    this.setState({ pin: event.target.value });
  }

  handle_locality(event) {
    //alert(event.target.value);
    this.setState({ locality: event.target.value });
  }
  handle_year_of_establishment(event) {
    //alert(event.target.value);
    that.setState({ yearofestablishment: event.target.value });
  }
  handle_no_of_employees(event) {
    //alert(event.target.value);
    that.setState({ noofemployees: event.target.value });
  }
  handle_business_description(event) {
    //alert(event.target.value);
    that.setState({ businessdescription: event.target.value });
  }

  handle_specialization_area(event) {
    //alert(event.target.value);
    that.setState({ specializationarea: event.target.value });
  }
  handle_establishment_type(event) {
    //alert(event.target.value);
    that.setState({ establishmenttype: event.target.value });
  }

  handle_catering_capacity(event) {
    //that.setState({cateringcapacity1: false});
    delete that.state.cateringcapacity1;
    that.setState({ cateringcapacity: event.target.value });
    that.setState({ catering_data1: "none" });
    is_catering = event.target.value;

    document.querySelector("#catering_data1").style.display = "none";
  }
  handle_catering_capacity1(event) {
    delete that.state.cateringcapacity;
    // that.setState({cateringcapacity: false});
    is_catering = event.target.value;
    that.setState({ cateringcapacity1: event.target.value });
    that.setState({ catering_data1: "block" });
    document.querySelector("#catering_data1").style.display = "block";
  }
  handle_email_id(event) {
    that.setState({ email_id: event.target.value });
    //that.setState({cateringcapacity: "off"});
  }
  handle_website(event) {
    that.setState({ website: event.target.value });
    //that.setState({cateringcapacity: "off"});
  }
  handle_msisdn(event) {
    that.setState({ msisdn: event.target.value });
    //that.setState({cateringcapacity: "off"});
  }
  handle_alt_msisdn(event) {
    that.setState({ alt_msisdn: event.target.value });
    //that.setState({cateringcapacity: "off"});
  }

  handle_fname(event) {
    that.setState({ fname: event.target.value });
    //that.setState({cateringcapacity: "off"});
  }

  handle_capacity(event) {
    //alert(event.target.value);
    that.setState({ capacity: event.target.value });
  }
  handle_website(event) {
    //alert(event.target.value);
    that.setState({ website: event.target.value });
  }
  handle_catering_type(event) {
    var data = event.target.value;
    delete that.state.catering_non_veg;
    delete that.state.catering_veg;
    delete that.state.catering_both;
    if (data == "non_veg") {
      that.setState({ catering_non_veg: event.target.value });
      that.setState({ catering_type: event.target.value });
    }
    if (data == "veg") {
      that.setState({ catering_veg: event.target.value });
      that.setState({ catering_type: event.target.value });
    }
    if (data == "both") {
      that.setState({ catering_both: event.target.value });
      that.setState({ catering_type: event.target.value });
    }
  }

  componentDidMount() {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var postData = { auth_token: ootel_auth_token };
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var postData = { auth_token: ootel_auth_token };

    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var postData = { auth_token: ootel_auth_token };
    const rawResponse = fetch(url.server_url + "/get_hotel_owners", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((data) => {
        try {
          if (data.error.length != 0) {
            alert(data.error);
            return;
          }
        } catch (e) {}

        try {
          var data1 = data.data;

          name = data1[0].name;
          business_name = data1[0].business_name;
          capacity = data1[0].capacity;

          var pan_number = data1[0].pan_no;
          var adhaar_number = data1[0].aadhar_no;
          specialization_area = data1[0].specialization_area;

          var nominee = data1[0].nominee;
          email_id = data1[0].email_id;
          website = data1[0].website;

          var blood_group = data1[0].blood_group;

          msisdn = data1[0].msisdn;
          var gender = data1[0].gender;
          var nominee_relation = data1[0].nominee_relation;
          var emergency_msisdn = data1[0].emergency_msisdn;

          address = data1[0].address;
          state = data1[0].state;
          city = data1[0].city;

          pin = data1[0].pin;
          locality = data1[0].locality;
		  memberuniqid = data1[0].memberuniqid==""?"Contact Admin for Member uniq Id":data1[0].memberuniqid;
          var address_permanent = data1[0].address_permanent;
          var state_permanent = data1[0].state_permanent;
          var city_permanent = data1[0].city_permanent;

          var pin_permanent = data1[0].pin_permanent;
          var locality_permanent = data1[0].locality_permanent;
          var alt_msisdn = data1[0].alt_msisdn;
          var landline = data1[0].landline;
          var landmark = data1[0].landmark;
          var year_of_establishment = data1[0].year_of_establishment;
          var gst_no = data1[0].gst_no;
          var dob = data1[0].dob;
          document.querySelector("#name").value = name;
          document.querySelector("#msisdn").value = msisdn;
          document.querySelector("#alt_msisdn").value = alt_msisdn;

          document.querySelector("#landline").value = landline;

          document.querySelector("#business_name").value = business_name;

          document.querySelector("#landmark").value = landmark;

          document.querySelector("#state").value = state;

          document.querySelector("#locality").value = locality;		  
		  document.querySelector("#memberuniqid").innerHTML = memberuniqid;
          document.querySelector("#pin").value = pin;
          document.querySelector("#city").value = city;

          document.querySelector(
            "#year_of_establishment"
          ).value = year_of_establishment;
          document.querySelector("#gst_no").value = gst_no;

          try {
            var images1 = data1[0].photo.split("<>");
            var photos = [];
            for (var i = 0; i < images1.length; i++) {
              if (images1[i] != "") {
                photos.push({
                  src: url.image_url + "/" + images1[i],
                  width: 1,
                  height: 1,
                });
              }
            }
            ReactDOM.render(
              <Photo_details data={photos} />,
              document.getElementById("photo_details")
            );
          } catch (e) {}

          // ************* KYC *****************//
          // ************ Aadhar Card ***********//
          try {
            var file01 = data1[0].aadhar_card.split("<>");
            var files = [];
            for (var i = 0; i < file01.length; i++) {
              if (file01[i] != "") {
                files.push({
                  src: url.image_url + "/" + file01[i],
                  width: 1,
                  height: 1,
                });
              }
            }
            ReactDOM.render(
              <aadhar_card_details data={files} />,
              document.getElementById("aadhar_card_details")
            );
          } catch (e) {}
          // ************* Pan Card *****************//
          try {
            var file02 = data1[0].pan_card.split("<>");
            var files = [];
            for (var i = 0; i < file02.length; i++) {
              if (file02[i] != "") {
                files.push({
                  src: url.image_url + "/" + file02[i],
                  width: 1,
                  height: 1,
                });
              }
            }
            ReactDOM.render(
              <pan_card_details data={files} />,
              document.getElementById("pan_card_details")
            );
          } catch (e) {}

          //For view profile
          document.querySelector("#name").innerHTML = name;
          document.querySelector("#msisdn").innerHTML = msisdn;
          document.querySelector("#alt_msisdn").innerHTML = alt_msisdn;
          document.querySelector("#landline").innerHTML = landline;
          document.querySelector("#business_name").innerHTML = business_name;
          document.querySelector("#landmark").innerHTML = landmark;

          document.querySelector("#state").innerHTML = state;

          document.querySelector("#locality").innerHTML = locality;
          document.querySelector("#pin").innerHTML = pin;
          document.querySelector("#city").innerHTML = city;

          document.querySelector(
            "#year_of_establishment"
          ).innerHTML = year_of_establishment;
          document.querySelector("#gst_no").innerHTML = gst_no;

          var html = "";
          var x = data1[0].area_of_experience;

          x = JSON.parse(x);
          for (var i = 0; i < x.length; i++) {
            html = html + x[i].name + "<br/>";
          }

          document.querySelector("#area_of_experience").innerHTML = html;
        } catch (e) {
          console.log(e.message);
        }
      })
      .catch((err) => {
        console.error("Error: ", err);
      });

    document.querySelector("#myform").addEventListener("submit", function(e) {
      e.preventDefault();
    });

    try {
      document.querySelector("#update").addEventListener("click", function(e) {
        e.preventDefault();

        const formData = new FormData(document.querySelector("#myform"));
        var ootel_auth_token = localStorage.getItem("ootel_auth_token");
        formData.append("auth_token", ootel_auth_token);

        formData.append(
          "experience_area",
          JSON.stringify(that.experience_area)
        );

        const rawResponse = fetch(url.server_url + "/add_suppliers", {
          method: "POST",
          body: formData,
        })
          .then((res) => res.json())
          .then((data) => {
            if (typeof data.error != "undefined") {
              try {
                alert(data.error);
              } catch (e) {
                console.log(e.message);
              }
            } else {
              alert(data.data);
              document.location.reload();
            }
          })
          .catch((err) => {
            console.error("Error: ", err);
          });
      });
    } catch (e) {}

    try {
      document
        .querySelector("#edit_profile")
        .addEventListener("click", function(e) {
          window.location.href = window.location.pathname + "?type=update";
          document.querySelector("#title").innerHTML =
            "<h5>Update Your Profile</h5>";
        });
    } catch (e) {}

    var url1 = new URL(window.location.href);
    var c = url1.searchParams.get("type");
    var view;
    if (c == "view") {
      view = true;
    } else {
      view = false;
    }

    if (c != "view") {
      var postData = { auth_token: ootel_auth_token };

      const rawResponse1 = fetch(
        url.server_url + "/get_supplier_supplier_data",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (typeof data.error != "undefined") {
            try {
              alert(data.error);
            } catch (e) {
              console.log(e.message);
            }
          } else {
            this.experience_area = JSON.parse(data.data_selected);

            ReactDOM.render(
              <Multiselect
                options={data.data} // Options to display in the dropdown
                selectedValues={JSON.parse(data.data_selected)} // Preselected value to persist in dropdown
                onSelect={this.onSelect_area_of_experience} // Function will trigger on select event
                onRemove={this.onRemove_area_of_experience} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
              />,
              document.getElementById("area_of_experience")
            );
          }
        })
        .catch((err) => {
          console.error("Error: ", err);
        });
    }
    try {
      document.querySelector("#profile").addEventListener("click", function(e) {
        window.location.href = window.location.pathname + "?type=view";
      });
    } catch (e) {}
  }

  onSelect_area_of_experience(selectedList, selectedItem) {
    that.experience_area = "";
    that.experience_area = selectedList;
  }

  onRemove_area_of_experience(selectedList, removedItem) {
    that.experience_area = "";
    that.experience_area = selectedList;
  }

  render() {
    const Profile = () => (
      <div>
        {new Counter_edit().render(this.state.data)}
        <hr />
      </div>
    );

    var url = new URL(window.location.href);
    var c = url.searchParams.get("type");
    var view;
    if (c == "view") {
      view = true;
    } else {
      view = false;
    }

    return (
      <div>
        {view ? (
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomTabs
                  title=""
                  headerColor="primary"
                  tabs={[
                    {
                      tabName: "View Profile",
                      tabIcon: BugReport,
                      tabContent: (
                        <div>
                          <h5 id="title">Your Profile</h5>
                          <form
                            id="myform"
                            name="myform"
                            style={mystyle1}
                            encType="multipart/form-data"
                          >
                            <div id="data">{<Profile />}</div>
                            <div style={{ float: "right" }}></div>
                          </form>
                          <div style={{ display: "inline-block", width: "" }}>
                            {" "}
                            &nbsp; &nbsp;
                          </div>
                          <button id="edit_profile" className="btn btn-primary">
                            Edit
                          </button>
                          <div style={{ display: "inline-block", width: "" }}>
                            {" "}
                            &nbsp; &nbsp;
                          </div>
                        </div>
                      ),
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        ) : (
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomTabs
                  title=""
                  headerColor="primary"
                  tabs={[
                    {
                      tabName: "Profile Update",
                      tabIcon: BugReport,
                      tabContent: (
                        <div>
                          <h5 id="title">Update Profile</h5>
                          <form
                            id="myform"
                            name="myform"
                            style={mystyle1}
                            encType="multipart/form-data"
                          >
                            {new Counter().render(this.state.data)}
                          </form>
                          <div style={{ float: "right" }}>
                            <button id="profile" className="btn btn-primary">
                              View Profile
                            </button>
                            <div style={{ display: "inline-block", width: "" }}>
                              {" "}
                              &nbsp; &nbsp;
                            </div>

                            <button
                              id="update"
                              type="submit"
                              value="0"
                              className="btn btn-primary"
                            >
                              Save All
                            </button>
                          </div>
                        </div>
                      ),
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        )}
      </div>
    );
  }
}

class Counter extends React.Component {
  render() {
    return (
      <div>
        <h6>Basic Details</h6>

        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <b> Name:</b>
            <span className="required"></span>
            <input
              type="text"
              name="fname"
              id="name"
              style={mystyle}
              className="form-control u-full-width"
            />
            <b> Mobile:</b>
            <span className="required"></span>
            <input
              type="text"
              disabled
              id="msisdn"
              style={mystyle}
              className="form-control u-full-width"
            />

            <b> Alternative Mobile:</b>
            <br />
            <input
              type="text"
              id="alt_msisdn"
              name="alt_msisdn"
              className="alt_msisdn form-control"
              style={mystyle}
            />

            <b> Land Line Number:</b>
            <input
              type="text"
              id="landline"
              name="landline"
              style={mystyle}
              className="form-control"
            />

            <b> Business Name:</b>
            <input
              type="text"
              id="business_name"
              name="business_name"
              style={mystyle}
              className="form-control"
            />
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <b> Locality:</b>
            <span className="required"></span>
            <input
              type="text"
              id="locality"
              name="locality"
              style={mystyle}
              className="form-control"
            />
            <b> Landmark:</b>
            <input
              type="text"
              name="landmark"
              id="landmark"
              style={mystyle}
              className="form-control"
            />

            <b> State:</b>
            <span className="required"></span>

            <input
              type="text"
              id="state"
              name="state"
              className="form-control"
              style={mystyle}
              onChange={that.handle_address1}
            />

            <b>City:</b>
            <span className="required"></span>
            <input
              type="text"
              id="city"
              name="city"
              style={mystyle}
              className="form-control"
              onChange={that.handle_city}
            />
            <b> PIN:</b>
            <span className="required"></span>
            <input
              type="text"
              id="pin"
              name="pin"
              style={mystyle}
              className="form-control"
              onChange={that.handle_pin}
            />
			
<b> Member UniqId: &nbsp;</b>
<label id="memberuniqid"></label>
          </GridItem>
        </GridContainer>
        <hr />
        <h6>Business Details</h6>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <b> Year of Establishment:</b>
            <span className="required"></span>
            <input
              type="text"
              name="year_of_establishment"
              id="year_of_establishment"
              style={mystyle}
              className="form-control"
              onChange={that.handle_address}
            />

            <b> GST Number:</b>
            <input
              type="text"
              id="gst_no"
              name="gst_no"
              style={mystyle}
              className="form-control"
              onChange={that.handle_locality}
            />
            <b> Major Supply Line:</b>
            <span className="required"></span>
            <div id="area_of_experience" className=""></div>
          </GridItem>
        </GridContainer>

        <hr />

        <h6>Photo Details</h6>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <b>Photos:</b>

            <input type="file" id="file" name="file" />
            <br />
          </GridItem>
        </GridContainer>

        <hr />

        <h6>KYC</h6>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <b>Aadhar card :</b>
            <span className="required"></span>&nbsp; &nbsp;
            <div id="no_of_files06">
              {that.state.files_upload_aadhar_card
                ? that.state.files_upload_aadhar_card.length + " files Selected"
                : ""}
            </div>
            <input
              type="file"
              id="file"
              name="file_aadhar_card"
              onChange={that.handle_file_aadhar_card}
              multiple
            />
            <br />
            <br />
            <b>Pan card :</b>&nbsp; &nbsp;
            <div id="no_of_files06">
              {that.state.files_upload_pan_card
                ? that.state.files_upload_pan_card.length + " files Selected"
                : ""}
            </div>
            <input
              type="file"
              id="file"
              name="file_pan_card"
              onChange={that.handle_file_pan_card}
              multiple
            />
            <br />
            <br />
            <br />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

class Counter_edit extends React.Component {
  render() {
    return (
      <div>
        <h6>Basic Details</h6>

        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <b> Name:</b>
            <span id="name"></span>
            <br />

            <b> Mobile:</b>
            <span id="msisdn"></span>
            <br />

            <b> Alternative Mobile:</b>
            <span id="alt_msisdn"></span>
            <br />

            <b> Land Line Number:</b>
            <span id="landline"></span>
            <br />

            <b> Business Name:</b>
            <span id="business_name"></span>
            <br />
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <b> Locality:</b>
            <span id="locality"></span>
            <br />
            <b> Landmark:</b>
            <span id="landmark"></span>
            <br />

            <b> State:</b>
            <span id="state"></span>

            <br />

            <b>City:</b>
            <span id="city"></span>
            <br />
            <b> PIN:</b>
            <span id="pin"></span>
            <br />
          </GridItem>
        </GridContainer>
        <hr />
        <h6>Business Details</h6>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <b> Year of Establishment:</b>
            <span id="year_of_establishment"></span>
            <br />

            <b> GST Number:</b>
            <span id="gst_no"></span>
            <br />
            <b> Major Supply Line:</b>
            <div id="area_of_experience"></div>
          </GridItem>
        </GridContainer>

        <hr />

        <h6>Photo Details</h6>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <b>Photos:</b>

            <div id="photo_details"></div>
            <br />
          </GridItem>
        </GridContainer>

        <hr />

        <h6>Aadhar Card Details</h6>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <b>Aadhar Card:</b>

            <div id="aadhar_card_details"></div>
          </GridItem>
        </GridContainer>

        <hr />

        <h6>Pan Card Details</h6>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <b>Pan Card:</b>

            <div id="pan_card_details"></div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
