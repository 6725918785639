import React from "react";
import Fragment from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components

import Admin from "layouts/Admin.js";
import Register from "register.js";

//import RTL from "layouts/RTL.js";

//import "assets/css/material-dashboard-react.css?v=1.8.0";
import front from "front_index.js";
const hist = createBrowserHistory();
var url = "/";
var ootel_auth_token = localStorage.getItem("ootel_auth_token");
var role = localStorage.getItem("ootel_role");

// var x = ootel_auth_token && window.location.href.indexOf("register") == -1
//         && window.location.href.indexOf("gohome") == -1? require("assets/css/form.css") : "";

//alert(ootel_auth_token);
//ootel_auth_token="1"
if (window.location.href.indexOf("register") != -1)
{
  ReactDOM.render(<Register />,
      document.getElementById("root"));  
}
else if (window.location.href.indexOf("gohome") != -1)
{
  window.history.replaceState(null, null, "/");
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/" component={front} />
        return <Redirect to="/" />
        
      </Switch>
    </Router>,
    document.getElementById("root")
  ); 
}
else if (ootel_auth_token && role == "hotel_owner") {
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/hotel_owners" component={Admin} />

        <Redirect from="/" to="/hotel_owners/dashboard" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );
} else if (ootel_auth_token && role == "employee") {
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/employees" component={Admin} />

        <Redirect from="/" to="/employees/dashboard" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );
} else if (ootel_auth_token && role == "vendor") {
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/vendors" component={Admin} />

        <Redirect from="/" to="/vendors/dashboard" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );
} else if (ootel_auth_token && role == "supplier") {
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/suppliers" component={Admin} />

        <Redirect from="/" to="/suppliers/dashboard" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );
} else {
  localStorage.removeItem("ootel_auth_token");
  if (window.location.href.indexOf("register") == -1)
  {
window.history.replaceState(null, null, "/");
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/" component={front} />
        return <Redirect to="/" />
      </Switch>
    </Router>,
    document.getElementById("root")
  );
  }
  else
  {
    ReactDOM.render(<Register />,
      document.getElementById("root"));
  }
  
}

if (!ootel_auth_token) {
  
if(window.location.href.indexOf("register")==-1){
  window.history.replaceState(null, null, "/");
  ReactDOM.render(
    <Router history={hist}>
      <Switch>
        <Route path="/" component={front} />
        return <Redirect to="/" />
        
      </Switch>
    </Router>,
    document.getElementById("root")
  );
  }
  else
  {
    ReactDOM.render(<Register />,
      document.getElementById("root"));
  }
}
