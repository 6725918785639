import React from "react";

import { useForm } from "react-hook-form";
// @material-ui/core components

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import avatar from "assets/img/faces/marc.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import url from "assets/js/config.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import BugReport from "@material-ui/icons/BugReport";

import ReactTable from "react-table-6";

import "react-table-6/react-table.css";

import "assets/css/styles.css";
import MicroModal from "micromodal";
var that;
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

export default class Welcome extends React.Component {
  constructor() {
    super();
    this.state = {
      data: this.data,
      loading: false,
      pages: 0,
      pagesize: 10,
      fetch_flag: 1,

      showModal_data: false,
    };
  }

  componentDidMount() {
    MicroModal.init({
      onShow: (modal) => console.info("${modal.id} is shown"),
      onClose: (modal) => console.info("${modal.id} is hidden"),
      openTrigger: "data-custom-open", // [3]
      closeTrigger: "data-custom-close", // [4]
      disableScroll: true, // [5]
      disableFocus: false, // [6]
      awaitOpenAnimation: false, // [7]
      awaitCloseAnimation: false, // [8]
      debugMode: true, // [9]
    });
  }

  click_fun() {
    that = this;

    try {
      var classname = document.getElementsByClassName("view_details_modal");

      for (var i = 0; i < classname.length; i++) {
        classname[i].addEventListener(
          "click",
          function(e) {
            var postData = {
              msisdn: this.value,
              auth_token: localStorage.getItem("ootel_auth_token"),
            };

            const rawResponse = fetch(
              url.server_url + "/get_employee_specification",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(postData),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                try {
                  if (data.error.length != 0) {
                    alert(data.error);
                    return;
                  }
                } catch (e) {
                  console.log(e.message);
                }

                try {
                  var data1 = data.data;
                  var data2 = data.data1;
                  document.getElementById("view_vendor_fname").innerHTML =
                    data1[0].name;

                  document.getElementById("view_vendor_msisdn").innerHTML =
                    data1[0].msisdn;

                  //document.getElementById("view_vendor_buisness_name").innerHTML=(data1[0].business_name);

                  document.getElementById("view_vendor_locality").innerHTML =
                    data1[0].locality;
                  document.getElementById("view_vendor_address").innerHTML =
                    data1[0].address;
                  //document.getElementById("view_hotel_type").innerHTML=(data1[0].hotel_type);
                  document.getElementById("view_salary").innerHTML =
                    data1[0].salary;

                  document.getElementById("view_dob").innerHTML = data1[0].dob;

                  document.getElementById("view_preferred_loc").innerHTML =
                    data1[0].preferred_loc;
                  document.getElementById("view_availability").innerHTML =
                    data1[0].availability;

                  var html = "";
                  for (var i = 0; i < data2.length; i++) {
                    html =
                      html +
                      "<tr><td>" +
                      data2[i].spl +
                      "</td><td>" +
                      data2[i].years +
                      "</td><td>" +
                      data2[i].description +
                      "</td></tr>";
                  }
                  try {
                    var d1 = document
                      .getElementById("view_vendor_specilization")
                      .getElementsByTagName("tbody")[0];
                    d1.innerHTML = "";
                    d1.innerHTML = html;
                  } catch (e) {
                    console.log(e.message);
                  }

                  MicroModal.show("modal-1");
                } catch (e) {
                  console.log(e.message);
                }
              })
              .catch((err) => {
                console.error("Error: ", err);
              });
          },
          false
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  get_data(filtered, column, pageIndex, pageSize) {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var postData = {
      auth_token: ootel_auth_token,
      filtered: filtered,
      column: column,
      pageIndex: pageIndex,
      pageSize: pageSize,
    };
    const rawResponse = fetch(url.server_url + "/get_vendor_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((data) => {
        try {
          if (data.error.length != 0) {
            alert(data.error);
            return;
          }
        } catch (e) {}

        this.setState({
          data: data.data,
          pages: Math.ceil(data.count / 10),
          loading: false,
          pagesize: data.data.length,
          fetch_flag: 0,
          showModal_data: false,
        });

        this.click_fun();
      })
      .catch((err) => {
        console.error("Error: ", err);
      });
  }

  render() {
    const mystyle = {
      width: "300px",
      fontSize: "14px",
    };
    const mystyle1 = {
      fontSize: "14px",
    };

    const { data } = this.state;

    const overlayClassName = this.state.showModal_data
      ? "modal fade show"
      : "modal fade";

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title=""
              headerColor="primary"
              tabs={[
                {
                  tabName: "Vendor Details",
                  tabIcon: BugReport,
                  tabContent: (
                    <div>
                      <ReactTable
                        style={mystyle1}
                        columns={[
                          {
                            Header: "Sl No",
                            accessor: "sl_no",
                            filterable: false,
                          },
                          {
                            Header: "Name",
                            accessor: "name",
                          },
                          {
                            Header: "Details",
                            accessor: "details",
                            filterable: false,
                            Cell: (row) => (
                              <div
                                dangerouslySetInnerHTML={{ __html: row.value }}
                              />
                            ),
                          },
                        ]}
                        data={data} // should default to []
                        pages={this.state.pages}
                        //defaultPageSize={this.state.pagesize}// should default to -1 (which means we don't know how many pages we have)
                        pageSize={this.state.pagesize}
                        loading={false}
                        manual
                        filterable={true}
                        onFetchData={(state, instance) => {
                          this.setState({ loading: true });

                          // alert(this.state.fetch_flag);
                          if (this.state.fetch_flag == 1) {
                            {
                              this.get_data("", "", "0", "10");
                            }
                          }
                        }}
                        onPageChange={(pageIndex) => {
                          this.get_data("", "", pageIndex, "");
                          this.state.fetch_flag = 0;
                        }}
                        onPageSizeChange={(pageSize, pageIndex) => {
                          this.get_data("", "", pageIndex, pageSize);
                          this.state.fetch_flag = 0;
                        }}
                        onSortedChange={(newSorted, column, shiftKey) => {}}
                        onExpandedChange={(newExpanded, index, event) => {}}
                        onFilteredChange={(filtered, column) => {
                          this.get_data(filtered, column, "", "");
                          this.state.fetch_flag = 0;
                        }}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>

        <div className="modal micromodal-slide" id="modal-1" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              className="modal__container"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-1-title"
            >
              <header className="modal__header">
                <h2 v="modal__title" id="modal-1-title">
                  Vendor Details
                </h2>
                <button
                  className="modal__close"
                  aria-label="Close modal"
                  data-micromodal-close
                ></button>
              </header>
              <main className="modal__content" id="modal-1-content">
                <b>Name:</b>
                <div id="view_vendor_fname"></div>

                <b>Mobile Number:</b>
                <div id="view_vendor_msisdn"></div>
                <b>Salary:</b>
                <div id="view_salary"></div>
                <b>Date Of Birth:</b>
                <div id="view_dob"></div>
                <b>Availability:</b>
                <div id="view_availability"></div>
                <b>Preferred location:</b>
                <div id="view_preferred_loc"></div>

                <b>Specialization:</b>
                <table id="view_vendor_specilization" border="1">
                  <thead>
                    <tr>
                      <th>Specialization</th>
                      <th>Years</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
                <b>Locality:</b>
                <div id="view_vendor_locality"></div>
                <b>Address:</b>
                <div id="view_vendor_address"></div>
              </main>
              <footer className="modal__footer">
                <button
                  className="modal__btn"
                  data-micromodal-close
                  aria-label="Close this dialog window"
                >
                  Close
                </button>
              </footer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
