import React from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
// @material-ui/core components
import $ from "jquery";
import DataTable from "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import avatar from "assets/img/faces/marc.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import url from "assets/js/config.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import BugReport from "@material-ui/icons/BugReport";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

//import DataTable from 'vanilla-datatables';

import { Photo_details } from "views/hotel_owners/UserProfile/photo_gallery.js";
import MicroModal from "micromodal";
import { Multiselect } from "multiselect-react-dropdown";
$.DataTable = DataTable;
var that;
var x;
var ht;
var is_catering;
var catering_type;
var capacity;
var that;
var business_description;
var establishment_type;
var specialization_area;
var alt_msisdn;
var email_id;
var website;
var no_of_employees;
var year_of_establishment;
var business_name;
var state;
var address;
var address1;
var name;
var loaded_data = 0;
var images1;
var msisdn;
var experience_area;
var city;
var locality;
var pin;
var that1;
var is_catering = "no";
var search;
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

var that;
var columns;
var dataTable;

export default class Welcome extends React.Component {
  constructor(props) {
    super(props);
    that = this;
    this.state = {
      data: props.value,
      loading: false,
      pages: 0,
      pagesize: 10,
      fetch_flag: 1,

      showModal_data: false,
    };
  }

  componentDidMount() {
    MicroModal.init({
      onShow: (modal) => console.info("${modal.id} is shown"),
      onClose: (modal) => console.info("${modal.id} is hidden"),
      openTrigger: "data-custom-open", // [3]
      closeTrigger: "data-custom-close", // [4]
      disableScroll: true, // [5]
      disableFocus: false, // [6]
      awaitOpenAnimation: false, // [7]
      awaitCloseAnimation: false, // [8]
      debugMode: true, // [9]
    });

    document.querySelector("#search").addEventListener("click", function() {
      var data = new get_search_data("1", "10", "id", "desc", " ").get_data();
    });
    //

    document
      .querySelector("#export_report")
      .addEventListener("click", function (e) {
        e.preventDefault();

        
          var ootel_auth_token = localStorage.getItem("ootel_auth_token");
          var msisdn = localStorage.getItem("ootel_msisdn");

          var csvData = [];
          var fileName = "Contact History"

          var postData = {
              auth_token: ootel_auth_token,
              column: "id",
              search: "",
              direction: "desc",
              length: 1,
              role: "vendor",
              msisdn: msisdn,
              start: 0,
              page: 1,  
              per_page: 1,
              isExport: "Y",
          };
       
          const rawResponse1 = fetch(url.server_url + "/get_contact_history", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }).then((res) => res.json())
            .then((result) => {
          
              var data = result.data;
              var data1 = result.data1;
              // alert(data.length);
                for (var i = 0; i < data.length; i++) {
                  csvData.push({
                      Photo: data[i].img_text,
                      ContactedOn: data[i].contacted_on,
                      Name: data[i].name,
                      Locality: data[i].locality,
                      Role: data[i].role,
                      ViewDetails: data[i].address,
                  });
                          
                };
         
              console.log(csvData);
                const fileType =
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const fileExtension = ".xlsx";

                const ws = XLSX.utils.json_to_sheet(csvData);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, {
                  bookType: "xlsx",
                  type: "array",
                });
                const exceldata = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(exceldata, fileName + fileExtension);
              
            }).catch((err) => {
              console.error("Error: ", err);
            });
        
      });


    document.querySelector("body").addEventListener(
      "click",
      function(e) {
        try {
          if (new clk().hasClass(e.target, "view_details")) {
            var elem = e.target;
            var z = elem.className.split(" ");

            var role = e.target.getAttribute("role");
            var value = e.target.getAttribute("value");

            if (role == "employee") {
              new view_details_employee(value).click_fun();
            } else new view_details(value).click_fun();
          }

          var z = "view_details";
        } catch (e) {}
      },
      false
    );
  }

  render() {
    const mystyle = {
      width: "300px",
      fontSize: "14px",
    };

    const { data } = this.state;

    const overlayClassName = this.state.showModal_data
      ? "modal fade show"
      : "modal fade";

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title=""
              headerColor="primary"
              tabs={[
                {
                  tabName: "Contact History",
                  tabIcon: BugReport,
                  tabContent: (
                    <div>
                      {new Search().render()}

                      <div id="table_data"> {new table().render()}</div>
                    </div>
                  ),
                },
              ]}
            />
          </GridItem>

          {new modal1().get_data()}
          {new modal().get_data()}
        </GridContainer>
        ;
      </div>
    );
  }
}

var that1;

class clk {
  hasClass(elem, className) {
    return elem.className.split(" ").indexOf(className) > -1;
  }
}

class table extends React.Component {
  render() {
    return (
      <div>
        <table className="table table-striped table-bordered" id="table">
          <thead style={{ backgroundColor: "#8fcbcd" }}>
            <tr>
              <th>Photo</th>
              <th>Contacted On</th>
              <th>Name</th>
              <th>Locality</th>
              <th>Role</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>

        <br />
        <br />
        <button
          id="export_report"
          className="btn btn-primary"
          style={{ width: "110px", fontSize: "15px", float: "right" }}
        >
          Export Excel
        </button>
        <br />
        <br />
      </div>
    );
  }
}

class Counter_edit extends React.Component {
  render() {
    return (
      <div>
        <h6>Basic Details</h6>

        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <b> Name:</b>
            <span id="name1"></span>
            <br />

            <b> Mobile:</b>
            <span id="msisdn1"></span>
            <br />

            <b> Alternative Mobile:</b>
            <span id="alt_msisdn1"></span>
            <br />

            <b> Land Line Number:</b>
            <span id="landline1"></span>
            <br />

            <b> Business Name:</b>
            <span id="business_name1"></span>
            <br />
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <b> Locality:</b>
            <span id="locality1"></span>
            <br />
            <b> Landmark:</b>
            <span id="landmark1"></span>
            <br />

            <b> State:</b>
            <span id="state1"></span>

            <br />

            <b>City:</b>
            <span id="city1"></span>
            <br />
            <b> PIN:</b>
            <span id="pin1"></span>
            <br />
          </GridItem>
        </GridContainer>
        <hr />
        <h6>Business Details</h6>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <b> Year of Establishment:</b>
            <span id="year_of_establishment1"></span>
            <br />

            <b> GST Number:</b>
            <span id="gst_no1"></span>
            <br />
            <b> Major Supply Line:</b>
            <div id="area_of_experience1"></div>
          </GridItem>
        </GridContainer>

        <hr />

        <h6>Photo Details</h6>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <b>Photos:</b>

            <div id="photo_details1"></div>
            <br />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
class Search extends React.Component {
  render() {
    return (
      <div>
        From :<input type="date" id="from_date" /> To:
        <input id="to_date" type="date" />
        &nbsp;&nbsp;&nbsp;
        <button
          style={{ display: "inline-block" }}
          className="btn btn-primary"
          id="search"
        >
          Search
        </button>
        <br /> <br />
      </div>
    );
  }
}

class multi_select extends React.Component {
  get_multi() {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var postData = { auth_token: ootel_auth_token };

    const rawResponse1 = fetch(url.server_url + "/get_vendor_experience_area", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (typeof data.error != "undefined") {
          try {
            alert(data.error);
          } catch (e) {
            console.log(e.message);
          }
        } else {
          ReactDOM.render(
            <Multiselect
              options={data.data} // Options to display in the dropdown
              //selectedValues={} // Preselected value to persist in dropdown
              onSelect={this.onSelect_area_of_experience} // Function will trigger on select event
              onRemove={this.onRemove_area_of_experience} // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
            />,
            document.getElementById("search_data")
          );

          document
            .querySelector("#multiselectContainerReact")
            .querySelector("div").style.border = "none";

          document
            .querySelector("#multiselectContainerReact")
            .querySelector("div")
            .querySelector("input").style.width = "100%";
        }
      })
      .catch((err) => {
        console.error("Error: ", err);
      });
  }

  onSelect_area_of_experience(selectedList, selectedItem) {
    that.experience_area = "[]";
    that.experience_area = selectedList;
  }

  onRemove_area_of_experience(selectedList, removedItem) {
    that.experience_area = "[]";
    that.experience_area = selectedList;
  }
}

class get_search_data extends React.Component {
  constructor(page, per_page, column, direction, search) {
    super(page, per_page, column, direction, search);

    this.search = search;
    this.column = column;
    this.direction = direction;
    this.per_page = per_page;
    this.page = page;

    that = this;
  }

  get_data() {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var msisdn = localStorage.getItem("ootel_msisdn");
    var role = localStorage.getItem("ootel_role");
    var to_date = document.querySelector("#to_date").value;
    var from_date = document.querySelector("#from_date").value;
    var search_value;

    var postData = {
      auth_token: ootel_auth_token,
      search: this.search,
      column: this.column,
      direction: this.direction,
      per_page: this.per_page,
      role: "vendor",
      msisdn: msisdn,
      from_date: from_date,
      page: this.page,
      loaded_data: loaded_data,
      to_date: to_date,
    };
    var tab = $("#table").DataTable();
    try {
      tab.destroy();
    } catch (e) {}

    tab = $("#table").DataTable({
      processing: true,
      serverSide: true,
      bPaginate: true, // Pagination True
      sPaginationType: "full_numbers", // And its type.
      iDisplayLength: 10,
      //Initial no order.
      search: {
        search: "",
      },
      ajax: {
        //  "url": "get_completed_tests_for_print_backup.php",
        url: "http://127.0.0.1:5000/get_contact_history",

        type: "POST",
        dataType: "JSON",
        data: {
          auth_token: ootel_auth_token,
          column: this.column,
          direction: this.direction,
          per_page: this.per_page,
          role: "vendor",
          msisdn: msisdn,
          from_date: from_date,
          page: this.page,
          loaded_data: loaded_data,
          to_date: to_date,
        },

        dataSrc: function(json1) {
          var return_data = new Array();
          var data = json1.data;
          var data1 = json1.data1;
          var k = 1;
          for (var i = 0; i < data.length; i++) {
            var img = url.image_url + "/" + data[i].photo.split("<>")[0];

            var img_text = "<img src='" + img + "' width='200'>";
            if (localStorage.getItem("payment_status") == "yes") {
              var btn = `<button value=${data[i].member_id} role=${data[i].role} class="view_details" >View Contacts</button>`;
            } else {
              var btn = "You are not paid member";
            }

            return_data.push({
              photo: img_text,
              contacted_on: data[i].contacted_on,
              name: data[i].name,
              locality: data[i].locality,
              role: data[i].role,
              view_details: btn,
              recordsTotal: 11,

              recordsFiltered: 11,
            });
          }
          $("#table11_filter")
            .find("input")
            .css({ width: "700px", "margin-left": "-80%" });
          $("#table11_filter")
            .find("input")
            .attr("placeholder", "Search  Name or Mobile No");

          return return_data;
        },
      },
      createdRow: function(row, data, index) {},
      columnDefs: [
        {
          targets: [0, 1], //first column / numbering column
          orderable: false, //set not orderable
        },
      ],
      columns: [
        { data: "photo" },
        { data: "contacted_on" },

        { data: "name" },
        { data: "locality" },
        { data: "role" },

        { data: "view_details" },
      ],
    });
  }
}
class view_details extends React.Component {
  constructor(props) {
    super(props);
    this.id = props;
  }
  click_fun() {
    try {
      var classname = document.getElementsByClassName("view_details");

      var postData = {
        id: this.id,
        auth_token: localStorage.getItem("ootel_auth_token"),
        contact: "yes",
      };

      const rawResponse = fetch(
        url.server_url + "/get_employee_specification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          try {
            if (data.error.length != 0) {
              alert(data.error);
              return;
            }
          } catch (e) {}

          try {
            var data1 = data.data;

            name = data1[0].name;
            business_name = data1[0].business_name;
            capacity = data1[0].capacity;

            var pan_number = data1[0].pan_no;
            var adhaar_number = data1[0].aadhar_no;
            specialization_area = data1[0].specialization_area;

            var nominee = data1[0].nominee;
            email_id = data1[0].email_id;
            website = data1[0].website;

            var blood_group = data1[0].blood_group;

            msisdn = data1[0].msisdn;
            var gender = data1[0].gender;
            var nominee_relation = data1[0].nominee_relation;
            var emergency_msisdn = data1[0].emergency_msisdn;

            address = data1[0].address;
            state = data1[0].state;
            city = data1[0].city;

            pin = data1[0].pin;
            locality = data1[0].locality;

            var address_permanent = data1[0].address_permanent;
            var state_permanent = data1[0].state_permanent;
            var city_permanent = data1[0].city_permanent;

            var pin_permanent = data1[0].pin_permanent;
            var locality_permanent = data1[0].locality_permanent;
            var alt_msisdn = data1[0].alt_msisdn;
            var landline = data1[0].landline;
            var landmark = data1[0].landmark;
            var year_of_establishment = data1[0].year_of_establishment;
            var gst_no = data1[0].gst_no;
            var dob = data1[0].dob;

            try {
              var images1 = data1[0].photo.split("<>");
              var photos = [];
              for (var i = 0; i < images1.length; i++) {
                if (images1[i] != "") {
                  photos.push({
                    src: url.image_url + "/" + images1[i],
                    width: 1,
                    height: 1,
                  });
                }
              }
              ReactDOM.render(
                <Photo_details data={photos} />,
                document.getElementById("photo_details1")
              );
            } catch (e) {}

            //For view profile

            document.querySelector("#name1").innerHTML = name;
            document.querySelector("#msisdn1").innerHTML = msisdn;
            document.querySelector("#alt_msisdn1").innerHTML = alt_msisdn;
            document.querySelector("#landline1").innerHTML = landline;
            document.querySelector("#business_name1").innerHTML = business_name;
            document.querySelector("#landmark1").innerHTML = landmark;

            document.querySelector("#state1").innerHTML = state;

            document.querySelector("#locality1").innerHTML = locality;
            document.querySelector("#pin1").innerHTML = pin;
            document.querySelector("#city1").innerHTML = city;

            document.querySelector(
              "#year_of_establishment1"
            ).innerHTML = year_of_establishment;
            document.querySelector("#gst_no1").innerHTML = gst_no;

            var html = "";

            var x = data1[0].area_of_experience_profile;
            try {
              x = JSON.parse(x);
              for (var i = 0; i < x.length; i++) {
                html = html + x[i].name + "<br/>";
              }
            } catch (e) {}

            document.querySelector("#area_of_experience1").innerHTML = html;
          } catch (e) {
            console.log(e.message);
          }
          MicroModal.show("modal-1");
        })
        .catch((err) => {
          console.error("Error: ", err);
        });
    } catch (e) {}
  }
}

class view_details_employee extends React.Component {
  constructor(props) {
    super(props);
    this.id = props;
  }

  click_fun() {
    try {
      var classname = document.getElementsByClassName("view_details");

      var postData = {
        id: this.id,
        auth_token: localStorage.getItem("ootel_auth_token"),
        contact: "yes",
      };

      const rawResponse = fetch(
        url.server_url + "/get_employee_specification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          try {
            if (data.error.length != 0) {
              alert(data.error);
              return;
            }
          } catch (e) {
            console.log(e.message);
          }

          try {
            var data1 = data.data;

            name = data1[0].name;
            business_name = data1[0].business_name;
            capacity = data1[0].capacity;

            var pan_number = data1[0].pan_no;
            var adhaar_number = data1[0].aadhar_no;
            specialization_area = data1[0].specialization_area;

            var nominee = data1[0].nominee;
            email_id = data1[0].email_id;
            website = data1[0].website;

            var blood_group = data1[0].blood_group;

            msisdn = data1[0].msisdn;
            var gender = data1[0].gender;
            var nominee_relation = data1[0].nominee_relation;
            var emergency_msisdn = data1[0].emergency_msisdn;

            address = data1[0].address;
            state = data1[0].state;
            city = data1[0].city;

            pin = data1[0].pin;
            locality = data1[0].locality;

            var address_permanent = data1[0].address_permanent;
            var state_permanent = data1[0].state_permanent;
            var city_permanent = data1[0].city_permanent;

            var pin_permanent = data1[0].pin_permanent;
            var locality_permanent = data1[0].locality_permanent;
            var abroad_job = data1[0].abroad_job;

            var dob = data1[0].dob;
            document.querySelector("#name").value = name;
            document.querySelector("#msisdn").value = msisdn;
            document.querySelector("#adhaar_number").value = adhaar_number;
            try {
              document.querySelector("#blood_group").value = blood_group;
            } catch (e) {}
            try {
              document.querySelector(
                "#nominee_relation"
              ).value = nominee_relation;
            } catch (e) {}
            document.querySelector("#email_id").value = email_id;
            document.querySelector(
              "#emergency_msisdn"
            ).value = emergency_msisdn;
            document.querySelector("#pan_number").value = pan_number;
            document.querySelector("#nominee").value = nominee;
            document.querySelector("#dob").value = dob;

            document.querySelector("#state").value = state;
            document.querySelector("#address").value = address;
            document.querySelector("#locality").value = locality;
            document.querySelector("#pin").value = pin;
            document.querySelector("#city").value = city;

            document.querySelector("#state_permanent").value = state_permanent;
            document.querySelector(
              "#address_permanent"
            ).value = address_permanent;
            document.querySelector(
              "#locality_permanent"
            ).value = locality_permanent;
            document.querySelector("#pin_permanent").value = pin_permanent;
            document.querySelector("#city_permanent").value = city_permanent;

            var work_experience = data1[0].total_experience;
            var salary = data1[0].salary;
            var bank_account_no = data1[0].bank_account_no;
            var ifsc_code = data1[0].ifsc_code;
            document.querySelector("#work_experience").value = work_experience;
            document.querySelector("#salary").value = salary;
            document.querySelector("#ifsc_code").value = ifsc_code;
            document.querySelector("#bank_account_no").value = bank_account_no;

            var details = JSON.parse(data1[0].work_experience);

            try {
              document
                .getElementById("experience_table")
                .getElementsByTagName("tbody")[0].innerHTML = "";
              var tableRef = document
                .getElementById("experience_table")
                .getElementsByTagName("tbody")[0];

              var newRow = tableRef.insertRow(tableRef.rows.length);
              newRow.innerHTML = "";
              var html = "";

              for (var i = 0; i < details.length; i++) {
                var org = details[i].organization;
                var years = details[i].years;
                var role = details[i].role;

                var url1 = new URL(window.location.href);
                var c = url1.searchParams.get("type");

                if (c == "view") {
                  html =
                    html +
                    "<tr><td>" +
                    org +
                    "</td><td>" +
                    years +
                    "</td><td>" +
                    role +
                    "</td></tr>";
                } else {
                  html =
                    html +
                    "<tr><td>" +
                    org +
                    "</td><td>" +
                    years +
                    "</td><td>" +
                    role +
                    "</td></tr>";
                }
              }
              newRow.innerHTML = html;
              try {
                var classname = document.getElementsByClassName("delete");

                var myFunction = function(e) {
                  e.preventDefault();
                  this.parentNode.parentNode.remove();
                };

                for (var i = 0; i < classname.length; i++) {
                  classname[i].addEventListener("click", myFunction, false);
                }
              } catch (e) {
                console.log(e.message);
              }
            } catch (e) {}

            var gender_array = document.querySelectorAll(".gender");

            for (var i = 0; i < gender_array.length; i++) {
              if (gender_array[i].value == gender) {
                gender_array[i].checked = true;
              }
            }

            var abroad_job_array = document.querySelectorAll(".abroad_job");

            for (var i = 0; i < abroad_job_array.length; i++) {
              if (abroad_job_array[i].value == abroad_job) {
                abroad_job_array[i].checked = true;
              }
            }

            try {
              var images1 = data1[0].photo.split("<>");
              var photos = [];
              for (var i = 0; i < images1.length; i++) {
                if (images1[i] != "") {
                  photos.push({
                    src: url.image_url + "/" + images1[i],
                    width: 1,
                    height: 1,
                  });
                }
              }
              ReactDOM.render(
                <Photo_details data={photos} />,
                document.getElementById("photo_details")
              );
            } catch (e) {}

            //For view profile

            document.querySelector("#name").innerHTML = name;
            document.querySelector("#msisdn").innerHTML = msisdn;
            document.querySelector("#adhaar_number").innerHTML = adhaar_number;
            document.querySelector("#blood_group_view").innerHTML = blood_group;
            document.querySelector("#email_id").innerHTML = email_id;
            document.querySelector(
              "#emergency_msisdn"
            ).innerHTML = emergency_msisdn;
            document.querySelector("#pan_number").innerHTML = pan_number;
            document.querySelector("#nominee").innerHTML = nominee;
            document.querySelector("#nominee_relation").innerHTML =
              data1[0].nominee_relation;
            document.querySelector("#dob").innerHTML = dob;

            document.querySelector("#state").innerHTML = state;
            document.querySelector("#address").innerHTML = address;
            document.querySelector("#locality").innerHTML = locality;
            document.querySelector("#pin").innerHTML = pin;
            document.querySelector("#city").innerHTML = city;

            document.querySelector(
              "#state_permanent"
            ).innerHTML = state_permanent;
            document.querySelector(
              "#address_permanent"
            ).innerHTML = address_permanent;
            document.querySelector(
              "#locality_permanent"
            ).innerHTML = locality_permanent;
            document.querySelector("#pin_permanent").innerHTML = pin_permanent;
            document.querySelector(
              "#city_permanent"
            ).innerHTML = city_permanent;

            var work_experience = data1[0].total_experience;
            var salary = data1[0].salary;
            var bank_account_no = data1[0].bank_account_no;
            var ifsc_code = data1[0].ifsc_code;
            document.querySelector(
              "#work_experience"
            ).innerHTML = work_experience;
            document.querySelector("#salary").innerHTML = salary;
            document.querySelector("#ifsc_code").innerHTML = ifsc_code;
            document.querySelector(
              "#bank_account_no"
            ).innerHTML = bank_account_no;
            document.querySelector("#abroad_job").innerHTML = abroad_job;
            var html = "";
            var x = data1[0].heighest_eductaion;
            x = JSON.parse(x);
            for (var i = 0; i < x.length; i++) {
              html = html + x[i].name + "<br/>";
            }

            document.querySelector("#education").innerHTML = html;

            var html = "";
            var x = data1[0].education_specialization;
            x = JSON.parse(x);
            for (var i = 0; i < x.length; i++) {
              html = html + x[i].name + "<br/>";
            }

            document.querySelector("#specialization").innerHTML = html;

            var html = "";
            var x = data1[0].languages;
            x = JSON.parse(x);
            for (var i = 0; i < x.length; i++) {
              html = html + x[i].name + "<br/>";
            }

            document.querySelector("#languages").innerHTML = html;

            var html = "";
            var x = data1[0].area_of_experience;

            x = JSON.parse(x);
            for (var i = 0; i < x.length; i++) {
              html = html + x[i].name + "<br/>";
            }

            document.querySelector("#area_of_experience").innerHTML = html;
          } catch (e) {
            console.log(e.message);
          }

          MicroModal.show("modal-12");
        })
        .catch((err) => {
          console.error("Error: ", err);
        });
    } catch (e) {
      console.log(e.message);
    }
  }
}

class Counter_edit_employee extends React.Component {
  render() {
    return (
      <div>
        <h6>Basic Details</h6>

        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <b> Name:</b> <span id="name"></span>
            <br />
            <b> Mobile:</b>
            <span id="msisdn"></span>
            <br />
            <b> Gender:</b>
            <span id="gender"></span>
            <br />
            <b> Emergency Contact Number:</b>
            <span id="emergency_msisdn"></span>
            <br />
            <b> Email ID:</b>
            <span id="email_id"></span>
            <br />
            <b> Date of Birth:</b>
            <span id="dob"></span>
            <br />
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <b> Adhaar Number:</b>
            <span id="adhaar_number"></span>
            <br />
            <b> PAN Number:</b>
            <span id="pan_number"></span>
            <br />

            <b> Blood Group:</b>
            <span id="blood_group_view"></span>

            <br />

            <b>Nominee:</b>
            <span id="nominee"></span>
            <br />

            <b> Relationship with Nominee:</b>
            <span id="nominee_relation"></span>
            <br />
          </GridItem>
        </GridContainer>
        <hr />
        <h6>Address Details</h6>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <h7>Present Address</h7>
            <br />

            <b> Address:</b>
            <span id="address"></span>
            <br />

            <b> State:</b>
            <span id="state"></span>

            <br />

            <b>City:</b>
            <span id="city"></span>
            <br />

            <b> Locality:</b>
            <span id="locality"></span>
            <br />
            <b> PIN:</b>
            <span id="pin"></span>
            <br />
          </GridItem>

          <GridItem xs={6} sm={6} md={6}>
            <h7>Permanent Address</h7>
            <br />

            <b> Address:</b>
            <span id="address_permanent"></span>
            <br />

            <b> State:</b>
            <span id="state_permanent"></span>

            <br />
            <b>City:</b>
            <span id="city_permanent"></span>
            <br />

            <b> Locality:</b>
            <span id="locality_permanent"></span>
            <br />
            <b> PIN:</b>
            <span id="pin_permanent"></span>
            <br />
          </GridItem>
        </GridContainer>

        <hr />
        <h6>Qualification Details</h6>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <b>Heighest Education :</b>
            <span id="required"></span>
            <div id="education"></div>

            <b>Specialization:</b>

            <div id="specialization" name="specialization"></div>

            <b>Languages Known:</b>
            <span id="required"></span>
            <div id="languages"></div>
          </GridItem>
        </GridContainer>

        <hr />
        <h6>Experience Details</h6>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <b>Total Work Experience :</b>
            <span id="work_experience"></span> Years.
            <br />
            <b>Area Of Experience:</b>
            <div id="area_of_experience" />
            <b>Work Expeience:</b>
            <span id="required"></span>
            <table
              id="experience_table"
              style={{ width: "100%", fontSize: "10px" }}
            >
              <thead>
                <tr>
                  <th>
                    <h8>Organization Name</h8>
                  </th>
                  <th>Years</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
            <b>Current Salary:</b>
            <span id="salary"></span>
            <br />
            <b>Interested in Abroad Job?:</b>
            <span id="abroad_job"></span>
            <br />
          </GridItem>
        </GridContainer>

        <hr />
        <h6>Bank Details</h6>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <b>Bank Account Number :</b>
            <span id="bank_account_no"></span>

            <br />

            <b>IFSC Code:</b>
            <span id="ifsc_code"></span>
          </GridItem>
        </GridContainer>

        <hr />

        <h6>Photo Details</h6>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <b>Photos:</b>

            <div id="photo_details"></div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

class modal extends React.Component {
  get_data() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div
            style={{ width: "100%" }}
            className="modal micromodal-slide"
            id="modal-1"
            aria-hidden="true"
          >
            <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
              <div
                style={{ maxWidth: "1000px", width: "1000px" }}
                className="modal__container"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-1-title"
              >
                <main className="modal__content" id="modal-1-content">
                  {new Counter_edit().render()}
                </main>
                <footer className="modal__footer">
                  <button
                    className="modal__btn"
                    data-micromodal-close
                    aria-label="Close this dialog window"
                  >
                    Close
                  </button>
                </footer>
              </div>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}
class modal1 extends React.Component {
  get_data() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div
            style={{ width: "100%" }}
            className="modal micromodal-slide"
            id="modal-12"
            aria-hidden="true"
          >
            <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
              <div
                style={{ maxWidth: "1000px", width: "1000px" }}
                className="modal__container"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-1-title"
              >
                <main className="modal__content" id="modal-12-content">
                  {new Counter_edit_employee().render()}
                </main>
                <footer className="modal__footer">
                  <button
                    className="modal__btn"
                    data-micromodal-close
                    aria-label="Close this dialog window"
                  >
                    Close
                  </button>
                </footer>
              </div>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}
