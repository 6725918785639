import React from "react";
export default class Welcome extends React.Component {
        constructor() {
       super();
       var ootel_auth_token=localStorage.getItem('ootel_auth_token');  

localStorage.removeItem('ootel_auth_token');  
          document.location.href='http://127.0.0.1:5000/logout?ootel_auth_token='+ootel_auth_token; 
       
   }
        
        

      }