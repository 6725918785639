var server_url="https://otel.co.in:10001";
var portal_url="http://127.0.0.1:3000";
var image_url="http://127.0.0.1:3000/uploads";
// var image_url = "../uploads";
export default  {
 
  server_url,
  portal_url,
  image_url
 
};
