import React, { useState, useCallback, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import url from "assets/js/config.js";

import Switch from "@material-ui/core/Switch";
import employeeAvailabilityData from "./employee_availability.json";

var handleOnOff = () => {
  var is_on = document.querySelector("#onOffSwicth").checked;

  if (is_on) {
    document.querySelector("#lblOnOff").innerHTML =
      '<b style ="color:black">On</b>';
  } else {
    document.querySelector("#lblOnOff").innerHTML = "Off";
  }

  var ootel_auth_token = localStorage.getItem("ootel_auth_token");
  var msisdn = localStorage.getItem("ootel_msisdn");

  var postData = {
    auth_token: ootel_auth_token,
    msisdn: msisdn,
    is_on: is_on,
  };
  const rawResponse = fetch(url.server_url + "/on_off_employee_availability", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(postData),
  })
    .then((res) => res.json())
    .then((data) => {
      try {
        if (data.error.length != 0) {
          alert(data.error);
          return;
        }
      } catch (e) {}
      alert(data.data);
    })
    .catch((err) => {
      console.error("Error: ", err);
    });
};

export default class Employee_availability extends React.Component {
  constructor() {
    super();
  }
  componentDidMount() {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var msisdn = localStorage.getItem("ootel_msisdn");

    var slot1_data = new Array();

    var dataFromTemplate = employeeAvailabilityData.data.filter(function (sch) {
      return sch.slot_no === "1";
    });
      //.sort(function (x, y) {
        //   return x.day_of_week - y.day_of_week;
        //   });
    var k = 1;
    for (var i = 0; i < dataFromTemplate.length; i++) {
      slot1_data.push({
        day_name:
          '<b style={{ fontSize: "13px", margin: "1px" }}>' +
          dataFromTemplate[i].day_name +
          "</b>",
        from_time: '<input type="time" id="fromTime" />',
        to_time: '<input type="time" id="toTime" />',
        is_availability:
          '<input type="checkbox" id="check_availability" style={{ width: "20px", height: "19px" }}/>',
       
      });
    }
    var tab = $("#emergency_employee_table_slot1").DataTable();
    try {
      tab.destroy();
    } catch (e) {}

    tab = $("#emergency_employee_table_slot1").DataTable({
     "sorting":false,
      "searching": false,
        "paging": false,
      "bInfo": false,
      data: slot1_data,      
      columns: [
        { data: "day_name" },
        { data: "from_time" },
        { data: "to_time" },
        { data: "is_availability" },
      ],
    });
      
    var slot2_data = new Array();

    dataFromTemplate = employeeAvailabilityData.data.filter(function (sch) {
                return sch.slot_no === "2";
        }).sort();
    var k = 1;
    for (var i = 0; i < dataFromTemplate.length; i++) {
      slot2_data.push({
        from_time: '<input type="time" id="fromTime" />',
        to_time: '<input type="time" id="toTime" />',
        is_availability:
              '<input type="checkbox" id="check_availability" style={{ width: "20px", height: "19px" }}/>',
        full_day:
          '<input type="checkbox" id="check_fullday" style={{ width: "20px", height: "19px" }}/>',
       
      });
    }
    var tab = $("#emergency_employee_table_slot2").DataTable();
    try {
      tab.destroy();
    } catch (e) {}

    tab = $("#emergency_employee_table_slot2").DataTable({
      "sorting":false,
        "searching": false,
        "paging": false,
      "bInfo": false,
      data: slot2_data,      
      columns: [
        { data: "from_time" },
        { data: "to_time" },
        { data: "is_availability" },
        { data: "full_day" },
      ],
    });
      
      
  }

  render() {
    return (
      <div className="App">
        <h6>Emergency Employment Availability</h6>
        <GridContainer>
          <GridItem xs={12} sm={6} md={10}></GridItem>
          <GridItem xs={12} sm={6} md={2}>
            <div>
              <Switch
                color="primary"
                id="onOffSwicth"
                onChange={handleOnOff}
                value="On"
              />{" "}
              <label id="lblOnOff">Off</label>
            </div>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <b>1st Slot</b>
            <table
              id="emergency_employee_table_slot1"
              style={{ width: "100%", fontSize: "10px" }}
            >
              <thead>
                <tr>
                  <th>
                    <h8>Days</h8>
                  </th>
                  <th>From</th>
                  <th>To</th>
                  <th>Availability</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
            <br />
            <br />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <b>2nd Slot</b>
            <table
              id="emergency_employee_table_slot2"
              style={{ width: "100%", fontSize: "10px" }}
            >
              <thead>
                <tr>
                  <th>From</th>
                  <th>To</th>
                  <th>Availability</th>
                  <th>Full Day</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
            <br />
            <br />
          </GridItem>
            </GridContainer>
            {/* <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <button id="btnApply" className="btn btn-primary" style= {{ float: "right"}}>
              Apply
            </button>
            <br />
            <br />
          </GridItem>
        </GridContainer> */}
      </div>
    );
  }
}
