import React from "react";

import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import CustomTabs from "components/CustomTabs/CustomTabs.js";

import Slider from "views/hotel_owners/slider/slider.js";
import "assets/css/slider.scss";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import MicroModal from "micromodal";
import "assets/css/styles.css";
import url from "assets/js/config.js";

export default class Welcome extends React.Component {
  constructor() {
    super();

    this.state = {
      index: true,
      setIndex: false,
      direction: true,
      setDirection: false,
    };

    const handleSelect = (selectedIndex, e) => {
      this.setState({
        index: false,
        setIndex: true,
        direction: false,

        setDirection: true,
      });
    };
    const my_style = {
      width: "100px !important",
    };
  }

  componentDidMount() {
    MicroModal.init({
      onShow: (modal) => console.info("${modal.id} is shown"),
      onClose: (modal) => console.info("${modal.id} is hidden"),
      openTrigger: "data-custom-open", // [3]
      closeTrigger: "data-custom-close", // [4]
      disableScroll: true, // [5]
      disableFocus: false, // [6]
      awaitOpenAnimation: false, // [7]
      awaitCloseAnimation: false, // [8]
      debugMode: true, // [9]
    });

    const slides = [
      {
        city: "Paris",
        country: "France",
        img: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/paris.jpg",
      },
      {
        city: "Singapore",
        img:
          "https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/singapore.jpg",
      },
      {
        city: "Prague",
        country: "Czech Republic",
        img: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/prague.jpg",
      },
      {
        city: "Amsterdam",
        country: "Netherlands",
        img:
          "https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/amsterdam.jpg",
      },
      {
        city: "Moscow",
        country: "Russia",
        img: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/moscow.jpg",
      },
    ];

    // ReactDOM.render(<Carousel />, document.getElementById('carousel'));
    ReactDOM.render(
      <Slider slides={slides} />,
      document.getElementById("slider")
    );
    // ReactDOM.render(<Carousel />, document.getElementById('carousel'));

    document.querySelector(".login").addEventListener("click", function() {
      MicroModal.show("modal-12");
    });
    document.querySelector("#login_div").addEventListener("click", function() {
      var uid = document.querySelector("#uid").value;
      var pwd = document.querySelector("#pwd").value;

      var postData = { uid: uid, pwd: pwd };
      //    alert(url);
      const rawResponse = fetch(url.server_url + "/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      })
        .then((res) => res.json())
        .then((data) => {
          try {
            if (data.error.length != 0) {
              alert(data.error);
              return;
            }
          } catch (e) {}
          var auth_token = data.auth_token;
          var msisdn = data.msisdn;
          var name = data.name;
          var role = data.role;
          var payment_status = data.payment_status;
          var refferedby_msisdn = data.refferedby_msisdn;

          // Store
          localStorage.setItem("ootel_auth_token", auth_token);
          localStorage.setItem("ootel_msisdn", msisdn);
          localStorage.setItem("ootel_name", name);
          localStorage.setItem("ootel_role", role);
          localStorage.setItem("payment_status", payment_status);
          localStorage.setItem("refferedby_msisdn", refferedby_msisdn);
         

          if (role == "hotel_owner") {
            var subrole = data.subrole != null ? data.subrole : "";
            localStorage.setItem("subrole", subrole);
            var port_url =
              window.location.hostname +
              ":" +
              window.location.port +
              "/hotel_owner/dashboard";

            //document.location.href=port_url;
          }
          document.location.reload();
        })
        .catch((err) => {
          console.error("Error: ", err);
        });
    });
    document.querySelector(".register").addEventListener("click", function() {
      MicroModal.show("modal-1");
    });

    document
      .querySelector("#register_div")
      .addEventListener("click", function(e) {
        e.preventDefault();
        var formData = new FormData();

        document.querySelector("#register_div").setAttribute("disabled", true);

        var fname = document.querySelector("#fname").value;
        var lname = document.querySelector("#lname").value;
        var msisdn = document.querySelector("#msisdn").value;
        var role = document.querySelector("#role").value;
        var pwd = document.querySelector("#pwd1").value;
        formData = {
          fname: fname,
          lname: lname,
          msisdn: msisdn,
          role: role,
          pwd: pwd,
        };

        const rawResponse = fetch(url.server_url + "/register", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify(formData),
        })
          .then((res) => res.json())
          .then((data) => {
            try {
              if (data.error.length != 0) {
                alert(data.error);
                document
                  .querySelector("#register_div")
                  .removeAttribute("disabled");
                return;
              }
            } catch (e) {}
            alert(data.data);
            document.querySelector("#register_div").removeAttribute("disabled");
          })
          .catch((err) => {
            console.error("Error: ", err);
          });
      });
  }
  openCity(evt, cityName, name) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tab-pane");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("active", "");
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      //tablinks[i].className = tablinks[i].parentNode.className.replace("active", "");
    }

    try {
      document.getElementById(cityName).style.display = "block";
      document
        .querySelector(".tab1")
        .parentNode.removeAttribute("class", "active");
      document
        .querySelector(".tab2")
        .parentNode.removeAttribute("class", "active");
      document
        .querySelector(".tab3")
        .parentNode.removeAttribute("class", "active");
      document
        .querySelector(".tab4")
        .parentNode.removeAttribute("class", "active");

      document
        .querySelector("." + name)
        .parentNode.setAttribute("class", "active");
    } catch (e) {
      console.log(e.message);
    }
  }
  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    return (
      <div>
        <header>
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
            fixed="top"
          >
            <Navbar.Brand href="#home">
              <img
                src={require("img/logo.png")}
                alt="image"
                style={{ width: "120px" }}
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link href="#features">Home</Nav.Link>
                <Nav.Link href="#pricing">About Us</Nav.Link>
                <Nav.Link href="#pricing">Contact Us</Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link
                  href="#deets"
                  className="login"
                  data-custom-open="modal-12"
                >
                  Login
                </Nav.Link>
                <Nav.Link
                  href="#deets1"
                  className="register"
                  data-custom-open="modal-1"
                >
                  Registration
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </header>
        <div id="slider"></div>

        <div className="homepage-blog-area">
          <div className="container">
            <div className="home-page-services-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 acurate">
                    <div className="single-servies service-1">
                      <h3>
                        <a href="#">Restaurant</a>
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 acurate">
                    <div className="single-servies service-2">
                      <h3>
                        <a href="#">Cafe</a>
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 acurate">
                    <div className="single-servies service-3">
                      <h3>
                        <a href="#">Bar</a>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="homepage-blog-area">
            <div className="home-page-about-us-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                    <div className="home-page-about-us-image">
                      <div className="about-image">
                        <a href="#">
                          <img src={require("img/about/about.png")} alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                    <div className="home-page-about-us-content">
                      <h2>
                        We <span>Believe</span> In Taste
                      </h2>
                      <p>
                        Simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining
                        essentially unchanged.
                      </p>
                      <div className="read-more">
                        <button type="button" className="btn btn-lg">
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="home-page-schedule-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="section-area">
                      <h2>We are Open</h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Voluptatum nisi quam magni quos laborum, dolorum
                        accusamus maiores, iste aspernatur reprehenderit omnis.
                        Odio consectetur itaque assumenda nostrum et perferendis
                        id! Ut.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="left-shedule-area">
                      <nav>
                        <ul>
                          <li>
                            Monday <span>9:00 am - 10:00 PM</span>
                          </li>
                          <li>
                            Wednesday <span>9:00 am - 10:00 PM</span>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="right-shedule-area">
                      <nav>
                        <ul>
                          <li>
                            Tuesday <span>9:00 am - 10:00 PM</span>
                          </li>
                          <li>
                            Thursday <span>9:00 am - 10:00 PM</span>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="homepage-menu-area">
            <div className="container">
              <div className="row">
                <div className="total-menu-area">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <ul className="nav nav-tabs menu-list" role="tablist">
                      <li role="presentation">
                        <a
                          className="tablinks tab1"
                          onClick={(e) => this.openCity(this, "hotel", "tab1")}
                          href="#Hotel1"
                          aria-controls="Hotel"
                          role="tab"
                          data-toggle="tab"
                          aria-expanded="false"
                        >
                          For Hotel/Restaurant Owners
                        </a>
                      </li>
                      <li role="presentation">
                        <a
                          className="tablinks tab2"
                          onClick={(e) =>
                            this.openCity(this, "Employees", "tab2")
                          }
                          href="#Employees1"
                          aria-controls="Employees"
                          role="tab"
                          data-toggle="tab"
                          aria-expanded="false"
                        >
                          For Employees
                        </a>
                      </li>
                      <li role="presentation">
                        <a
                          className="tablinks tab3"
                          onClick={(e) =>
                            this.openCity(this, "Vendors", "tab3")
                          }
                          href="#Vendors1"
                          aria-controls="Vendors"
                          role="tab"
                          data-toggle="tab"
                          aria-expanded="false"
                        >
                          For Vendors
                        </a>
                      </li>
                      <li role="presentation">
                        <a
                          className="tablinks tab4"
                          onClick={(e) =>
                            this.openCity(this, "Service", "tab4")
                          }
                          href="#Service1"
                          aria-controls="Service"
                          role="tab"
                          data-toggle="tab"
                          aria-expanded="true"
                        >
                          Service Providers
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="tab-content menu-details">
                    <div role="tabpanel" className="tab-pane" id="hotel">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <nav>
                            <ul className="single-dish-list">
                              <li>
                                <div className="media single-dish">
                                  <a className="pull-left" href="#">
                                    <img
                                      src={require("img/Hire Skilled Workforce in Single Platform.png")}
                                      alt="menu"
                                      style={{ width: "80px" }}
                                    />
                                  </a>
                                  <div className="media-body dish-content">
                                    <h3 className="media-heading">
                                      <a href="#">
                                        Hire Skilled Workforce in Single
                                        Platform
                                      </a>
                                    </h3>
                                    <p>
                                      Cras sit amet nibh libero, in gravida
                                      nulla. Nulla vel metus scelerisque anteso.
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media single-dish">
                                  <a className="pull-left" href="#">
                                    <img
                                      src={require("img/Buy Items.png")}
                                      alt="menu"
                                      style={{ width: "80px" }}
                                    />
                                  </a>
                                  <div className="media-body dish-content">
                                    <h3 className="media-heading">
                                      <a href="#">Buy Items</a>
                                    </h3>
                                    <p>
                                      Cras sit amet nibh libero, in gravida
                                      nulla. Nulla vel metus scelerisque anteso.
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </nav>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <nav>
                            <ul className="single-dish-list">
                              <li>
                                <div className="media single-dish">
                                  <a className="pull-left" href="#">
                                    <img
                                      src={require("img/Find Service Providers.png")}
                                      alt="menu"
                                      style={{ width: "80px" }}
                                    />
                                  </a>
                                  <div className="media-body dish-content">
                                    <h3 className="media-heading">
                                      <a href="#">Find Service Providers</a>
                                    </h3>
                                    <p>
                                      Cras sit amet nibh libero, in gravida
                                      nulla. Nulla vel metus scelerisque anteso.
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media single-dish">
                                  <a className="pull-left" href="#">
                                    <img
                                      src={require("img/Get Catering Orders.png")}
                                      alt="menu"
                                      style={{ width: "80px" }}
                                    />
                                  </a>
                                  <div className="media-body dish-content">
                                    <h3 className="media-heading">
                                      <a href="#">Get Catering Orders</a>
                                    </h3>
                                    <p>
                                      Cras sit amet nibh libero, in gravida
                                      nulla. Nulla vel metus scelerisque anteso.
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div role="tabpanel" className="tab-pane" id="Employees">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <nav>
                            <ul className="single-dish-list">
                              <li>
                                <div className="media single-dish">
                                  <a className="pull-left" href="#">
                                    <img
                                      src={require("img/Find Job.png")}
                                      alt="menu"
                                      style={{ width: "80px" }}
                                    />
                                  </a>
                                  <div className="media-body dish-content">
                                    <h3 className="media-heading">
                                      <a href="#">Find Job</a>
                                    </h3>
                                    <p>
                                      Cras sit amet nibh libero, in gravida
                                      nulla. Nulla vel metus scelerisque anteso.
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media single-dish">
                                  <a className="pull-left" href="#">
                                    <img
                                      src={require("img/Abroad Job Opportunities.png")}
                                      alt="menu"
                                      style={{ width: "80px" }}
                                    />
                                  </a>
                                  <div className="media-body dish-content">
                                    <h3 className="media-heading">
                                      <a href="#">Abroad Job Opportunities</a>
                                    </h3>
                                    <p>
                                      Cras sit amet nibh libero, in gravida
                                      nulla. Nulla vel metus scelerisque anteso.
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </nav>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <nav>
                            <ul className="single-dish-list">
                              <li>
                                <div className="media single-dish">
                                  <a className="pull-left" href="#">
                                    <img
                                      src={require("img/Refer and Earn.png")}
                                      alt="menu"
                                      style={{ width: "80px" }}
                                    />
                                  </a>
                                  <div className="media-body dish-content">
                                    <h3 className="media-heading">
                                      <a href="#">Refer and Earn</a>
                                    </h3>
                                    <p>
                                      Cras sit amet nibh libero, in gravida
                                      nulla. Nulla vel metus scelerisque anteso.
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media single-dish">
                                  <a className="pull-left" href="#">
                                    <img
                                      src={require("img/Emergency contract Jobs.png")}
                                      alt="menu"
                                      style={{ width: "80px" }}
                                    />
                                  </a>
                                  <div className="media-body dish-content">
                                    <h3 className="media-heading">
                                      <a href="#">Emergency/Contract Jobs</a>
                                    </h3>
                                    <p>
                                      Cras sit amet nibh libero, in gravida
                                      nulla. Nulla vel metus scelerisque anteso.
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div role="tabpanel" className="tab-pane" id="Vendors">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <nav>
                            <ul className="single-dish-list">
                              <li>
                                <div className="media single-dish">
                                  <a className="pull-left" href="#">
                                    <img
                                      src={require("img/An open market to sell goods.png")}
                                      alt="menu"
                                      style={{ width: "80px" }}
                                    />
                                  </a>
                                  <div className="media-body dish-content">
                                    <h3 className="media-heading">
                                      <a href="#">
                                        An open market to sell goods
                                      </a>
                                    </h3>
                                    <p>
                                      Cras sit amet nibh libero, in gravida
                                      nulla. Nulla vel metus scelerisque anteso.
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media single-dish">
                                  <a className="pull-left" href="#">
                                    <img
                                      src={require("img/Explore huge requirements.png")}
                                      alt="menu"
                                      style={{ width: "80px" }}
                                    />
                                  </a>
                                  <div className="media-body dish-content">
                                    <h3 className="media-heading">
                                      <a href="#">Explore huge requirements</a>
                                    </h3>
                                    <p>
                                      Cras sit amet nibh libero, in gravida
                                      nulla. Nulla vel metus scelerisque anteso.
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </nav>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <nav>
                            <ul className="single-dish-list">
                              <li>
                                <div className="media single-dish">
                                  <a className="pull-left" href="#">
                                    <img
                                      src={require("img/A genuine platform to sell.png")}
                                      alt="menu"
                                      style={{ width: "80px" }}
                                    />
                                  </a>
                                  <div className="media-body dish-content">
                                    <h3 className="media-heading">
                                      <a href="#">A genuine platform to sell</a>
                                    </h3>
                                    <p>
                                      Cras sit amet nibh libero, in gravida
                                      nulla. Nulla vel metus scelerisque anteso.
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media single-dish">
                                  <a className="pull-left" href="#">
                                    <img
                                      src={require("img/Transparency.png")}
                                      alt="menu"
                                      style={{ width: "80px" }}
                                    />
                                  </a>
                                  <div className="media-body dish-content">
                                    <h3 className="media-heading">
                                      <a href="#">Transparency</a>
                                    </h3>
                                    <p>
                                      Cras sit amet nibh libero, in gravida
                                      nulla. Nulla vel metus scelerisque anteso.
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div
                      role="tabpanel"
                      className="tab-pane active"
                      id="Service"
                    >
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <nav>
                            <ul className="single-dish-list">
                              <li>
                                <div className="media single-dish">
                                  <a className="pull-left" href="#">
                                    <img
                                      src={require("img/An open platform to provide service.png")}
                                      alt="menu"
                                      style={{ width: "80px" }}
                                    />
                                  </a>
                                  <div className="media-body dish-content">
                                    <h3 className="media-heading">
                                      <a href="#">
                                        An open platform to provide service
                                      </a>
                                    </h3>
                                    <p>
                                      Cras sit amet nibh libero, in gravida
                                      nulla. Nulla vel metus scelerisque anteso.
                                    </p>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="media single-dish">
                                  <a className="pull-left" href="#">
                                    <img
                                      src={require("img/Huge Opportunities under one roof.png")}
                                      alt="menu"
                                      style={{ width: "80px" }}
                                    />
                                  </a>
                                  <div className="media-body dish-content">
                                    <h3 className="media-heading">
                                      <a href="#">
                                        Huge Opportunities under one roof
                                      </a>
                                    </h3>
                                    <p>
                                      Cras sit amet nibh libero, in gravida
                                      nulla. Nulla vel metus scelerisque anteso.
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </nav>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <nav>
                            <ul className="single-dish-list">
                              <li>
                                <div className="media single-dish">
                                  <a className="pull-left" href="#">
                                    <img
                                      src={require("img/Better customer engagements.png")}
                                      alt="menu"
                                      style={{ width: "80px" }}
                                    />
                                  </a>
                                  <div className="media-body dish-content">
                                    <h3 className="media-heading">
                                      <a href="#">
                                        Better customer engagements
                                      </a>
                                    </h3>
                                    <p>
                                      Cras sit amet nibh libero, in gravida
                                      nulla. Nulla vel metus scelerisque anteso.
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="homepage-team-area" style={{ background: "none" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="section-area">
                  <h2>Incredible Chefs</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Voluptatum nisi quam magni quos laborum, dolorum accusamus
                    maiores, iste aspernatur reprehenderit omnis. Odio
                    consectetur itaque assumenda nostrum et perferendis id! Ut.
                  </p>
                </div>
              </div>
            </div>

            <Carousel responsive={responsive}>
              <div className="total-team-area">
                <div className="team-area">
                  <div className="single-team">
                    <div className="image-round">
                      <div className="team-image">
                        <a href="#">
                          <img src={require("img/team/1.png")} alt="team" />
                        </a>
                      </div>
                    </div>
                    <div className="team-content">
                      <h3>
                        <a href="#">David Jhon</a>
                      </h3>
                      <p>CEO</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="total-team-area">
                <div className="team-area">
                  <div className="single-team">
                    <div className="image-round">
                      <div className="team-image">
                        <a href="#">
                          <img src={require("img/team/2.png")} alt="team" />
                        </a>
                      </div>
                    </div>
                    <div className="team-content">
                      <h3>
                        <a href="#">David Jhon</a>
                      </h3>
                      <p>CEO</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="total-team-area">
                <div className="team-area">
                  <div className="single-team">
                    <div className="image-round">
                      <div className="team-image">
                        <a href="#">
                          <img src={require("img/team/3.png")} alt="team" />
                        </a>
                      </div>
                    </div>
                    <div className="team-content">
                      <h3>
                        <a href="#">David Jhon</a>
                      </h3>
                      <p>CEO</p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>

        <div className="homepage-blog-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="section-area">
                  <h2>Whats Clients Say</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Minus delectus molestias quidem labore placeat eius
                    voluptates excepturi earum sit quo a deserunt veritatis
                    nesciunt magnam iure eveniet, laudantium assumenda incidunt.
                  </p>
                </div>
              </div>
            </div>

            <Carousel responsive={responsive}>
              <div className="total-blog-area">
                <div className="blog-area">
                  <div className="single-blog">
                    <div className="blog-image">
                      <a href="single-blog.html">
                        <img
                          src={require("img/blog/1.png")}
                          alt="Single Blog"
                        />
                        <i className="fa fa-link" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div className="blog-content-area">
                      <h3>
                        <a href="single-blog.html">Blog Title Here</a>
                      </h3>
                      <div className="informations">
                        <ul>
                          <li>
                            <i
                              className="fa fa-calendar"
                              aria-hidden="true"
                            ></i>{" "}
                            20/05/16
                          </li>
                          <li>
                            <i className="fa fa-user" aria-hidden="true"></i> by
                            admin
                          </li>
                        </ul>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ipsa magni, ratione nostrum, aut iusto quos saepe
                        dolorum? Temporibus, veniam magni officiis, accusamus
                        aliquam, iusto ea rerum, cum sequi ut perferendis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="total-blog-area">
                <div className="blog-area">
                  <div className="single-blog">
                    <div className="blog-image">
                      <a href="single-blog.html">
                        <img
                          src={require("img/blog/2.png")}
                          alt="Single Blog"
                        />
                        <i className="fa fa-link" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div className="blog-content-area">
                      <h3>
                        <a href="single-blog.html">Blog Title Here</a>
                      </h3>
                      <div className="informations">
                        <ul>
                          <li>
                            <i
                              className="fa fa-calendar"
                              aria-hidden="true"
                            ></i>{" "}
                            20/05/16
                          </li>
                          <li>
                            <i className="fa fa-user" aria-hidden="true"></i> by
                            admin
                          </li>
                        </ul>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ipsa magni, ratione nostrum, aut iusto quos saepe
                        dolorum? Temporibus, veniam magni officiis, accusamus
                        aliquam, iusto ea rerum, cum sequi ut perferendis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="total-blog-area">
                <div className="blog-area">
                  <div className="single-blog">
                    <div className="blog-image">
                      <a href="single-blog.html">
                        <img
                          src={require("img/blog/3.png")}
                          alt="Single Blog"
                        />
                        <i className="fa fa-link" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div className="blog-content-area">
                      <h3>
                        <a href="single-blog.html">Blog Title Here</a>
                      </h3>
                      <div className="informations">
                        <ul>
                          <li>
                            <i
                              className="fa fa-calendar"
                              aria-hidden="true"
                            ></i>{" "}
                            20/05/16
                          </li>
                          <li>
                            <i className="fa fa-user" aria-hidden="true"></i> by
                            admin
                          </li>
                        </ul>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ipsa magni, ratione nostrum, aut iusto quos saepe
                        dolorum? Temporibus, veniam magni officiis, accusamus
                        aliquam, iusto ea rerum, cum sequi ut perferendis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="total-blog-area">
                <div className="blog-area">
                  <div className="single-blog">
                    <div className="blog-image">
                      <a href="single-blog.html">
                        <img
                          src={require("img/blog/1.png")}
                          alt="Single Blog"
                        />
                        <i className="fa fa-link" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div className="blog-content-area">
                      <h3>
                        <a href="single-blog.html">Blog Title Here</a>
                      </h3>
                      <div className="informations">
                        <ul>
                          <li>
                            <i
                              className="fa fa-calendar"
                              aria-hidden="true"
                            ></i>{" "}
                            20/05/16
                          </li>
                          <li>
                            <i className="fa fa-user" aria-hidden="true"></i> by
                            admin
                          </li>
                        </ul>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Ipsa magni, ratione nostrum, aut iusto quos saepe
                        dolorum? Temporibus, veniam magni officiis, accusamus
                        aliquam, iusto ea rerum, cum sequi ut perferendis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>

        <div className="free-consult">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
                <div className="free-consult-text">
                  <p>If you have any problem ... We are available</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                <div className="get-free-consult">
                  <a href="#">Contact Us</a>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div className="google-map-area">
          <div id="googleMap"></div>
        </div>
        <footer>
          <div className="footer-top-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                  <div className="single-footer footer-one">
                    <h3>About Company</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Earum repellat, maxime vel alias impedit veritatis
                      temporibus, sequi quos veniam eius optio corporis modi
                      dicta molestias at inventore culpa, natus explicabo.
                    </p>
                    <div className="footer-social-media-area">
                      <nav>
                        <ul>
                          <li>
                            <a href="#">
                              <i className="fa fa-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-rss" aria-hidden="true"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-pinterest-p"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i
                                className="fa fa-linkedin"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="fa fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                  <div className="single-footer footer-two">
                    <h3>Twitter Feed</h3>
                    <nav>
                      <ul>
                        <li>
                          <i className="fa fa-twitter" aria-hidden="true"></i>{" "}
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. <a href="#">https://twitter.com/?lang=en</a>
                          <br /> 3 days ago
                        </li>
                        <li>
                          <i className="fa fa-twitter" aria-hidden="true"></i>{" "}
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. <a href="#">https://twitter.com/?lang=en</a>
                          <br /> 3 days ago
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                  <div className="single-footer footer-three">
                    <h3>Flickr Photos</h3>
                    <ul>
                      <li>
                        <a href="#">
                          <img
                            src={require("img/flicker/1.png")}
                            alt="flicker photo"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img
                            src={require("img/flicker/1.png")}
                            alt="flicker photo"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img
                            src={require("img/flicker/1.png")}
                            alt="flicker photo"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img
                            src={require("img/flicker/1.png")}
                            alt="flicker photo"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img
                            src={require("img/flicker/1.png")}
                            alt="flicker photo"
                          />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img
                            src={require("img/flicker/1.png")}
                            alt="flicker photo"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                  <div className="single-footer footer-four">
                    <h3>Corporate Office</h3>
                    <nav>
                      <ul>
                        <li>
                          <i
                            className="fa fa-paper-plane-o"
                            aria-hidden="true"
                          ></i>{" "}
                          44 New Design Street, rne 005
                        </li>
                        <li>
                          <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                          (0041) 111-252-3333
                        </li>
                        <li>
                          <i
                            className="fa fa-envelope-o"
                            aria-hidden="true"
                          ></i>{" "}
                          info@chili.com
                        </li>
                        <li>
                          <i className="fa fa-fax" aria-hidden="true"></i> Fax:
                          (123) 118 9999
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="footer-bottom">
                    <p>
                      {" "}
                      &copy; Copyrights Chili. 2016. All rights reserved.
                      Designed by digitalcenturysf
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <div className="modal micromodal-slide" id="modal-1" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              style={{ width: "100%" }}
              className="modal__container"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-1-title"
            >
              <header className="modal__header">
                <h4 v="modal__title" id="modal-1-title">
                  Registration Details
                </h4>
                <button
                  className="modal__close"
                  aria-label="Close modal"
                  data-micromodal-close
                ></button>
              </header>
              <main className="modal__content" id="modal-1-content">
                <form id="login_form" method="post">
                  First Name:
                  <br />
                  <input type="text" id="fname" className="form-control" />
                  <br />
                  Last Name:
                  <br />
                  <input type="text" id="lname" className="form-control" />
                  <br />
                  Mobile(User Id):
                  <br />
                  <input type="text" id="msisdn" className="form-control" />
                  <br />
                  Password:
                  <br />
                  <input type="password" id="pwd1" className="form-control" />
                  <br />
                  Role:
                  <br />
                  <select id="role" className="form-control">
                    <option value="">--select---</option>
                    <option value="hotel_owner"> Hotel Owner</option>
                    <option value="employee"> Employee</option>
                    <option value="vendor">Vendor</option>
                    <option value="service_provider">Service Provider</option>
                  </select>
                  <br />
                  <button class="btn btn-primary" id="register_div">
                    Register
                  </button>
                </form>
              </main>
            </div>
          </div>
        </div>

        <div
          className="modal micromodal-slide"
          id="modal-12"
          aria-hidden="true"
        >
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              style={{ width: "100%" }}
              className="modal__container"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-12-title"
            >
              <header className="modal__header">
                <h4 v="modal__title" id="modal-12-title">
                  Login
                </h4>
                <button
                  className="modal__close"
                  aria-label="Close modal"
                  data-micromodal-close
                ></button>
              </header>
              <main className="modal__content" id="modal-12-content">
                Login Id:
                <br />
                <input
                  type="text"
                  name="uid"
                  id="uid"
                  className="form-control"
                />
                <br />
                Password:
                <br />
                <input
                  type="password"
                  name="pwd"
                  id="pwd"
                  className="form-control"
                />
                <br />
                <button class="btn btn-primary" id="login_div">
                  Login
                </button>
              </main>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
