import React, { useState, useCallback, useEffect, useRef } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import "bootstrap/dist/css/bootstrap.min.css";
import url from "assets/js/config.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import BugReport from "@material-ui/icons/BugReport";
import Select from "react-select";
import $ from "jquery";
import MicroModal from "micromodal";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

var ht;
var is_catering;
var catering_type;
var capacity;
var that;
var business_description;
var establishment_type;
var specialization_area;
var alt_msisdn;
var email_id;
var website;
var no_of_employees;
var year_of_establishment;
var business_name;
var state;
var address;
var address1;
var name;
var images1;
var msisdn;
var experience_area;
var city;
var locality;
var pin;
var is_catering = "no";
var select_options;
var grand_data;

const mystyle = {
  width: "250px",
  fontSize: "14px",
};
const mystyle1 = {
  fontSize: "14px",
};
const FileInput = (props) => (
  <input type="file" name="file" multiple value={props.value} />
);

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

var form_value = 0;

var that;
export default class Welcome extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <CustomTabs
          title=""
          headerColor="primary"
          tabs={[
            {
              tabName: "View Joblist",
              tabIcon: BugReport,
              tabContent: (
                <div>
                  <Table />
                </div>
              ),
            },
          ]}
        />
      </div>
    );
  }
}

var d;

class add extends React.Component {
  constructor(props) {
    super(props);
  }
  get_data(cb) {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var msisdn = localStorage.getItem("ootel_msisdn");
    var postData = {
      job_id: this.job_id,
      auth_token: ootel_auth_token,
      msisdn: msisdn,
    };
    // alert(postData);
    const rawResponse = fetch(url.server_url + "/add_abroadjob_apply_data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((data) => {
        try {
          if (data.error.length != 0) {
            alert(data.error);
            return;
          }
        } catch (e) {}
        alert(data.data);
        document.location.reload();
      })
      .catch((err) => {
        console.error("Error: ", err);
      });
  }
}

class get_search_data extends React.Component {
  constructor(page, per_page, column, direction, search) {
    super(page, per_page, column, direction, search);

    this.search = search;
    this.column = column;
    this.direction = direction;
    this.per_page = per_page;
    this.page = page;

    that = this;
  }

  get_data() {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var msisdn = localStorage.getItem("ootel_msisdn");
    var role = localStorage.getItem("ootel_role");

    var search_value;

    var postData = {
      auth_token: ootel_auth_token,
      search: this.search,
      column: this.column,
      direction: this.direction,
      per_page: this.per_page,
      role: "employee",
      msisdn: msisdn,
      page: this.page,
    };
    var tab = $("#table").DataTable();
    try {
      tab.destroy();
    } catch (e) {}

    tab = $("#table").DataTable({
      processing: true,
      serverSide: true,
      bPaginate: true, // Pagination True
      sPaginationType: "full_numbers", // And its type.
      iDisplayLength: 10,
      //Initial no order.
      search: {
        search: "",
      },
      ajax: {
        //  "url": "get_completed_tests_for_print_backup.php",
        url: url.server_url + "/get_post_abroadjob_employee",

        type: "POST",
        dataType: "JSON",
        data: {
          auth_token: ootel_auth_token,
          column: this.column,
          direction: this.direction,
          per_page: this.per_page,
          role: "employee",
          msisdn: msisdn,
          page: this.page,
        },

        dataSrc: function(json1) {
          var return_data = new Array();
          var data = json1.data;
          var data1 = json1.data1;
          var k = 1;
          for (var i = 0; i < data.length; i++) {
            return_data.push({
              sino: i + 1,
              //   company: data1[i][0].business_name,
              date: data[i].post_date,
              job_title:
                '<a href="#" style="color: blue;font-size: 14px;padding: 8px;" jobid="' +
                data[i].id +
                '" jobtitle="' +
                data[i].job_title +
                '" skillset="' +
                data[i].skill_set +
                '" experience="' +
                data[i].experience +
                '" city="' +
                data[i].city +
                '" country="' +
                data[i].country +
                '" salary="' +
                data[i].salary +
                '" description="' +
                data[i].description +
                '" id="btnViewJob" action="view">' +
                data[i].job_title +
                "</a>",
              city: data[i].city,
              country: data[i].country,
              action:
                data1[i][0].applicant_date == null
                  ? '<a href="#" style="color: blue;font-size: 14px;padding: 8px;" jobid="' +
                    data[i].id +
                    '" id="btnApply">Apply</a>'
                  : "Applied on " + data1[i][0].applicant_date,
              recordsTotal: 11,

              recordsFiltered: 11,
            });
          }
          $("#table11_filter")
            .find("input")
            .css({ width: "700px", "margin-left": "-80%" });
          $("#table11_filter")
            .find("input")
            .attr("placeholder", "Search  Item");

          return return_data;
        },
      },
      createdRow: function(row, data, index) {},
      columnDefs: [
        {
          targets: [0, 1], //first column / numbering column
          orderable: false, //set not orderable
        },
      ],
      columns: [
        { data: "sino" },
        // { data: "company" },
        { data: "date" },
        { data: "job_title" },
        { data: "city" },
        { data: "country" },
        { data: "action" },
      ],
    });
  }
}

class Table extends React.Component {
  componentDidMount() {
    var c = new get_search_data("1", "10", "id", "desc");
    c.get_data();

    MicroModal.init({
      onShow: (modal) => console.info("${modal.id} is shown"),
      onClose: (modal) => console.info("${modal.id} is hidden"),
      openTrigger: "data-custom-open", // [3]
      closeTrigger: "data-custom-close", // [4]
      disableScroll: true, // [5]
      disableFocus: false, // [6]
      awaitOpenAnimation: false, // [7]
      awaitCloseAnimation: false, // [8]
      debugMode: true, // [9]
    });
    document.querySelector("#table").addEventListener("click", function(e) {
      var jobid = e.target.getAttribute("jobid");
      var action = e.target.getAttribute("action");
      if (jobid != null && action == "view") {
        document
          .querySelector("#modal1Fields")
          .setAttribute("disabled", "disabled");
        document.querySelector("#job_title1").value = e.target.getAttribute(
          "jobtitle"
        );
        document.querySelector("#skill_set1").value = e.target.getAttribute(
          "skillset"
        );
        document.querySelector("#experience1").value = e.target.getAttribute(
          "experience"
        );
        document.querySelector("#city1").value = e.target.getAttribute("city");
        document.querySelector("#country1").value = e.target.getAttribute(
          "country"
        );
        document.querySelector("#salary1").value = e.target.getAttribute(
          "salary"
        );
        document.querySelector("#description1").value = e.target.getAttribute(
          "description"
        );
        MicroModal.show("modal-1");
      } else if (jobid != null) {
        var ootel_auth_token = localStorage.getItem("ootel_auth_token");
        var msisdn = localStorage.getItem("ootel_msisdn");

        var postData = {
          auth_token: ootel_auth_token,
          job_id: jobid,
          msisdn: msisdn,
        };
        alert("Applied Successfull");
        const rawResponse = fetch(url.server_url + "/add_abroadjob_apply_data", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        })
          .then((res) => res.json())
          .then((data) => {
            try {
              if (data.error.length != 0) {
                alert(data.error);
                return;
              }
            } catch (e) {}

            var c = new get_search_data("1", "10", "id", "desc");
            c.get_data();
          })
          .catch((err) => {
            console.error("Error: ", err);
          });
      }
    });
    document.querySelector("#btnclose").addEventListener("click", function(e) {
      e.preventDefault();
      MicroModal.close("modal-1");
    });

    document
      .querySelector("#export_report")
      .addEventListener("click", function (e) {
        e.preventDefault();

        
          var ootel_auth_token = localStorage.getItem("ootel_auth_token");
          var msisdn = localStorage.getItem("ootel_msisdn");

          var csvData = [];
          var fileName = "Abroad Employeejobs"

          var postData = {
              auth_token: ootel_auth_token,
              column: "id",
              search: "",
              direction: "desc",
              length: 1,
              role: "employee",
              msisdn: msisdn,
              start: 0,
              page: 1,  
              per_page: 1,
              isExport: "Y",
          };
       
          const rawResponse1 = fetch(url.server_url + "/get_post_abroadjob_employee", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }).then((res) => res.json())
            .then((result) => {
          
              var data = result.data;
              var data1 = result.data1;
              // alert(data.length);
              // alert(data1.length);
                for (var i = 0; i < data.length; i++) {
                  csvData.push({
                        SiNo: i + 1,
                        Date: data[i].post_date,
                        JobTitle: data[i].job_title,
                        City: data[i].city,
                        Country: data[i].country,
                        Action: data1[i][0].applicant_date,
                  });
                          
                };
         
              console.log(csvData);
                const fileType =
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const fileExtension = ".xlsx";

                const ws = XLSX.utils.json_to_sheet(csvData);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, {
                  bookType: "xlsx",
                  type: "array",
                });
                const exceldata = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(exceldata, fileName + fileExtension);
              
            }).catch((err) => {
              console.error("Error: ", err);
            });
        
      });

  }

  render() {
    return (
      <div className="cl">
        <table className="table table-striped table-bordered" id="table">
          <thead>
            <tr style={{ backgroundColor: "#8fcbcd" }}>
              <th>Si No</th>
              {/* <th>Company</th> */}
              <th>Date</th>
              <th>Job Title</th>
              <th>City</th>
              <th>country</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>

        <br />
        <br />
        <button
          id="export_report"
          className="btn btn-primary"
          style={{ width: "110px", fontSize: "15px", float: "right" }}
        >
          Export Excel
        </button>
        <br />
        <br />

        <div className="modal micromodal-slide" id="modal-1" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              style={{ width: "100%" }}
              className="modal__container"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-1-title"
            >
              <header className="modal__header">
                <h4 v="modal__title" id="modal-1-title">
                  Job Details
                </h4>
                <button
                  className="modal__close"
                  aria-label="Close modal"
                  data-micromodal-close
                ></button>
              </header>
              <main className="modal__content" id="modal-1-content">
                <form id="ViewEdit_form" method="post">
                  <fieldset id="modal1Fields">
                    Job Title:
                    <br />
                    <input
                      type="text"
                      id="job_title1"
                      className="form-control"
                    />
                    <br />
                    Skill Set:
                    <br />
                    <input
                      type="text"
                      id="skill_set1"
                      className="form-control"
                    />
                    <br />
                    Experience:
                    <br />
                    <input
                      type="text"
                      id="experience1"
                      className="form-control"
                    />
                    <br />
                    City:
                    <br />
                    <input type="text" id="city1" className="form-control" />
                    <br />
                    Country:
                    <br />
                    <input
                      type="text"
                      id="country1"
                      className="form-control"
                    />
                    <br />
                    Salary:
                    <br />
                    <input
                      type="text"
                      id="salary1"
                      className="form-control"
                    />
                    <br />
                    Description:
                    <br />
                    <textarea
                      id="description1"
                      className="form-control"
                    />
                    <br />
                  </fieldset>
                  <button class="btn btn-primary" id="btnclose">
                    Close
                  </button>
                </form>
              </main>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
class clk {
  hasClass(elem, className) {
    try {
      return elem.className.split(" ").indexOf(className) > -1;
    } catch (e) {
      return -1;
    }
  }
}
