import React, { useState, useCallback } from "react";
import ReactDOM from "react-dom";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import url from "assets/js/config.js";
import  {Photo_details} from "views/hotel_owners/UserProfile/photo_gallery.js";

var is_catering;
var catering_type;
var capacity;
var that;
var business_description;
var establishment_type;
var specialization_area;
var file1;
var file2;
var file3;
var file4;
var file5;
var alt_msisdn;
var email_id;
var website;
var no_of_employees;
var year_of_establishment;
var business_name;
var state;
var address;
var address1;
var name;
	var images1;
	var msisdn;
	
	var city;
	var locality;
	var pin;
	
console.log("jag");
var photos = [];
var files = [];
export const Basic_details =class Basic_details extends React.Component {
	constructor(props){
		super(props);
		this.state={
      photos: "",
      files: ""
		}
		
		that=this;
		
	}
	componentDidMount(){
		
		var ootel_auth_token=localStorage.getItem('ootel_auth_token');  
         var postData={"auth_token":ootel_auth_token};
        const  rawResponse =  fetch(url.server_url+"/get_hotel_owners", {
    method: 'POST',
    headers: {
      
      'Content-Type': 'application/json' 
    },
    body: JSON.stringify(postData)
  }).then(res => res.json())
    .then(data => {
   try{     
if(data.error.length!=0){
    alert(data.error);return;
}
   }catch(e){
       
   }
   
   
   try{
 var data1=data.data;
    	var data2=data.data1;
    	business_name=(data1[0].business_name);
		document.getElementById("business_name").innerHTML=business_name;
		
		
 try{
       state=(data1[0].state);
	   }catch(e){
			console.log(e.message);
		}
       try{
			 document.getElementById("locality").innerHTML=(data1[0].locality);
		}catch(e){
			console.log(e.message);
		}
		try{
			address=(data1[0].address);
		}catch(e){
			console.log(e.message);
		}
		
		try{
			address1=(data1[0].address1);
		}catch(e){
			console.log(e.message);
		}
		try{
			 city=(data1[0].city);
		}catch(e){
			console.log(e.message);
		}
		try{
			pin=(data1[0].pin);
		}catch(e){
			console.log(e.message);
		}
		
    	
  
   
 capacity= data1[0].capacity;
    
  business_description= data1[0].business_description;
   establishment_type= data1[0].establishment_type;
 specialization_area= data1[0].specialization_area;

  // bbmp_license= data1[0].bbmp_license;
  // fssai_license= data1[0].fssai_license;
  // gst_certificate= data1[0].gst_certificate;
  // msme_certificate = data1[0].msme_certificate;
  // rental_agreement = data1[0].rental_agreement;   
     
  alt_msisdn= data1[0].alt_msisdn;
   email_id= data1[0].email_id;
 website= data1[0].website; 
    	
   no_of_employees= data1[0].no_of_employees;
    name= data1[0].name;   
     msisdn= data1[0].msisdn; 
	 state= data1[0].state; 
 year_of_establishment= data1[0].year_of_establishment;   	
		is_catering=data1[0].is_catering; 
		catering_type=data1[0].catering_type;
		document.querySelector("#business_name").innerHTML=business_name;
		document.querySelector("#business_description").innerHTML=business_description;
		document.querySelector("#establishment_type").innerHTML=establishment_type;
    document.querySelector("#specialization_area").innerHTML = specialization_area;
    // document.querySelector("#bbmp_license").innerHTML = bbmp_license;
    // document.querySelector("#fssai_license").innerHTML = fssai_license;
    // document.querySelector("#gst_certificate").innerHTML = gst_certificate;
    // document.querySelector("#msme_certificate").innerHTML = msme_certificate;
    // document.querySelector("#rental_agreement").innerHTML=rental_agreement;
		document.querySelector("#alt_msisdn").innerHTML=alt_msisdn;
		document.querySelector("#email_id").innerHTML=email_id;
		document.querySelector("#website").innerHTML=website;
		document.querySelector("#msisdn").innerHTML=msisdn;
		document.querySelector("#year_of_establishment").innerHTML=year_of_establishment;
		document.querySelector("#catering_type").innerHTML=catering_type;
		document.querySelector("#is_catering").innerHTML=is_catering;
		document.querySelector("#no_of_employees").innerHTML=no_of_employees;
		document.querySelector("#capacity").innerHTML=capacity;
		document.querySelector("#state").innerHTML=state;
		document.querySelector("#address").innerHTML=address;
		document.querySelector("#address1").innerHTML=address1;
		document.querySelector("#pin").innerHTML=pin;
		document.querySelector("#city").innerHTML=city;
		document.querySelector("#name").innerHTML=name;
		
		
		
		try{
		 	images1= data1[0].photo.split("<>");
			photos.length=0;
	for(var i=0;i<images1.length;i++){
				if(images1[i]!=""){
					photos.push(
				{
					"src":url.image_url+"/"+images1[i],
					"width":1,
					height:1
				
				});
				}
				
			}
			
			ReactDOM.render(
  <Photo_details data={photos}/>,
  document.getElementById("photo_details")
);
			
			
			
		}catch(e){
			
    }
     
// *************** File Attachment ******************//
     
 // *************** bbmp license ******************//   	 
   	try{
		 	file1= data1[0].bbmp_license.split("<>");
			files.length=0;
	for(var i=0;i<file1.length;i++){
				if(file1[i]!=""){
					files.push(
				{
					"src":url.image_url+"/"+file1[i],
					"width":1,
					height:1
				
				});
				}
				
			}
			
			ReactDOM.render(
  <bbmp_details data={files}/>,
  document.getElementById("bbmp_details")
);
			
		}catch(e){
			
    }   
 
// *************** fssai license ******************//
      	try{
		 	file2= data1[0].fssai_license.split("<>");
			files.length=0;
	for(var i=0;i<file2.length;i++){
				if(file2[i]!=""){
					files.push(
				{
					"src":url.image_url+"/"+file2[i],
					"width":1,
					height:1
				
				});
				}
				
			}
			
			ReactDOM.render(
  <fssai_details data={files}/>,
  document.getElementById("fssai_details")
);
			
		}catch(e){
			
        }
     
 // *************** gst certificate ******************//
      	try{
		 	file3= data1[0].gst_certificate.split("<>");
			files.length=0;
	for(var i=0;i<file3.length;i++){
				if(file3[i]!=""){
					files.push(
				{
					"src":url.image_url+"/"+file3[i],
					"width":1,
					height:1
				
				});
				}
				
			}
			
			ReactDOM.render(
  <gst_details data={files}/>,
  document.getElementById("gst_details")
);
			
		}catch(e){
			
        }    
    
 // *************** msme certificate ******************//
      	try{
		 	file4= data1[0].msme_certificate.split("<>");
			files.length=0;
	for(var i=0;i<file4.length;i++){
				if(file4[i]!=""){
					files.push(
				{
					"src":url.image_url+"/"+file4[i],
					"width":1,
					height:1
				
				});
				}
				
			}
			
			ReactDOM.render(
  <msme_details data={files}/>,
  document.getElementById("msme_details")
);
			
		}catch(e){
			
        } 
        
     
 // *************** rental agreement ******************//
      	try{
		 	file5= data1[0].rental_agreement.split("<>");
			files.length=0;
	for(var i=0;i<file5.length;i++){
				if(file5[i]!=""){
					files.push(
				{
					"src":url.image_url+"/"+file5[i],
					"width":1,
					height:1
				
				});
				}
				
			}
			
			ReactDOM.render(
  <rental_details data={files}/>,
  document.getElementById("rental_details")
);
			
		}catch(e){
			
        } 
     
     
    }catch(e){
        console.log(e.message);
    }
    }).catch(err => {
        console.error('Error: ', err);
    });
       
	}
	
	
render() {
      const mystyle = {
      width: "250px",
      fontSize:"14px",
      
    };
    const mystyle1 = {
      
      fontSize:"14px",
      
    }; 
      
      
      
    return <div>
	<h6>Basic Details</h6>
	
            <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <b> Business Name:</b> <span id="business_name"></span>
                
                
                 
                 
                 
             <br/>     
                 
                 
                 
                 <b> State:</b> <span id="state"></span>
                   <br/>     
               
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
          <b> Address Line 1:</b><span id="address"></span>
                
                  <br/>     
                 
                  <b> Address Line 2:</b> <span id="address1"></span>
                    <br/>     
                  
                  <b>City:</b>  <span id="city"></span>
                  
                   <br/>     
                 <b> Locality:</b>  <span id="locality"></span>
                   <br/>     
                <b>   PIN:</b>  <span id="pin"></span>
                 
				  
                 <br/>
        </GridItem>
      </GridContainer>
	  	<hr/>
            <h6>Business Details</h6>

            <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <b> Year of Establishment:</b> <span id="year_of_establishment"></span>
                 <br/>
                 
                  <b>No Of Employees:</b> <span id="no_of_employees"></span>
                  <div id='no_of_employee'  ></div>
                  <b>   Business Description:</b><span id="business_description"></span>
                  <div id='business_description'  >  </div>   <br/>
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
         
                <b>   Catering Capacity:</b>  <span id="is_catering"></span>
				
                <br/>
                  <b>   Catering Type:</b>    <span id="catering_type"></span>
        <br/>
	   <b>   Capacity:</b>   <span id="capacity"></span>
        
                
        <br/>
        
        
        <b>Specialization Area:</b>  <span id="specialization_area"></span>
             <br/>
        
        <b> Establishment Type: </b><span id="establishment_type"></span>
             <br/>
        
        </GridItem>
        
      </GridContainer>


        	<hr/>
            <h6>Certificate And License</h6>

            <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
        
                 <br />
        </GridItem>
        <GridItem xs={6} sm={6} md={6}>
         
                <br />
        
        </GridItem>
        
      </GridContainer>


            <hr/>
            <h6>Conatct Details</h6>
            <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <b> Contact Person:</b> {name}<span id="name"></span>
                  <br/> 
                 
                  <b>Mobile No:</b> {msisdn}<span id="msisdn"></span>
                  
                    <br/> 
                    <b>Alternative Mobile No:</b>  <span id="alt_msisdn"></span>
                  <br/> 
                 <b> Email Id:</b>  <span id="email_id"></span>
                    <br/> 
				   <b>   Website:</b> <span id="website"></span>
                   <br/> 
        </GridItem>
        
      </GridContainer>
	  
	  <hr/>
            <h6>Photo Details</h6>
            <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div id="photo_details"></div>
        </GridItem>
        
      </GridContainer>
	  
      <hr/>
            <h6>BBMP License Details</h6>
            <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div id="bbmp_details"></div>
        </GridItem>
        
      </GridContainer>      
            
       <hr/>
            <h6>FSSAI License Details</h6>
            <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div id="fssai_details"></div>
        </GridItem>
        
      </GridContainer> 
      
       <hr/>
            <h6>GST Certificate Details</h6>
            <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div id="gst_details"></div>
        </GridItem>
        
      </GridContainer> 

      <hr/>
            <h6>MSME Certificate Details</h6>
            <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div id="msme_details"></div>
        </GridItem>
        
      </GridContainer> 

      <hr/>
            <h6>Rental Agreement Details</h6>
            <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div id="rental_details"></div>
        </GridItem>
        
      </GridContainer> 
                    
                    </div>;
  }
 

}


 


