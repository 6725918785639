import React from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
// @material-ui/core components

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import avatar from "assets/img/faces/marc.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import url from "assets/js/config.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import BugReport from "@material-ui/icons/BugReport";

import ReactTable from "react-table-6";

import "react-table-6/react-table.css";

import "assets/css/styles.css";
import { Photo_details } from "views/hotel_owners/UserProfile/photo_gallery.js";
import MicroModal from "micromodal";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";
import DataTable from "datatables.net";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

var that;
var ht;
var is_catering;
var catering_type;
var capacity;
var that;
var business_description;
var establishment_type;
var specialization_area;
var alt_msisdn;
var email_id;
var website;
var no_of_employees;
var year_of_establishment;
var business_name;
var state;
var address;
var address1;
var name;
var images1;
var msisdn;
var experience_area = [];
var city;
var locality;
var pin;
var is_catering = "no";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

var that;
var columns;
export default class Welcome extends React.Component {
  constructor(props) {
    super(props);
    that = this;
    this.state = {
      data: props.value,
      loading: false,
      pages: 0,
      pagesize: 10,
      fetch_flag: 1,

      showModal_data: false,
    };
  }

  componentDidMount() {
    MicroModal.init({
      onShow: (modal) => console.info("${modal.id} is shown"),
      onClose: (modal) => console.info("${modal.id} is hidden"),
      openTrigger: "data-custom-open", // [3]
      closeTrigger: "data-custom-close", // [4]
      disableScroll: true, // [5]
      disableFocus: false, // [6]
      awaitOpenAnimation: false, // [7]
      awaitCloseAnimation: false, // [8]
      debugMode: true, // [9]
    });

    // var data=new get_search_data("","","","").get_data();
  }

  render() {
    const mystyle = {
      width: "300px",
      fontSize: "14px",
    };

    const { data } = this.state;

    const overlayClassName = this.state.showModal_data
      ? "modal fade show"
      : "modal fade";

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title=""
              headerColor="primary"
              tabs={[
                {
                  tabName: "Request History",
                  tabIcon: BugReport,
                  tabContent: (
                    <div>
                      <div id="table_data">
                        <Table />
                      </div>
                      <div id="otp_div"></div>
                    </div>
                  ),
                },
                {
                  tabName: "Export to Excel",
                  tabIcon: BugReport,
                  tabContent: (
                    <div id="export_div">
                      <Export />
                    </div>
                  ),
                },
              ]}
            />
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div
              style={{ width: "100%" }}
              className="modal micromodal-slide"
              id="modal-1"
              aria-hidden="true"
            >
              <div
                className="modal__overlay"
                tabIndex="-1"
                data-micromodal-close
              >
                <div
                  style={{ maxWidth: "1000px", width: "1000px" }}
                  className="modal__container"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-1-title"
                >
                  <main className="modal__content" id="modal-1-content">
                    {new Counter_edit().render(this.state.data)}
                  </main>
                  <footer className="modal__footer">
                    <button
                      className="modal__btn"
                      data-micromodal-close
                      aria-label="Close this dialog window"
                    >
                      Close
                    </button>
                  </footer>
                </div>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

var that1;
class Table extends React.Component {
  constructor(props, props1) {
    super(props, props1);
    this.data = props;
    this.count = props1;

    this.pages = Math.ceil(this.count / 10);

    this.mystyle1 = {
      fontSize: "14px",
    };
    that1 = this;
  }

  componentDidMount() {
    var data = new get_search_data("", "", "", "").get_data();

    document.querySelector("#table").addEventListener(
      "click",
      function(e) {
        if (new clk().hasClass(e.target, "request")) {
          var elem = e.target;
          var z = elem.className.split(" ");

          var type = e.target.getAttribute("type");

          var id = e.target.value;

          var ootel_auth_token = localStorage.getItem("ootel_auth_token");

          var postData = {
            type: type,
            auth_token: ootel_auth_token,
            id: id,
          };
          const rawResponse = fetch(
            url.server_url + "/update_request_history_vendor",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(postData),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              try {
                if (data.error.length != 0) {
                  alert(data.error);
                  return;
                }
              } catch (e) {}
              alert(data.data);

              var c = new get_search_data("1", "10", "id", "desc");
              c.get_data();
            })
            .catch((err) => {
              console.error("Error: ", err);
            });
        }
      },
      false
    );

     document
      .querySelector("#export_report")
      .addEventListener("click", function (e) {
        e.preventDefault();

        
          var ootel_auth_token = localStorage.getItem("ootel_auth_token");
          var msisdn = localStorage.getItem("ootel_msisdn");

          var csvData = [];
          var fileName = "Vendors Requesthistory"

          var postData = {
              auth_token: ootel_auth_token,
              column: "id",
              search: "",
              direction: "desc",
              role: "vendor",
              length: 1,
              msisdn: msisdn,
              start: 0,
              page: 1,  
              per_page: 1,
              isExport: "Y",
          };
       
          const rawResponse1 = fetch(url.server_url + "/get_request_history_for_vendor", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }).then((res) => res.json())
            .then((result) => {
          
              var data = result.data;
              var data1 = result.data1;
              // alert(data.length);
                for (var i = 0; i < data.length; i++) {
                  csvData.push({
                        SiNo: i + 1,
                        CreatedOn: data[i].created_on,
                        Item: data[i].items,
                        Description: data[i].description,

                        AdminRate: data[i].admin_rate,
                        Status: data[i].status,
                        // Action: act,
                        // assigned_to: adr,
                  });
                          
                };
         
              console.log(csvData);
                const fileType =
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const fileExtension = ".xlsx";

                const ws = XLSX.utils.json_to_sheet(csvData);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, {
                  bookType: "xlsx",
                  type: "array",
                });
                const exceldata = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(exceldata, fileName + fileExtension);
              
            }).catch((err) => {
              console.error("Error: ", err);
            });
        
    });

    document.querySelector("#table").addEventListener(
      "click",
      function(e) {
        if (new clk().hasClass(e.target, "make_delivered")) {
          var elem = e.target;
          var z = elem.className.split(" ");

          var msisdn = e.target.getAttribute("vendor_msisdn");

          var id = e.target.value;

          var otp = Math.floor(Math.random() * 90000) + 10000;
          var auth_token = localStorage.getItem("ootel_auth_token");
          var postData = {
            id: id,
            msisdn: msisdn,
            auth_token: auth_token,
            otp: otp,
          };

          const rawResponse1 = fetch(url.server_url + "/send_otp_for_vendor", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          })
            .then((res) => res.json())
            .then((data) => {
              if (typeof data.error != "undefined") {
                try {
                  alert(data.error);
                } catch (e) {
                  console.log(e.message);
                }
              } else {
                ReactDOM.render(
                  <Otp id={id} otp={otp} vendor_msisdn={msisdn} />,
                  document.querySelector("#otp_div")
                );
                MicroModal.show("modal-1");
              }
            })
            .catch((err) => {
              console.error("Error: ", err);
            });
        }
      },
      false
    );
  }

  render() {
    return (
      <div className="cl">
        <table className="table table-striped table-bordered" id="table">
          <thead>
            <tr style={{ backgroundColor: "#8fcbcd" }}>
              <th>Created On</th>
              <th>Item</th>
              <th>Description</th>

              <th>Max Rate</th>
              <th>Status</th>
              <th>Action</th>
              <th>Requested from</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>

            <br />
            <br />
            <button
              id="export_report"
              className="btn btn-primary"
              style={{ width: "110px", fontSize: "15px", float: "right" }}
            >
              Export Excel
            </button>
            <br />
            <br />
      </div>
    );
  }
}

class Counter_edit extends React.Component {
  render() {
    return (
      <div>
        <h6>Basic Details</h6>

        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <b> Name:</b>
            <span id="name"></span>
            <br />

            <b> Mobile:</b>
            <span id="msisdn"></span>
            <br />

            <b> Alternative Mobile:</b>
            <span id="alt_msisdn"></span>
            <br />

            <b> Land Line Number:</b>
            <span id="landline"></span>
            <br />

            <b> Business Name:</b>
            <span id="business_name"></span>
            <br />
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <b> Locality:</b>
            <span id="locality"></span>
            <br />
            <b> Landmark:</b>
            <span id="landmark"></span>
            <br />

            <b> State:</b>
            <span id="state"></span>

            <br />

            <b>City:</b>
            <span id="city"></span>
            <br />
            <b> PIN:</b>
            <span id="pin"></span>
            <br />
          </GridItem>
        </GridContainer>
        <hr />
        <h6>Business Details</h6>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <b> Year of Establishment:</b>
            <span id="year_of_establishment"></span>
            <br />

            <b> GST Number:</b>
            <span id="gst_no"></span>
            <br />
            <b> Major Supply Line:</b>
            <div id="area_of_experience"></div>
          </GridItem>
        </GridContainer>

        <hr />

        <h6>Photo Details</h6>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <b>Photos:</b>

            <div id="photo_details"></div>
            <br />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
class Search extends React.Component {
  componentDidMount() {
    new multi_select().get_multi();

    document
      .querySelector("#submit_request")
      .addEventListener("click", function() {
        var ootel_auth_token = localStorage.getItem("ootel_auth_token");
        var msisdn = localStorage.getItem("ootel_msisdn");
        var description = document.querySelector("#description").value;
        var postData = {
          auth_token: ootel_auth_token,
          description: description,
          items: experience_area,
          msisdn: msisdn,
        };

        const rawResponse1 = fetch(url.server_url + "/add_request_for_vendor", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        })
          .then((res) => res.json())
          .then((data) => {
            if (typeof data.error != "undefined") {
              try {
                alert(data.error);
              } catch (e) {
                console.log(e.message);
              }
            } else {
              alert(data.data);
              document.location.reload();
            }
          })
          .catch((err) => {
            console.error("Error: ", err);
          });
      });
  }
  render() {
    return (
      <div>
        Search:
        <br />
        <div
          id="search_data"
          style={{ width: "400px", display: "inline-block" }}
        ></div>
        <br />
        Max Rate:
        <br />
        <input type="text" id="max_rate" disabled />
        <br />
        Description:
        <br />
        <textarea id="description"></textarea>
        <br />
        <button id="submit_request"> Submit</button>
        <br />
      </div>
    );
  }
}

class multi_select extends React.Component {
  get_multi() {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var postData = { auth_token: ootel_auth_token };

    const rawResponse1 = fetch(url.server_url + "/get_vendor_experience_area", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (typeof data.error != "undefined") {
          try {
            alert(data.error);
          } catch (e) {
            console.log(e.message);
          }
        } else {
          ReactDOM.render(
            <Multiselect
              options={data.data} // Options to display in the dropdown
              //selectedValues={} // Preselected value to persist in dropdown
              onSelect={this.onSelect_area_of_experience} // Function will trigger on select event
              onRemove={this.onRemove_area_of_experience} // Function will trigger on remove event
              displayValue="name"
              selectionLimit="1"
              // Property name to display in the dropdown options
            />,
            document.getElementById("search_data")
          );

          document
            .querySelector("#multiselectContainerReact")
            .querySelector("div").style.border = "none";

          document
            .querySelector("#multiselectContainerReact")
            .querySelector("div")
            .querySelector("input").style.width = "100%";
        }
      })
      .catch((err) => {
        console.error("Error: ", err);
      });
  }

  onSelect_area_of_experience(selectedList, selectedItem) {
    document.querySelector("#max_rate").value = selectedList[0].admin_rate;
    experience_area = selectedList;
  }

  onRemove_area_of_experience(selectedList, removedItem) {
    document.querySelector("#max_rate").value = "";
    experience_area = selectedList;
  }
}

var that1;

class get_search_data extends React.Component {
  constructor(page, per_page, column, direction, search) {
    super(page, per_page, column, direction, search);

    this.search = search;
    this.column = column;
    this.direction = direction;
    this.per_page = per_page;
    this.page = page;

    that1 = this;
  }
  componentDidMount() {}

  get_data() {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var msisdn = localStorage.getItem("ootel_msisdn");
    var role = localStorage.getItem("ootel_role");

    var search_value;

    var postData = {
      auth_token: ootel_auth_token,
      search: this.search,
      column: this.column,
      direction: this.direction,
      per_page: this.per_page,
      role: "vendor",
      msisdn: msisdn,
      page: this.page,
    };
    var tab = $("#table").DataTable();
    try {
      tab.destroy();
    } catch (e) {}

    tab = $("#table").DataTable({
      processing: true,
      serverSide: true,
      bPaginate: true, // Pagination True
      sPaginationType: "full_numbers", // And its type.
      iDisplayLength: 10,
      //Initial no order.
      search: {
        search: "",
      },
      ajax: {
        //  "url": "get_completed_tests_for_print_backup.php",
        url: url.server_url + "/get_request_history_for_vendor",

        type: "POST",
        dataType: "JSON",
        data: {
          auth_token: ootel_auth_token,
          column: this.column,
          direction: this.direction,
          per_page: this.per_page,
          role: "vendor",
          msisdn: msisdn,
          page: this.page,
        },

        dataSrc: function(json1) {
          var return_data = new Array();
          var data = json1.data;
          var data1 = json1.data1;
          var k = 1;
          for (var i = 0; i < data.length; i++) {
            var act = "";
            var del = "";
            if (data[i].status == "assigned") {
              act = `<button   type='accepted' value="${data[i].id}" class='request'>Accept</button>&nbsp;<button class='request' value="${data[i].id}" type='rejected'>Reject</button>`;
            }
            if (data[i].status == "accepted") {
              act = `<button class='request' value="${data[i].id}" type='rejected'>Reject</button>`;
              del =
                "<button class='btn btn-primary make_delivered' vendor_msisdn='" +
                data[i].msisdn +
                "' value='" +
                data[i].id +
                "'>Make Delivered</button>";
            }
            if (data[i].status == "rejected") {
              act = ``;
            }

            if (data[i].status == "deleivered") {
              act = ``;
              del = "";
            }
            if (data[i].status == "missed") {
              act = ``;
            }
            var adr = "";
            if (data[i].status == "accepted" || data[i].status == "delivered") {
              adr =
                data[i].business_name +
                "<br/>" +
                data[i].requestor_name +
                "<br/>" +
                data[i].msisdn;
            }

            return_data.push({
              created_on: data[i].created_on,
              item: data[i].items,
              description: data[i].description,

              admin_rate: data[i].admin_rate,
              status: data[i].status + "<br/>" + del,
              action: act,
              assigned_to: adr,

              recordsTotal: 11,

              recordsFiltered: 11,
            });
          }
          $("#table11_filter")
            .find("input")
            .css({ width: "700px", "margin-left": "-80%" });
          $("#table11_filter")
            .find("input")
            .attr("placeholder", "Search  Item");

          return return_data;
        },
      },
      createdRow: function(row, data, index) {},
      columnDefs: [
        {
          targets: [0, 1], //first column / numbering column
          orderable: false, //set not orderable
        },
      ],
      columns: [
        { data: "created_on" },
        { data: "item" },
        { data: "description" },

        { data: "admin_rate" },
        { data: "status" },
        { data: "action" },
        { data: "assigned_to" },
      ],
    });
  }
}
class view_details extends React.Component {
  constructor(props) {
    super(props);
    this.id = props;
  }
  click_fun() {
    try {
      var classname = document.getElementsByClassName("view_details");

      var postData = {
        id: this.id,
        auth_token: localStorage.getItem("ootel_auth_token"),
        contact: "no",
      };

      const rawResponse = fetch(
        url.server_url + "/get_employee_specification",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          try {
            if (data.error.length != 0) {
              alert(data.error);
              return;
            }
          } catch (e) {}

          try {
            var data1 = data.data;

            name = data1[0].name;
            business_name = data1[0].business_name;
            capacity = data1[0].capacity;

            var pan_number = data1[0].pan_no;
            var adhaar_number = data1[0].aadhar_no;
            specialization_area = data1[0].specialization_area;

            var nominee = data1[0].nominee;
            email_id = data1[0].email_id;
            website = data1[0].website;

            var blood_group = data1[0].blood_group;

            msisdn = data1[0].msisdn;
            var gender = data1[0].gender;
            var nominee_relation = data1[0].nominee_relation;
            var emergency_msisdn = data1[0].emergency_msisdn;

            address = data1[0].address;
            state = data1[0].state;
            city = data1[0].city;

            pin = data1[0].pin;
            locality = data1[0].locality;

            var address_permanent = data1[0].address_permanent;
            var state_permanent = data1[0].state_permanent;
            var city_permanent = data1[0].city_permanent;

            var pin_permanent = data1[0].pin_permanent;
            var locality_permanent = data1[0].locality_permanent;
            var alt_msisdn = data1[0].alt_msisdn;
            var landline = data1[0].landline;
            var landmark = data1[0].landmark;
            var year_of_establishment = data1[0].year_of_establishment;
            var gst_no = data1[0].gst_no;
            var dob = data1[0].dob;
            document.querySelector("#name").value = name;
            document.querySelector("#msisdn").value = msisdn;
            document.querySelector("#alt_msisdn").value = alt_msisdn;

            document.querySelector("#landline").value = landline;

            document.querySelector("#business_name").value = business_name;

            document.querySelector("#landmark").value = landmark;

            document.querySelector("#state").value = state;

            document.querySelector("#locality").value = locality;
            document.querySelector("#pin").value = pin;
            document.querySelector("#city").value = city;

            document.querySelector(
              "#year_of_establishment"
            ).value = year_of_establishment;
            document.querySelector("#gst_no").value = gst_no;

            try {
              var images1 = data1[0].photo.split("<>");
              var photos = [];
              for (var i = 0; i < images1.length; i++) {
                if (images1[i] != "") {
                  photos.push({
                    src: url.image_url + "/" + images1[i],
                    width: 1,
                    height: 1,
                  });
                }
              }
              ReactDOM.render(
                <Photo_details data={photos} />,
                document.getElementById("photo_details")
              );
            } catch (e) {}

            //For view profile
            document.querySelector("#name").innerHTML = name;
            document.querySelector("#msisdn").innerHTML = msisdn;
            document.querySelector("#alt_msisdn").innerHTML = alt_msisdn;
            document.querySelector("#landline").innerHTML = landline;
            document.querySelector("#business_name").innerHTML = business_name;
            document.querySelector("#landmark").innerHTML = landmark;

            document.querySelector("#state").innerHTML = state;

            document.querySelector("#locality").innerHTML = locality;
            document.querySelector("#pin").innerHTML = pin;
            document.querySelector("#city").innerHTML = city;

            document.querySelector(
              "#year_of_establishment"
            ).innerHTML = year_of_establishment;
            document.querySelector("#gst_no").innerHTML = gst_no;

            var html = "";
            var x = data1[0].area_of_experience_profile;

            x = JSON.parse(x);
            for (var i = 0; i < x.length; i++) {
              html = html + x[i].name + "<br/>";
            }

            document.querySelector("#area_of_experience").innerHTML = html;
            MicroModal.show("modal-1");
          } catch (e) {
            console.log(e.message);
          }
        })
        .catch((err) => {
          console.error("Error: ", err);
        });
    } catch (e) {}
  }
}

class clk {
  hasClass(elem, className) {
    try {
      return elem.className.split(" ").indexOf(className) > -1;
    } catch (e) {
      return -1;
    }
  }
}
var elem = "";
class Export extends React.Component {
  componentDidMount() {
    try {
      $(function() {
        $('[type="date"]').prop("max", function() {
          return new Date().toJSON().split("T")[0];
        });
      });
      document.querySelector("#export_div").addEventListener(
        "click",
        function(e) {
          if (new clk().hasClass(e.target, "export")) {
            elem = e.target;
            var z = elem.className.split(" ");

            elem.setAttribute("disabled", true);

            var type = e.target.getAttribute("type");

            var id = e.target.value;

            var ootel_auth_token = localStorage.getItem("ootel_auth_token");
            var msisdn = localStorage.getItem("ootel_msisdn");
            var from_date = document.querySelector("#from_date").value;

            var to_date = document.querySelector("#to_date").value;
            var postData = {
              type: type,
              auth_token: ootel_auth_token,
              msisdn: msisdn,
              from_date: from_date,
              to_date: to_date,
            };
            const rawResponse = fetch(
              url.server_url + "/export_request_history",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(postData),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                try {
                  if (data.error.length != 0) {
                    elem.removeAttribute("disabled");
                    alert(data.error);
                    return;
                  }
                } catch (e) {}
                try {
                  document.querySelector("#export").click();
                  elem.removeAttribute("disabled");
                } catch (e) {}
              })
              .catch((err) => {
                console.error("Error: ", err);
              });
          }
        },
        false
      );
    } catch (e) {
      console.log(e.message);
    }
  }
  render() {
    return (
      <div>
        From:
        <input type="date" id="from_date" />
        To:
        <input type="date" id="to_date" />
        <button className="btn btn-primary export">Export</button>
        <a
          id="export"
          href={url.image_url + "/report.csv"}
          download="report.csv"
          style={{ display: "none" }}
        >
          Download
        </a>
      </div>
    );
  }
}

class Otp extends React.Component {
  constructor(props) {
    super(props);
    that = this;
    that.vendor_msisdn = props.vendor_msisdn;

    that.id = props.id;
  }

  componentDidMount() {
    document.querySelector("#submit_otp").addEventListener("click", function() {
      var otp = document.querySelector("#otp").value;
      var auth_token = localStorage.getItem("ootel_auth_token");
      var postData = { id: that.id, otp: otp, auth_token: auth_token };
      const rawResponse1 = fetch(url.server_url + "/update_request_otp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      })
        .then((res) => res.json())
        .then((data) => {
          if (typeof data.error != "undefined") {
            try {
              alert(data.error);
            } catch (e) {
              console.log(e.message);
            }
          } else {
            alert(data.data);
            document.location.reload();
          }
        })
        .catch((err) => {
          console.error("Error: ", err);
        });
    });
  }

  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div
              style={{ width: "100%" }}
              className="modal micromodal-slide"
              id="modal-1"
              aria-hidden="true"
            >
              <div
                className="modal__overlay"
                tabIndex="-1"
                data-micromodal-close
              >
                <div
                  className="modal__container"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-1-title"
                >
                  <main className="modal__content" id="modal-1-content">
                    OTP:
                    <br />
                    <input type="text" id="otp" />
                    <br />
                    <button className="btn btn-primary" id="submit_otp">
                      Submit
                    </button>
                  </main>
                  <footer className="modal__footer">
                    <button
                      className="modal__btn"
                      data-micromodal-close
                      aria-label="Close this dialog window"
                    >
                      Close
                    </button>
                  </footer>
                </div>
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
