import React from "react";
import url from "assets/js/config.js";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Container, Grid, TextField } from "@material-ui/core";

export default class Register extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const refferedby_msisdn = urlParams.get("sid");

    document.querySelector("#refferedby").value = refferedby_msisdn;

    document
      .querySelector("#register_div")
      .addEventListener("click", function(e) {
        e.preventDefault();
        var formData = new FormData();

        document.querySelector("#register_div").setAttribute("disabled", true);

        var fname = document.querySelector("#fname").value;
        var lname = document.querySelector("#lname").value;
        var msisdn = document.querySelector("#msisdn").value;
        var role = document.querySelector("#role").value;
        var pwd = document.querySelector("#pwd1").value;
        formData = {
          fname: fname,
          lname: lname,
          msisdn: msisdn,
          role: role,
          pwd: pwd,
          refferedby: refferedby_msisdn,
        };

        const rawResponse = fetch(url.server_url + "/register", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify(formData),
        })
          .then((res) => res.json())
          .then((data) => {
            try {
              if (data.error.length != 0) {
                alert(data.error);
                document
                  .querySelector("#register_div")
                  .removeAttribute("disabled");
                return;
              }
            } catch (e) {}
            alert(data.data);
            localStorage.removeItem("ootel_auth_token");
            window.location.href = url.portal_url+"/gohome";
          })
          .catch((err) => {
            console.error("Error: ", err);
          });
      });
  }
  render() {
    return (
      <div>
        <header>
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
            fixed="top"
          >
            <Navbar.Brand href="#home">
              <img
                src={require("img/logo3.png")}
                alt="image"
                style={{ width: "120px" }}
              />
            </Navbar.Brand>
          </Navbar>
            </header>
            <h5 style= {{ color: "black" }}>Employee Reference</h5>
            <Container style={{ border: "ridge", borderRadius: "8px", backgroundColor: "aliceblue", marginTop: "140px" }}>
                <h2 style= {{ color: "black", textAlign: "center" }}>Employee Reference</h2>
          <Grid container spacing={2} style={{ display: "inline" }}>
            <Grid item xs={12} md={4}>
              Reffered By :
              <input type="text" id="refferedby" className="form-control" />
            </Grid>
            <Grid item xs={12} md={4}>
              First Name :
              <input type="text" id="fname" className="form-control" />
            </Grid>
            <Grid item xs={12} md={4}>
              Last Name :
              <input type="text" id="lname" className="form-control" />
            </Grid>
            <Grid item xs={12} md={4}>
              Mobile(User Id) :
              <input type="text" id="msisdn" className="form-control" />
            </Grid>
            <Grid item xs={12} md={4}>
              Password :
              <input type="password" id="pwd1" className="form-control" />
            </Grid>
            <Grid item xs={12} md={4}>
              Role :
              <input type="text" id="role" className="form-control" value="employee" />
            </Grid>
            <Grid item xs={12} md={4}>
              <button class="btn btn-primary" id="register_div">
                Register
              </button>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
