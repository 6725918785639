import React, { useState, useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import ReactDOM from "react-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// icon for file attachment view
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ListItemIcon from "@material-ui/core/ListItemIcon";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import avatar from "assets/img/faces/marc.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import url from "assets/js/config.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import BugReport from "@material-ui/icons/BugReport";
import MultiStep from "react-multistep";
import { Basic_details } from "views/hotel_owners/UserProfile/profile_template.js";
import { blackColor } from "assets/jss/material-dashboard-react";
//import {Basic_details_edit,Business_details_edit,Certificate_license_edit,Contact_details_edit} from "views/UserProfile/profile_template_edit.js";

const onChange = (e) => {
  console.log(e.target.files);
};

var ht;
var is_catering;
var catering_type;
var capacity;
var that;
var business_description;
var establishment_type;
var specialization_area;
var alt_msisdn;
var email_id;
var website;
var no_of_employees;
var year_of_establishment;
var business_name;
var state;
var address;
var address1;
var name;
var images1;
var msisdn;

var city;
var locality;
var memberuniqid;
var pin;
var is_catering = "no";
const mystyle = {
  width: "250px",
  fontSize: "14px",
};
const mystyle1 = {
  fontSize: "14px",
};

const btnstyle = {
  fontSize: "14px",
  backgroundColor: "#5522C9",
  color: "white",
  padding: "10px",
  marginTop: "8px",
};

//   const viewfileattach = {

// 	  fontSize: "16px",
// 	  color: "black!important",

// };
const FileInput = (props) => (
  <input type="file" name="file" multiple value={props.value} />
);

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const title01 = {
  fontSize: "1.5rem",
  backgroundColor: "rgb(172 164 206)",
  height: "30px",
  paddingLeft: "10px",
  fontWeight: "600",
  color: "black",
};

var form_value = 0;
const ImageThumb = ({ image }) => {
  return <img src={URL.createObjectURL(image)} alt={image.name} />;
};

const Basic_details_edit = () => (
  <GridContainer>
    <GridItem xs={12} sm={12} md={6}>
      <b> Business Name:</b>
      <span className="required"></span>
      <input
        type="text"
        name="business_name"
        id="business_name"
        style={mystyle}
        className="form-control u-full-width"
        onChange={that.handleFirstNameChanged}
      />

      <div>
        {" "}
        <img src="" width="300" id="photo_details" />
      </div>

      <b>Select Multiple Photos:</b>
      <div id="no_of_files">
        {that.state.files_upload
          ? that.state.files_upload.length + " files Selected"
          : ""}
      </div>
      {/* <input type="file" id="file" name="file" onChange={ that.handle_file } multiple
				  /> */}

      <div id="upload-box">
        <input
          type="file"
          id="file"
          name="file"
          onChange={that.handle_file}
          multiple
        />
      </div>
      <div>
        <a
          href={that.state.urlFile}
          type="button"
          style={btnstyle}
          target="_new"
        >
          View Test <nbsp /> <FileCopyIcon />
        </a>
      </div>

      {/* <div> {<a href={window.location.href} style={{ color: "black", fontSize: "16px" }}>Last Attachment <FileCopyIcon style={viewfileattach} /></a>} </div> */}
      <br />

      <b> State:</b>
      <span className="required"></span>
      <input
        type="text"
        id="state"
        name="state"
        style={mystyle}
        className="form-control"
        onChange={that.handle_state}
      />
      <b> PIN:</b>
      <span className="required"></span>
      <input
        type="text"
        id="pin"
        name="pin"
        style={mystyle}
        className="form-control"
        onChange={that.handle_pin}
      />
    </GridItem>
    <GridItem xs={12} sm={12} md={6}>
      <b> Address Line 1:</b>
      <span className="required"></span>
      <input
        type="text"
        name="address"
        id="address"
        style={mystyle}
        className="form-control"
        onChange={that.handle_address}
      />

      <b> Address Line 2:</b>
      <span className="required"></span>

      <input
        type="text"
        id="address1"
        name="address1"
        className="form-control"
        style={mystyle}
        onChange={that.handle_address1}
      />

      <b>City:</b>
      <span className="required"></span>
      <input
        type="text"
        id="city"
        name="city"
        style={mystyle}
        className="form-control"
        onChange={that.handle_city}
      />

      <b> Locality:</b>
      <span className="required"></span>
      <input
        type="text"
        id="locality"
        name="locality"
        style={mystyle}
        className="form-control"
        onChange={that.handle_locality}
      />

<b> Member Customer Id: &nbsp;</b>
<label id="memberuniqid"></label>
      
      <br />
    </GridItem>
  </GridContainer>
);

const Business_details_edit = () => (
  <GridContainer>
    <GridItem xs={12} sm={12} md={6}>
      <b> Year of Establishment:</b>
      <span className="required"></span>
      <input
        type="date"
        name="year_of_establishment"
        id="year_of_establishment"
        style={mystyle}
        className="form-control"
        onChange={that.handle_year_of_establishment}
        value={that.state.yearofestablishment}
      />
      <b>No Of Employees:</b>
      <span className="required"></span>
      <input
        type="text"
        id="no_of_employees"
        name="no_of_employees"
        style={mystyle}
        className="form-control"
        onChange={that.handle_no_of_employees}
        value={that.state.noofemployees}
      />
      <b> Business Description:</b>
      <span className="required"></span>
      <textarea
        onChange={that.handle_business_description}
        value={that.state.businessdescription}
        id="business_description"
        name="business_description"
        style={mystyle}
        className="form-control"
      ></textarea>
      <br />
      <b> Establishment Type:</b>
      <span className="required"></span> <br />
      <textarea
        id="establishment_type"
        name="establishment_type"
        onChange={that.handle_establishment_type}
        value={that.state.establishmenttype}
        style={mystyle}
      ></textarea>
    </GridItem>
    <GridItem xs={12} sm={12} md={6}>
      <b> Catering Capacity:</b>
      <span className="required"></span>
      <br />
      <table>
        <tr>
          <td>NO:</td>

          <td>
            <input
              onClick={that.handle_catering_capacity}
              checked={that.state.cateringcapacity}
              style={{ width: "30px" }}
              value="no"
              type="radio"
              defaultChecked
              className="catering_capacity1"
              name="catering_capacity"
            />
          </td>

          <td>YES:</td>

          <td>
            <input
              onClick={that.handle_catering_capacity1}
              checked={that.state.cateringcapacity1}
              style={{ width: "30px" }}
              type="radio"
              value="yes"
              className="catering_capacity2"
              name="catering_capacity"
            />
          </td>
        </tr>
      </table>
      <div id="catering_data1">
        <table>
          <tbody>
            <tr>
              <td>VEG:</td>

              <td>
                <input
                  onClick={that.handle_catering_type}
                  checked={that.state.catering_veg}
                  style={{ width: "30px" }}
                  type="radio"
                  checked
                  className="catering_type"
                  value="veg"
                  name="catering_type"
                />
              </td>

              <td>NON VEG:</td>

              <td>
                <input
                  style={{ width: "30px" }}
                  type="radio"
                  className="catering_type"
                  value="non_veg"
                  name="catering_type"
                />
              </td>

              <td>BOTH:</td>

              <td>
                <input
                  style={{ width: "30px" }}
                  type="radio"
                  className="catering_type"
                  value="both"
                  name="catering_type"
                />
              </td>
            </tr>
            <tr>
              <td colspan="3">
                Capacity:<span className="required"></span>
                <br />
                <input type="text" name="capacity" id="capacity" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b> Specialization Area:</b>
      <span className="required"></span> <br />
      <textarea
        id="specialization_area"
        name="specialization_area"
        onChange={that.handle_specialization_area}
        value={that.state.specializationarea}
        style={{ width: "195px" }}
      ></textarea>
      <br />
    </GridItem>
  </GridContainer>
);

const Certificate_license_edit = () => (
  <GridContainer>
    <GridItem xs={12} sm={12} md={6}>
      <b> BBMP (Municipal Authority) License:</b>
      <span className="required"></span>
      <br />
      <div id="no_of_files06">
        {that.state.files_upload_bbmp_license
          ? that.state.files_upload_bbmp_license.length + " files Selected"
          : ""}
      </div>
      <input
        type="file"
        id="file6"
        name="file_bbmp_license"
        onChange={that.handle_file_bbmp_license}
        multiple
      />
      <div>
        <a
          href={that.state.urlFile6}
          type="button"
          style={btnstyle}
          target="_new"
        >
          View Test <nbsp /> <FileCopyIcon />
        </a>
      </div>
      <br />
      <b> FSSAI License:</b>
      <span className="required"></span> <br />
      <div id="no_of_files05">
        {that.state.files_upload_fssai_license
          ? that.state.files_upload_fssai_license.length + " files Selected"
          : ""}
      </div>
      <input
        type="file"
        id="file5"
        name="file_fssai_license"
        onChange={that.handle_file_fssai_license}
        multiple
      />
      <div>
        <a
          href={that.state.urlFile5}
          type="button"
          style={btnstyle}
          target="_new"
        >
          View Test <nbsp /> <FileCopyIcon />
        </a>
      </div>
      <br />
      <b> GST Certificate:</b>
      <span className="required"></span> <br />
      <div id="no_of_files04">
        {that.state.files_upload_gst_certificate
          ? that.state.files_upload_gst_certificate.length + " files Selected"
          : ""}
      </div>
      <input
        type="file"
        id="file4"
        name="file_gst_certificate"
        onChange={that.handle_file_gst_certificate}
        multiple
      />
      <div>
        <a
          href={that.state.urlFile4}
          type="button"
          style={btnstyle}
          target="_new"
        >
          View Test <nbsp /> <FileCopyIcon />
        </a>
      </div>
      <br />
    </GridItem>
    <GridItem xs={12} sm={12} md={6}>
      <b> MSME Certificate:</b>
      <br />
      <div id="no_of_files03">
        {that.state.files_upload_msme_certificate
          ? that.state.files_upload_msme_certificate.length + " files Selected"
          : ""}
      </div>
      <input
        type="file"
        id="file3"
        name="file_msme_certificate"
        onChange={that.handle_file_msme_certificate}
        multiple
      />
      <div>
        <a
          href={that.state.urlFile3}
          type="button"
          style={btnstyle}
          target="_new"
        >
          View Test <nbsp /> <FileCopyIcon />
        </a>
      </div>
      <br />
      <b> Rental Agreement:</b> <br />
      <div id="no_of_files02">
        {that.state.files_upload_rental_agreement
          ? that.state.files_upload_rental_agreement.length + " files Selected"
          : ""}
      </div>
      <input
        type="file"
        id="file2"
        name="file_rental_agreement"
        onChange={that.handle_file_rental_agreement}
        multiple
      />
      <div>
        <a
          href={that.state.urlFile2}
          type="button"
          style={btnstyle}
          target="_new"
        >
          View Test <nbsp /> <FileCopyIcon />
        </a>
      </div>
      <br />
      <b>Hotel Owner ID Proof:</b>
      <span className="required"></span> <br />
      <div id="no_of_files01">
        {that.state.files_upload_hotel_owner_idproof
          ? that.state.files_upload_hotel_owner_idproof.length +
            " files Selected"
          : ""}
      </div>
      <input
        type="file"
        id="file1"
        name="file_hotel_owner_idproof"
        onChange={that.handle_file_hotel_owner_idproof}
        multiple
      />
      <div>
        <a
          href={that.state.urlFile1}
          type="button"
          style={btnstyle}
          target="_new"
        >
          View Test <nbsp /> <FileCopyIcon />
        </a>
      </div>
      <br />
    </GridItem>
  </GridContainer>
);

const Contact_details_edit = () => (
  <GridContainer>
    <GridItem xs={12} sm={12} md={12}>
      <b> Contact Person:</b>
      <span className="required"></span>
      <input
        type="text"
        name="fname"
        id="fname"
        style={mystyle}
        className="form-control"
        onChange={that.handle_fname}
      />

      <b>Mobile No:</b>

      <input
        disabled
        type="text"
        id="msisdn"
        name="msisdn"
        className="form-control"
        style={mystyle}
        onChange={that.handle_msisdn}
        value={that.state.msisdn}
      />

      <b>Alternative Mobile No:</b>
      <input
        type="text"
        id="alt_msisdn"
        name="alt_msisdn"
        style={mystyle}
        className="form-control"
        onChange={that.handle_alt_msisdn}
        value={that.state.alt_msisdn}
      />

      <b> Email Id:</b>
      <input
        type="text"
        id="email_id"
        name="email_id"
        style={mystyle}
        className="form-control"
        onChange={that.handle_email_id}
        value={that.state.email_id}
      />
      <b> Website:</b>
      <input
        type="text"
        id="website"
        name="website"
        style={mystyle}
        className="form-control"
        onChange={that.handle_website}
        value={that.state.website}
      />
      <br />

      <br />
      <br />
      <br />
    </GridItem>
  </GridContainer>
);

var that;
export default class Welcome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.name,
      file2: "",
      urlFile: null,
      urlFile6: null,
      urlFile5: null,
      urlFile4: null,
      urlFile3: null,
      urlFile2: null,
      urlFile1: null,
      hoteltype: props.value,
      address: props.value,
      address1: props.value,
      city: props.value,
      state: props.value,

      pin: props.value,
      locality: props.value,
      yearofestablishment: props.value,
      noofemployees: props.value,
      businessdescription: props.value,
      specializationarea: props.value,
      cateringcapacity: props.value,
      cateringcapacity1: props.value,
      fname: props.value,
      email_id: props.value,
      msisdn: props.value,
      alt_msisdn: props.value,
      website: props.value,

      catering_data1: "none",
      capacity: props.value,
      catering_both: props.value,
      catering_veg: props.value,
      catering_non_veg: props.value,
      catering_type: props.value,
      files_upload: props.value,
      files_upload_bbmp_license: props.value,
      files_upload_fssai_license: props.value,
      files_upload_gst_certificate: props.value,
      files_upload_msme_certificate: props.value,
      files_upload_rental_agreement: props.value,
      files_upload_hotel_owner_idproof: props.value,
      steps: [],
    };

    this.handleFirstNameChanged = this.handleFirstNameChanged.bind(this);
    this.handle_hotel_type = this.handle_hotel_type.bind(this);
    this.handle_address = this.handle_address.bind(this);
    this.handle_address1 = this.handle_address1.bind(this);
    this.handle_city = this.handle_city.bind(this);
    this.handle_state = this.handle_state.bind(this);
    this.handle_pin = this.handle_pin.bind(this);
    this.handle_locality = this.handle_locality.bind(this);
    this.handle_year_of_establishment = this.handle_year_of_establishment.bind(
      this
    );
    this.handle_no_of_employees = this.handle_no_of_employees.bind(this);
    this.handle_business_description = this.handle_business_description.bind(
      this
    );
    this.handle_specialization_area = this.handle_specialization_area.bind(
      this
    );
    this.handle_catering_capacity = this.handle_catering_capacity.bind(this);
    this.handle_website = this.handle_website.bind(this);
    this.handle_email_id = this.handle_email_id.bind(this);
    this.handle_alt_msisdn = this.handle_alt_msisdn.bind(this);
    this.handle_msisdn = this.handle_msisdn.bind(this);
    this.handle_fname = this.handle_fname.bind(this);
    this.handle_capacity = this.handle_capacity.bind(this);
    this.handle_file = this.handle_file.bind(this);
    this.handle_file_hotel_owner_idproof = this.handle_file_hotel_owner_idproof.bind(
      this
    );
    this.handle_file_bbmp_license = this.handle_file_bbmp_license.bind(this);
    this.handle_file_fssai_license = this.handle_file_fssai_license.bind(this);
    this.handle_file_gst_certificate = this.handle_file_gst_certificate.bind(
      this
    );
    this.handle_file_msme_certificate = this.handle_file_msme_certificate.bind(
      this
    );
    this.handle_file_rental_agreement = this.handle_file_rental_agreement.bind(
      this
    );

    that = this;
  }

  handleFirstNameChanged(event) {
    //alert(event.target.value);
    this.setState({ name: event.target.value });
  }
  handle_hotel_type(event) {
    //alert(event.target.value);
    this.setState({ hoteltype: event.target.value });
  }

  handle_file(event) {
    console.log("---------------", event.target.files[0]);
    //document.querySelector("#myform").value=event.target.value;
    if (this.state.files_upload === undefined) {
      this.state.files_upload = "";
    }
    //alert(event.target.files);

    this.setState({
      files_upload: event.target.files,
      urlFile: URL.createObjectURL(event.target.files[0]),
    });
  }

  // **************** certificate & license *********************//

  handle_file_bbmp_license(event) {
    //document.querySelector("#myform").value=event.target.value;
    if (this.state.files_upload_bbmp_license === undefined) {
      this.state.files_upload_bbmp_license = "";
    }
    //alert(event.target.files);

    this.setState({
      files_upload_bbmp_license: event.target.files_bbmp_license,
      urlFile6: URL.createObjectURL(event.target.files[0]),
    });
  }

  handle_file_fssai_license(event) {
    //document.querySelector("#myform").value=event.target.value;
    if (this.state.files_upload_fssai_license === undefined) {
      this.state.files_upload_fssai_license = "";
    }
    //alert(event.target.files);

    this.setState({
      files_upload_fssai_license: event.target.files_fssai_license,
      urlFile5: URL.createObjectURL(event.target.files[0]),
    });
  }

  handle_file_gst_certificate(event) {
    //document.querySelector("#myform").value=event.target.value;
    if (this.state.files_upload_gst_certificate === undefined) {
      this.state.files_upload_gst_certificate = "";
    }
    //alert(event.target.files);

    this.setState({
      files_upload_gst_certificate: event.target.files_gst_certificate,
      urlFile4: URL.createObjectURL(event.target.files[0]),
    });
  }

  handle_file_msme_certificate(event) {
    //document.querySelector("#myform").value=event.target.value;
    if (this.state.files_upload_msme_certificate === undefined) {
      this.state.files_upload_msme_certificate = "";
    }
    //alert(event.target.files);

    this.setState({
      files_upload_msme_certificate: event.target.files_msme_certificate,
      urlFile3: URL.createObjectURL(event.target.files[0]),
    });
  }

  handle_file_rental_agreement(event) {
    //document.querySelector("#myform").value=event.target.value;
    if (this.state.files_upload_rental_agreement === undefined) {
      this.state.files_upload_rental_agreement = "";
    }
    //alert(event.target.files);

    this.setState({
      files_upload_rental_agreement: event.target.files_rental_agreement,
      urlFile2: URL.createObjectURL(event.target.files[0]),
    });
  }

  handle_file_hotel_owner_idproof(event) {
    //document.querySelector("#myform").value=event.target.value;
    if (this.state.files_upload_hotel_owner_idproof === undefined) {
      this.state.files_upload_hotel_owner_idproof = "";
    }
    //alert(event.target.files);

    this.setState({
      files_upload_hotel_owner_idproof: event.target.files_hotel_owner_idproof,
      urlFile1: URL.createObjectURL(event.target.files[0]),
    });
  }

  handle_address(event) {
    //alert(event.target.value);
    this.setState({ address: event.target.value });
  }
  handle_address1(event) {
    //alert(event.target.value);
    this.setState({ address1: event.target.value });
  }
  handle_city(event) {
    //alert(event.target.value);
    this.setState({ city: event.target.value });
  }
  handle_state(event) {
    //alert(event.target.value);
    this.setState({ state: event.target.value });
  }
  handle_pin(event) {
    //alert(event.target.value);
    this.setState({ pin: event.target.value });
  }

  handle_locality(event) {
    //alert(event.target.value);
    this.setState({ locality: event.target.value });
  }
  handle_year_of_establishment(event) {
    //alert(event.target.value);
    that.setState({ yearofestablishment: event.target.value });
  }
  handle_no_of_employees(event) {
    //alert(event.target.value);
    that.setState({ noofemployees: event.target.value });
  }
  handle_business_description(event) {
    //alert(event.target.value);
    that.setState({ businessdescription: event.target.value });
  }

  handle_specialization_area(event) {
    //alert(event.target.value);
    that.setState({ specializationarea: event.target.value });
  }
  handle_establishment_type(event) {
    //alert(event.target.value);
    that.setState({ establishmenttype: event.target.value });
  }

  handle_catering_capacity(event) {
    //that.setState({cateringcapacity1: false});
    delete that.state.cateringcapacity1;
    that.setState({ cateringcapacity: event.target.value });
    that.setState({ catering_data1: "none" });
    is_catering = event.target.value;

    document.querySelector("#catering_data1").style.display = "none";
  }
  handle_catering_capacity1(event) {
    delete that.state.cateringcapacity;
    // that.setState({cateringcapacity: false});
    is_catering = event.target.value;
    that.setState({ cateringcapacity1: event.target.value });
    that.setState({ catering_data1: "block" });
    document.querySelector("#catering_data1").style.display = "block";
  }
  handle_email_id(event) {
    that.setState({ email_id: event.target.value });
    //that.setState({cateringcapacity: "off"});
  }
  handle_website(event) {
    that.setState({ website: event.target.value });
    //that.setState({cateringcapacity: "off"});
  }
  handle_msisdn(event) {
    that.setState({ msisdn: event.target.value });
    //that.setState({cateringcapacity: "off"});
  }
  handle_alt_msisdn(event) {
    that.setState({ alt_msisdn: event.target.value });
    //that.setState({cateringcapacity: "off"});
  }

  handle_fname(event) {
    that.setState({ fname: event.target.value });
    //that.setState({cateringcapacity: "off"});
  }

  handle_capacity(event) {
    //alert(event.target.value);
    that.setState({ capacity: event.target.value });
  }
  handle_website(event) {
    //alert(event.target.value);
    that.setState({ website: event.target.value });
  }
  handle_catering_type(event) {
    var data = event.target.value;
    delete that.state.catering_non_veg;
    delete that.state.catering_veg;
    delete that.state.catering_both;
    if (data == "non_veg") {
      that.setState({ catering_non_veg: event.target.value });
      that.setState({ catering_type: event.target.value });
    }
    if (data == "veg") {
      that.setState({ catering_veg: event.target.value });
      that.setState({ catering_type: event.target.value });
    }
    if (data == "both") {
      that.setState({ catering_both: event.target.value });
      that.setState({ catering_type: event.target.value });
    }
  }

  componentDidMount() {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var postData = { auth_token: ootel_auth_token };
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var postData = { auth_token: ootel_auth_token };

    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var postData = { auth_token: ootel_auth_token };
    const rawResponse = fetch(url.server_url + "/get_hotel_owners", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((data) => {
        try {
          if (data.error.length != 0) {
            alert(data.error);
            return;
          }
        } catch (e) {}

        try {
          var data1 = data.data;

          name = data1[0].name;
          business_name = data1[0].business_name;
          capacity = data1[0].capacity;

          business_description = data1[0].business_description;
          establishment_type = data1[0].establishment_type;
          specialization_area = data1[0].specialization_area;

          alt_msisdn = data1[0].alt_msisdn;
          email_id = data1[0].email_id;
          website = data1[0].website;

          no_of_employees = data1[0].no_of_employees;
          name = data1[0].name;
          msisdn = data1[0].msisdn;
          state = data1[0].state;
          year_of_establishment = data1[0].year_of_establishment;
          is_catering = data1[0].is_catering;
          catering_type = data1[0].catering_type;
          address = data1[0].address;
          address1 = data1[0].address1;
          city = data1[0].city;

          pin = data1[0].pin;
          locality = data1[0].locality;
		  memberuniqid = data1[0].memberuniqid==""?"Contact Admin for Member Customer Id":data1[0].memberuniqid;
          document.querySelector("#business_name").value = business_name;
          document.querySelector(
            "#business_description"
          ).value = business_description;
          document.querySelector(
            "#establishment_type"
          ).value = establishment_type;
          document.querySelector(
            "#specialization_area"
          ).value = specialization_area;
          document.querySelector("#alt_msisdn").value = alt_msisdn;
          document.querySelector("#email_id").value = email_id;
          document.querySelector("#website").value = website;
          document.querySelector("#msisdn").value = msisdn;
          document.querySelector(
            "#year_of_establishment"
          ).value = year_of_establishment;
          document.querySelector("#locality").value = locality;
		  document.querySelector("#memberuniqid").innerHTML = memberuniqid;
          try {
            document.querySelector("#catering_type").value = catering_type;
          } catch (e) {}

          try {
            document.querySelector("#is_catering").value = is_catering;
          } catch (e) {}
          try {
            document.querySelector("#no_of_employees").value = no_of_employees;
          } catch (e) {}

          try {
            document.querySelector("#capacity").value = capacity;
          } catch (e) {}

          document.querySelector("#state").value = state;
          document.querySelector("#address").value = address;
          document.querySelector("#address1").value = address1;
          document.querySelector("#pin").value = pin;
          document.querySelector("#city").value = city;
          document.querySelector("#fname").value = name;

          if (is_catering == "yes") {
            document.querySelector("#catering_data1").style.display = "block";
            var c = document.querySelectorAll(".catering_capacity1");
            c[0].removeAttribute("checked");
            var c = document.querySelectorAll(".catering_capacity2");
            c[0].checked = true;
          } else {
            document.querySelector("#catering_data1").style.display = "none";
            var c = document.querySelectorAll(".catering_capacity2");
            c[0].removeAttribute("checked");
            var c = document.querySelectorAll(".catering_capacity1");
            c[0].checked = true;
          }
          var cat_type = document.querySelectorAll(".catering_type");

          for (var i = 0; i < cat_type.length; i++) {
            if (cat_type[i].value == catering_type) {
              cat_type[i].checked = true;
            }
          }
        } catch (e) {
          console.log(e.message);
        }
      })
      .catch((err) => {
        console.error("Error: ", err);
      });

    document.querySelector("#myform").addEventListener("submit", function(e) {
      e.preventDefault();
    });

    try {
      document.querySelector("#myform").addEventListener("submit", function(e) {
        e.preventDefault();

        const formData = new FormData(document.querySelector("#myform"));
        var ootel_auth_token = localStorage.getItem("ootel_auth_token");
        formData.append("auth_token", ootel_auth_token);
        const rawResponse = fetch(url.server_url + "/add_hotel_owners", {
          method: "POST",
          //    headers: {
          //      //"Content-Type": "multipart/form-data",
          //   'Content-Type': 'application/json' ,
          //      // 'Accept': 'application/json'
          //    // 'Access-Control-Allow-Origin':'*'
          //    },
          //
          body: formData,
        })
          .then((res) => res.json())
          .then((data) => {
            if (typeof data.error != "undefined") {
              try {
                alert(data.error);
              } catch (e) {
                console.log(e.message);
              }
            } else {
              alert(data.data);
              document.location.reload();
            }
          })
          .catch((err) => {
            console.error("Error: ", err);
          });
      });
    } catch (e) {}
    try {
      document.querySelector("#profile").addEventListener("click", function(e) {
        window.location.href = window.location.pathname + "?type=view";
      });
    } catch (e) {}

    try {
      document
        .querySelector("#edit_profile")
        .addEventListener("click", function(e) {
          window.location.href = window.location.pathname + "?type=update";
          document.querySelector("#title").innerHTML =
            "<h5>Update Your Profile</h5>";
        });
    } catch (e) {}
  }

  render() {
    var subrole = localStorage.getItem("subrole");
    if (subrole == "subuser") {
      return (<div style={{ fontSize: "14px", color: "red" }}>Not authorised!!
      <form id="myform"></form> 
      </div>);
    }
    else
    {
    
      
    const Profile = () => (
      <div>        
        <Basic_details />
        <hr />
      </div>
    );

    var url = new URL(window.location.href);
    var c = url.searchParams.get("type");
    var view;
    if (c == "view") {
      view = true;
    } else {
      view = false;
    }

    return (
      <div>
        {view ? (
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomTabs
                  title=""
                  headerColor="primary"
                  tabs={[
                    {
                      tabName: "View Profile",
                      tabIcon: BugReport,
                      tabContent: (
                        <div>
                          <h5 id="title">Your Profile</h5>
                          <form
                            id="myform"
                            name="myform"
                            style={mystyle1}
                            encType="multipart/form-data"
                          >
                            <div id="data">{<Profile />}</div>
                            <div style={{ float: "right" }}>
                              <div
                                style={{ display: "inline-block", width: "" }}
                              >
                                {" "}
                                &nbsp; &nbsp;
                              </div>
                              <button
                                id="edit_profile"
                                className="btn btn-primary"
                              >
                                Edit
                              </button>
                              <div
                                style={{ display: "inline-block", width: "" }}
                              >
                                {" "}
                                &nbsp; &nbsp;
                              </div>
                            </div>
                          </form>
                        </div>
                      ),
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        ) : (
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomTabs
                  title=""
                  headerColor="primary"
                  tabs={[
                    {
                      tabName: "Profile Update",
                      tabIcon: BugReport,
                      tabContent: (
                        <div>
                          {/* <h5 id='title'>Update Profile</h5> */}
                          <form
                            id="myform"
                            name="myform"
                            style={mystyle1}
                            encType="multipart/form-data"
                          >
                            <div id="data">
                              <h6 style={title01}>Basic Details</h6>
                              <Basic_details_edit />
                              <hr />
                              <h6 style={title01}> Business Details</h6>
                              <Business_details_edit />
                              <hr />
                              <h6 style={title01}>Certificates And Licenses</h6>
                              <Certificate_license_edit />
                              <hr />
                              <h6 style={title01}>Contact Details</h6>
                              <Contact_details_edit />
                              <hr />
                            </div>
                            <div style={{ float: "right" }}>
                              <button id="profile" className="btn btn-primary">
                                View Profile
                              </button>

                              <div
                                style={{ display: "inline-block", width: "" }}
                              >
                                {" "}
                                &nbsp; &nbsp;
                              </div>
                              <button
                                id="update"
                                type="submit"
                                value="0"
                                className="btn btn-primary"
                              >
                                Save All
                              </button>
                            </div>
                          </form>
                        </div>
                      ),
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        )}
      </div>
    );
      
    }
  }
}
