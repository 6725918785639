import React, { useState, useCallback, useEffect, useRef } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import "bootstrap/dist/css/bootstrap.min.css";
import url from "assets/js/config.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import BugReport from "@material-ui/icons/BugReport";
import Select from "react-select";
import $ from "jquery";
import MicroModal from "micromodal";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

var ht;
var is_catering;
var catering_type;
var capacity;
var that;
var business_description;
var establishment_type;
var specialization_area;
var alt_msisdn;
var email_id;
var website;
var no_of_employees;
var year_of_establishment;
var business_name;
var state;
var address;
var address1;
var name;
var images1;
var msisdn;
var experience_area;
var city;
var locality;
var pin;
var is_catering = "no";
var select_options;
var grand_data;

var job_title;
var skill_set;
var experience;
var locality;
var city;

const mystyle = {
  width: "250px",
  fontSize: "14px",
};
const mystyle1 = {
  fontSize: "14px",
};
const FileInput = (props) => (
  <input type="file" name="file" multiple value={props.value} />
);

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

var form_value = 0;

var that;
export default class Welcome extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    document
      .querySelector("#btnAddUsers")
      .addEventListener("click", function(e) {
        document.querySelector("#tblGetUserdetails").style.display = "none";
        document.querySelector("#tblAddUserdetails").style.display =
          "block";
        document.querySelector("#btnBack").style.display = "block";
      });

    document.querySelector("#btnBack").addEventListener("click", function(e) {
      document.querySelector("#tblGetUserdetails").style.display = "block";
      document.querySelector("#tblAddUserdetails").style.display = "none";
      document.querySelector("#btnBack").style.display = "none";
    });
  }

  render() {
    return (
      <div>
        <button
          className="btn btn-primary"
          id="btnBack"
          style={{
            float: "left",
            fontSize: "18px",
            marginBottom: "20px",
            display: "none",
          }}
        >
          Back
        </button>
        <button
          className="btn btn-primary"
          id="btnAddUsers"
          style={{ float: "right", fontSize: "18px", marginBottom: "20px" }}
        >
          Add User
        </button>
        <br />
        <br />
        <div id="tblGetUserdetails" style={{ display: "block" }}>
          <Table />
        </div>
        <div id="tblAddUserdetails" style={{ display: "none" }}>
          <Add_subuserdetails />
        </div>
      </div>
    );
  }
}

class Add_subuserdetails extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    // new get_multi().get_data(function(data) {
    //   grand_data = data;
    // });

    try {
      document
        .querySelector("#add_subusers")
        .addEventListener("click", function(e) {
          e.preventDefault();

          var name = document.querySelector("#name").value;
          var userid = document.querySelector("#userid").value;
          var password = document.querySelector("#password").value;
          try {
            if (
              name == "" ||
              userid == "" ||
              password == "" 
            ) {
              alert("All fields are Required");
              return;
            }
          } catch (e) {
            alert("All fields are Required");
            return;
          }

          var d = {
            name: name,
            userid: userid,
            password: password,
            subuserid: 0,
          };
          var x = new add(d);
          x.get_data();
        });
    } catch (e) {}
  }
  render() {
    return (
      <div>
        <h6>User Details</h6>

        <GridContainer>
          <GridItem xs={12} sm={6} md={1}></GridItem>
          <GridItem
            xs={12}
            sm={6}
            md={5}
            style={{
              border: "ridge",
              padding: "20px!important",
              borderRadius: "8px",
            }}
          >
            <table
              id="subuser_table"
              border="0"
              style={{ width: "100%", fontSize: "13px" }}
            >
              <thead>
                <tr>
                  <th style={{ paddingTop: "30px" }}>
                    Name :
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="text"
                      id="name"
                      placeholder="Enter name..."
                      style={{ width: "280px" }}
                    />
                  </th>
                </tr>
                <br />
                <tr>
                  <th>
                    User Id : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="text"
                      id="userid"
                      placeholder="Enter userid..."
                      style={{ width: "280px" }}
                    />
                  </th>
                </tr>
                <br />
                <tr>
                  <th>
                    Password :
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="password"
                      id="password"
                      placeholder="Enter password(max 6 characters)..."
                      style={{ width: "280px" }}
                      maxLength="6"
                    />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;             
                    <input
                      type="checkbox"
                      onClick={(e) => {
                        var x = document.getElementById("password");
                        if (x.type === "password") {
                          x.type = "text";
                        } else {
                          x.type = "password";
                        }
                      }}
                    />
                    &nbsp;
                    <span style={{ fontWeight: "100", fontSize: "13px" }}>Show Password</span>
                  </th>
                </tr>
                <br />
                <tr>
                  <th style={{ paddingTop: "32px", paddingLeft: "160px" }}>
                    <button
                      className="btn btn-primary"
                      id="add_subusers"
                      style={{ width: "150px", fontSize: "16px" }}
                    >
                      Add
                    </button>
                  </th>
                </tr>
                <br />
              </thead>
              <tbody></tbody>
            </table>
          </GridItem>
          <GridItem xs={12} sm={6} md={1}></GridItem>
        </GridContainer>
      </div>
    );
  }
}

var d;
class App extends React.Component {
  constructor(props) {
    super(props);
    try {
      for (var i = 0; i < props.data.length; i++) {
        props.data[i].label = props.data[i].name;
      }
    } catch (e) {}

    this.options = props.data;
    this.item = "";
    that = this;
  }
  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    d = selectedOption;
    console.log(`Option selected:`, selectedOption);
    try {
      document.querySelector("#name").value =
            selectedOption.name;
      document.querySelector("#userid").value =
        selectedOption.userid;
      document.querySelector("#password").value = selectedOption.password;
      
    } catch (e) {}
  };
  get_data() {
    return d;
  }
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.options}
      />
    );
  }
}
class add extends React.Component {
  constructor(props) {
    super(props);
    this.name = props.name;
    this.userid = props.userid;
    this.password = props.password;
    this.subuserid = props.subuserid;
  }
  get_data(cb) {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var msisdn = localStorage.getItem("ootel_msisdn");
    var postData = {
      name: this.name,
      userid: this.userid,
      password: this.password,
      msisdn: msisdn,
      auth_token: ootel_auth_token,
      date: this.date,
      subuser_id: this.subuserid,
    };
    // alert(postData);
    const rawResponse = fetch(url.server_url + "/add_hotelownersubusers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((data) => {
        try {
          if (data.error.length != 0) {
            alert(data.error);
            return;
          }
        } catch (e) {}
        alert(data.data);
        document.location.reload();
      })
      .catch((err) => {
        console.error("Error: ", err);
      });
  }
}

class get_search_data extends React.Component {
  constructor(page, per_page, column, direction, search) {
    super(page, per_page, column, direction, search);

    this.search = search;
    this.column = column;
    this.direction = direction;
    this.per_page = per_page;
    this.page = page;

    that = this;
  }

  get_data() {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var msisdn = localStorage.getItem("ootel_msisdn");
    var role = localStorage.getItem("ootel_role");

    var search_value;

    var postData = {
      auth_token: ootel_auth_token,
      search: this.search,
      column: this.column,
      direction: this.direction,
      per_page: this.per_page,
      role: "hotel_owner",
      msisdn: msisdn,
      page: this.page,
    };
    var tab = $("#table").DataTable();
    try {
      tab.destroy();
    } catch (e) {}

    tab = $("#table").DataTable({
      processing: true,
      serverSide: true,
      bPaginate: true, // Pagination True
      sPaginationType: "full_numbers", // And its type.
      iDisplayLength: 10,
      //Initial no order.
      search: {
        search: "",
      },
      ajax: {
        //  "url": "get_completed_tests_for_print_backup.php",
        url: url.server_url + "/get_hotelownersubusers",

        type: "POST",
        dataType: "JSON",
        data: {
          auth_token: ootel_auth_token,
          column: this.column,
          direction: this.direction,
          per_page: this.per_page,
          role: "hotel_owner",
          msisdn: msisdn,
          page: this.page,
        },

        dataSrc: function(json1) {
          var return_data = new Array();
          var data = json1.data;
          var data1 = json1.data1;
          var k = 1;
          for (var i = 0; i < data.length; i++) {
            var x = "<input type='text' value='" + data[i].location + "'>";

            return_data.push({
              sino: i + 1,
              date: data[i].post_date,
              name: data[i].name,
              userid: data[i].userid,
              password: data[i].password,
              status: data[i].status,
               action:
                "<select class='make_active_inactive' ids='" +
                data[i].id +
                "'><option value=''>--select--</option><option value='active'>active</option><option value='inactive'>inactive</option></select>" +
                '<a href="#" style="color: white;background-color: #007bff;font-size: 12px;padding: 6px;border-radius: 6px;margin-left: 20px;" subuserid="' +
               data[i].id +
                '" name="' +
                data[i].name +
                '" userid="' +
                data[i].userid +
                '" password="' +
                data[i].password +
                '" id="btnViewSubUsers" action="edit">Edit</a>' ,
               

              recordsTotal: 11,

              recordsFiltered: 11,
            });
          }
          $("#table11_filter")
            .find("input")
            .css({ width: "700px", "margin-left": "-80%" });
          $("#table11_filter")
            .find("input")
            .attr("placeholder", "Search  Item");

          return return_data;
        },
      },
      createdRow: function(row, data, index) {},
      columnDefs: [
        {
          targets: [0, 1], //first column / numbering column
          orderable: false, //set not orderable
        },
      ],
      columns: [
        { data: "sino" },
        { data: "date" },
        { data: "name" },
        { data: "userid" },
        { data: "password" },
        { data: "status" },
        { data: "action" },
      ],
    });
  }
}

class Table extends React.Component {
  componentDidMount() {
   

    var c = new get_search_data("1", "10", "id", "desc");
    c.get_data();

     var subrole = localStorage.getItem("subrole");
    if (subrole == "subuser") {
      document.getElementById("table_wrapper").style.display = "none";
      document.getElementById("btnAddUsers").style.display = "none";
    }

    MicroModal.init({
      onShow: (modal) => console.info("${modal.id} is shown"),
      onClose: (modal) => console.info("${modal.id} is hidden"),
      openTrigger: "data-custom-open", // [3]
      closeTrigger: "data-custom-close", // [4]
      disableScroll: true, // [5]
      disableFocus: false, // [6]
      awaitOpenAnimation: false, // [7]
      awaitCloseAnimation: false, // [8]
      debugMode: true, // [9]
    });
    document.querySelector("#table").addEventListener("click", function(e) {
      var subuserid = e.target.getAttribute("subuserid");
      var action = e.target.getAttribute("action");
      if (subuserid != null) {
        document.querySelector("#name1").value = e.target.getAttribute(
          "name"
        );
        document.querySelector(
          "#userid1"
        ).value = e.target.getAttribute("userid");
        document.querySelector(
          "#password1"
          ).value = e.target.getAttribute("password");

        MicroModal.show("modal-1");
        if (action == "view") {
          document
            .querySelector("#modal1Fields")
            .setAttribute("disabled", "disabled");
          document.querySelector("#btnedit").style.display = "none";
          document.querySelector("#btnclose").style.display = "block";
        } else {
          document.querySelector("#btnedit").style.display = "block";
          document.querySelector("#btnclose").style.display = "none";
          document.querySelector("#modal1Fields").removeAttribute("disabled");
          document
            .querySelector("#modal1Fields")
            .setAttribute("subuserid", subuserid);
        }
      }
    });
    document.querySelector("#btnclose").addEventListener("click", function(e) {
      e.preventDefault();
      MicroModal.close("modal-1");
    });

    document.querySelector("#btnedit").addEventListener("click", function(e) {
      e.preventDefault();
      var subuser_id = document
        .querySelector("#modal1Fields")
        .getAttribute("subuserid");
      var name = document.querySelector("#name1").value;
      var userid = document.querySelector("#userid1").value;
      var password = document.querySelector("#password1").value;
     
      try {
        if (
          name == "" ||
          userid == "" ||
          password == "" 
        ) {
          alert("All fields are Required");
          return;
        }
      } catch (e) {
        alert("All fields are Required");
        return;
      }
      var d = {
        name: name,
        userid: userid,
        password: password,
        subuserid: subuser_id,
      };
      var x = new add(d);
      x.get_data();
      MicroModal.close("modal-1");
    });
      

    document
      .querySelector("#export_report")
      .addEventListener("click", function (e) {
        e.preventDefault();

        
          var ootel_auth_token = localStorage.getItem("ootel_auth_token");
          var msisdn = localStorage.getItem("ootel_msisdn");

          var csvData = [];
          var fileName = "Sub Users"

          var postData = {
              auth_token: ootel_auth_token,
              column: "id",
              search: "",
              direction: "desc",
              length: 1,
              role: "hotel_owner",
              msisdn: msisdn,
              start: 0,
              page: 1,  
              per_page: 1,
              isExport: "Y",
          };
       
          const rawResponse1 = fetch(url.server_url + "/get_hotelownersubusers", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }).then((res) => res.json())
            .then((result) => {
          
              var data = result.data;
              var data1 = result.data1;
              // alert(data.length);
                for (var i = 0; i < data.length; i++) {
                  csvData.push({
                          SiNo: i + 1,
                          Date: data[i].post_date,
                          Name: data[i].name,
                          UserId: data[i].userid,
                          Password: data[i].password,
                          Status: data[i].status,
                    });
                          
                };
         
              console.log(csvData);
                const fileType =
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const fileExtension = ".xlsx";

                const ws = XLSX.utils.json_to_sheet(csvData);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, {
                  bookType: "xlsx",
                  type: "array",
                });
                const exceldata = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(exceldata, fileName + fileExtension);
              
            }).catch((err) => {
              console.error("Error: ", err);
            });
        
      });  

      document.querySelector("#table").addEventListener(
      "change",
      function(e) {
        if (new clk().hasClass(e.target, "make_active_inactive")) {
          var elem = e.target;
          var z = elem.className.split(" ");

          var status = e.target.value;
          var id = e.target.getAttribute("ids");

          var ootel_auth_token = localStorage.getItem("ootel_auth_token");

          var postData = {
            auth_token: ootel_auth_token,
            id: id,
            status: status,
          };
          const rawResponse = fetch(
            url.server_url + "/active_inactive_hotelownersubusers",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(postData),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              try {
                if (data.error.length != 0) {
                  alert(data.error);
                  return;
                }
              } catch (e) {}
              alert(data.data);

              var c = new get_search_data("1", "10", "id", "desc");
              c.get_data();
            })
            .catch((err) => {
              console.error("Error: ", err);
            });
        }
      },
      false
    );
      

  }

  render() {
    var subrole = localStorage.getItem("subrole");
    if (subrole == "subuser") {
      
      return (<div style={{ fontSize: "14px", color: "red" }}>Not authorised!!
        <table id="table" style= {{ display: "none"}}>
          <thead>
            <tr style={{ backgroundColor: "#8fcbcd"}}>
              <th>Si No</th>
              <th>Created On</th>
              <th>Name</th>
              <th>User Id</th>
              <th>Password</th>
              <th>Status</th>
              <th style= {{ width: "140px" }}>Action</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
        <button id="btnclose" style= {{ display: "none"}}></button>
        <button id="btnedit" style= {{ display: "none"}} ></button>
      </div>);
    }
    else {
      return (
      <div className="cl">
        <table className="table table-striped table-bordered" id="table">
          <thead>
            <tr style={{ backgroundColor: "#8fcbcd" }}>
              <th>Si No</th>
              <th>Created On</th>
              <th>Name</th>
              <th>User Id</th>
              <th>Password</th>
              <th>Status</th>
              <th style= {{ width: "140px" }}>Action</th>
            </tr>
          </thead>
          <tbody></tbody>
          </table>
          
            <br />
            <br />
            <button
              id="export_report"
              className="btn btn-primary"
              style={{ width: "110px", fontSize: "15px", float: "right" }}
            >
              Export Excel
            </button>
            <br />
            <br />
        <div className="modal micromodal-slide" id="modal-1" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              style={{ width: "100%" }}
              className="modal__container"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-1-title"
            >
              <header className="modal__header">
                <h4 v="modal__title" id="modal-1-title">
                  User Details
                </h4>
                <button
                  className="modal__close"
                  aria-label="Close modal"
                  data-micromodal-close
                ></button>
              </header>
              <main className="modal__content" id="modal-1-content">
                <form id="ViewEdit_form" method="post">
                  <fieldset id="modal1Fields">
                    Name :
                    <br />
                    <input type="text" id="name1" className="form-control" />
                    <br />
                    User Id :
                    <br />
                    <input type="text" id="userid1" className="form-control" />
                    <br />
                    Password :
                    <br />
                    <input
                      type="password"
                      id="password1"
                      maxLength="6"
                      className="form-control"
                    />
                    <input
                      type="checkbox"
                      onClick={(e) => {
                        var x = document.getElementById("password1");
                        if (x.type === "password") {
                          x.type = "text";
                        } else {
                          x.type = "password";
                        }
                      }}
                    />
                    &nbsp;
                    <span style={{ fontWeight: "100", fontSize: "13px" }}>
                      Show Password
                    </span>
                  </fieldset>
                  <button class="btn btn-primary" id="btnedit">
                    Save
                  </button>
                  <button class="btn btn-primary" id="btnclose">
                    Close
                  </button>
                </form>
              </main>
            </div>
          </div>
        </div>
      </div>
    );
    }
  }
}
class clk {
  hasClass(elem, className) {
    try {
      return elem.className.split(" ").indexOf(className) > -1;
    } catch (e) {
      return -1;
    }
  }
}
