import React from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
// @material-ui/core components

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import avatar from "assets/img/faces/marc.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import url from "assets/js/config.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import BugReport from "@material-ui/icons/BugReport";

import ReactTable from "react-table-6";

import "react-table-6/react-table.css";

import "assets/css/styles.css";
import { Photo_details } from "views/hotel_owners/UserProfile/photo_gallery.js";
import MicroModal from "micromodal";
import { Multiselect } from "multiselect-react-dropdown";
import $ from "jquery";
import DataTable from "datatables.net";
import { Table } from "@material-ui/core";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

var that;
var issue_id=0;
var member_role="";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

var that;
var columns;
export default class Welcome extends React.Component {
  constructor(props) {
    super(props);
    that = this;
    this.state = {
      data: props.value,
      loading: false,
      pages: 0,
      pagesize: 10,
      fetch_flag: 1,
      showModal_data: false,
    };
  }

  componentDidMount() {
    MicroModal.init({
      onShow: (modal) => console.info("${modal.id} is shown"),
      onClose: (modal) => console.info("${modal.id} is hidden"),
      openTrigger: "data-custom-open", // [3]
      closeTrigger: "data-custom-close", // [4]
      disableScroll: true, // [5]
      disableFocus: false, // [6]
      awaitOpenAnimation: false, // [7]
      awaitCloseAnimation: false, // [8]
      debugMode: true, // [9]
    });

    // var data=new get_search_data("","","","").get_data();
  }

  render() {
    const mystyle = {
      width: "300px",
      fontSize: "14px",
    };

    const { data } = this.state;

    const overlayClassName = this.state.showModal_data
      ? "modal fade show"
      : "modal fade";

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTabs
              title=""
              headerColor="primary"
              tabs={[
                            
                {
                  tabName: "Report History",
                  tabIcon: BugReport,
                  tabContent: (
                    <div>
                      <div id="table_data">
                      <Table1 />
                      </div>
                    </div>
                  ),
                },
                {
                  tabName: "Reporting",
                  tabIcon: BugReport,
                  tabContent: (
                    <div>
                      <Report />
                    </div>
                  ),
                }  
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

class Report extends React.Component {
  componentDidMount() {
    new multi_select().get_multi();

    document
      .querySelector("#submit_report")
      .addEventListener("click", function() {
        var ootel_auth_token = localStorage.getItem("ootel_auth_token");
        var msisdn = localStorage.getItem("ootel_msisdn");
        var description = document.getElementById("description").value .trim();
        var member_msisdn = document.getElementById("mobile_no").value.trim();
        var member_name = document.getElementById("name").value.trim();
        if(member_msisdn == "" || issue_id == 0 
          || member_role =="" || description == ""
          || member_name =="")
          {
            alert("All fields are mandatory");
            return;
          }
        var postData = {
          auth_token: ootel_auth_token,
          description: description,
          msisdn: msisdn,
          members_name:member_name,
          members_msisdn:member_msisdn,
          report_id:0,
          members_role:member_role,
          issue_id:issue_id
        };

        const rawResponse1 = fetch(url.server_url + "/add_report_issue", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        })
          .then((res) => res.json())
          .then((data) => {
            if (typeof data.error != "undefined") {
              try {
                alert(data.error);
              } catch (e) {
                console.log(e.message);
              }
            } else {
              alert(data.data);
              document.location.reload();
            }
          })
          .catch((err) => {
            console.error("Error: ", err);
          });
      });
  }
  render() {
    return (
      <table>
        <tr>
          <td width="250">
            <span style={{ fontSize: "17px", float: "right" }}>Entity : </span>
          </td>
          <td>
            <div id="members_role"></div>
          </td>
        </tr>
        <tr>
          <td>
            <span
              style={{ fontSize: "17px", float: "right" }}
              id="spnMemberName"
            >
              {" "}
              Name :{" "}
            </span>
          </td>
          <td>
            <input
              type="text"
              id="name"
              style={{ width: "290px" }}
              placeholder="Enter Name"
            />
          </td>
        </tr>
        <tr>
          <td>
            <span
              style={{ fontSize: "17px", float: "right" }}
              id="spnMemberMobile"
            >
              {" "}
              Mobile :
            </span>
          </td>
          <td>
            <input
              type="text"
              id="mobile_no"
              style={{ width: "290px" }}
              placeholder="Enter Mobile No"
            />
          </td>
        </tr>
        <tr>
          <td>
            <span style={{ fontSize: "17px", float: "right" }}>
              Select Issue :
            </span>
          </td>
          <td>
            <div id="member_issue"><input
              type="text"
              id="txtIssuType"
              style={{ width: "290px" }}
              disabled = "disabled"
              placeholder="Select Entity"
            /></div>
          </td>
        </tr>
        <tr>
          <td>
            <span style={{ fontSize: "17px", float: "right" }}>
              Describe Issue :
            </span>
          </td>
          <td>
            <textarea id="description" ootel_msisdn></textarea>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <button
              id="submit_report"
              className="btn btn-primary"
              style={{ width: "110px", fontSize: "15px" }}
            >
              {" "}
              Submit
            </button>
          </td>
        </tr>
      </table>
    );
  }
}

class multi_select extends React.Component {
  get_multi() {
    var entity = [
      {
        name: "Hotel Owner",
        value: "hotel_owner",
      },
    ];

    ReactDOM.render(
      <Multiselect
        options={entity} // Options to display in the dropdown
        //selectedValues={} // Preselected value to persist in dropdown
        onSelect={this.onSelect_MemberRole} // Function will trigger on select event
        onRemove={this.onRemove_MemberRole} // Function will trigger on remove event
        displayValue="name"
        selectionLimit="1"
        // Property name to display in the dropdown options
      />,
      document.getElementById("members_role")
    );
  }
  onSelect_MemberRole(selectedList, removedItem) {
    document.getElementById("spnMemberName").innerHTML =
      selectedList[0].name + " Name :";
    document.getElementById("spnMemberMobile").innerHTML =
      selectedList[0].name + " Mobile :";
    console.log(selectedList[0].value);
    member_role=selectedList[0].value;
    new getIssueType().get_issuetypes(member_role);
  }

  onRemove_MemberRole(selectedList, removedItem) {
    document.getElementById("spnMemberName").innerHTML = " Name :";
    document.getElementById("spnMemberMobile").innerHTML = " Mobile :";
    member_role ="";
    new getIssueType().get_issuetypes('');
  }
}

class getIssueType extends React.Component {
  get_issuetypes(memberRole) {
    
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var ootel_msisdn = localStorage.getItem("ootel_msisdn");
    var postData = {
      auth_token: ootel_auth_token,
      msisdn: ootel_msisdn,
      member_role: memberRole,
    };

    fetch(url.server_url + "/get_members_issue_types", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (typeof data.error != "undefined") {
          try {
            ReactDOM.render(
              <input
              type="text"
              id="txtIssuType"
              style={{ width: "290px" }}
              disabled = "disabled"
              placeholder="Select Entity"
            />,
              document.getElementById("member_issue")
            );
          } catch (e) {
            console.log(e.message);
          }
        } else {
         
          ReactDOM.render(
            <Multiselect
              options={data.data} // Options to display in the dropdown
              //selectedValues={} // Preselected value to persist in dropdown
              onSelect={this.onSelect_IssueTyep} // Function will trigger on select event
              onRemove={this.onRemove_IsuueType} // Function will trigger on remove event
              displayValue="name"
              selectionLimit="1"
              // Property name to display in the dropdown options
            />,
            document.getElementById("member_issue")
          );

          document
            .querySelector("#multiselectContainerReact")
            .querySelector("div").style.border = "none";

          document
            .querySelector("#multiselectContainerReact")
            .querySelector("div")
            .querySelector("input").style.width = "100%";
        }
      })
      .catch((err) => {
        console.error("Error: ", err);
      });
  }
  onSelect_IssueTyep(selectedList, removedItem) {
      issue_id=selectedList[0].id;
  }

  onRemove_IsuueType(selectedList, removedItem) {
    issue_id=0;
  }
}


class Table1 extends React.Component {
  componentDidMount() {
    var c = new get_search_data("1", "10", "id", "desc");
    c.get_data();

     document
      .querySelector("#export_report")
      .addEventListener("click", function (e) {
        e.preventDefault();

        
          var ootel_auth_token = localStorage.getItem("ootel_auth_token");
          var msisdn = localStorage.getItem("ootel_msisdn");

          var csvData = [];
          var fileName = "Reporting Details"

          var postData = {
              auth_token: ootel_auth_token,
              column: "id",
              search: "",
              direction: "desc",
              length: 1,
              msisdn: msisdn,
              start: 0,
              page: 1,  
              per_page: 1,
              isExport: "Y",
          };
       
          const rawResponse1 = fetch(url.server_url + "/get_report_history", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }).then((res) => res.json())
            .then((result) => {
          
              var data = result.data;
              var data1 = result.data1;
              // alert(data.length);
                for (var i = 0; i < data.length; i++) {
                  csvData.push({
                        SiNo: i + 1,
                        Date: data[i].created_date,
                        Role: data[i].members_role,
                        Name: data[i].members_name,
                        Mobile: data[i].members_msisdn,
                        Issue: data[i].issue_name,
                        Description: data[i].description,
                    });
                          
                };
         
              console.log(csvData);
                const fileType =
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const fileExtension = ".xlsx";

                const ws = XLSX.utils.json_to_sheet(csvData);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, {
                  bookType: "xlsx",
                  type: "array",
                });
                const exceldata = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(exceldata, fileName + fileExtension);
              
            }).catch((err) => {
              console.error("Error: ", err);
            });
        
    });
    
  }

  render() {
    return (
      <div className="cl" style={{ overflow: "auto", width: "1000px" }}>
        <table className="table table-striped table-bordered" id="table">
          <thead style={{ background: "lightsteelblue" }}>
            <tr>
              <th>Sl No</th>
              <th>Date</th>
              <th>Entity</th>
              <th>Name</th>
              <th>Mobile</th>
              <th>Issue</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>

            <br />
            <br />
            <button
              id="export_report"
              className="btn btn-primary"
              style={{ width: "110px", fontSize: "15px", float: "right" }}
            >
              Export Excel
            </button>
            <br />
            <br />
      </div>
    );
  }
}

class get_search_data extends React.Component {
  constructor(page, per_page, column, direction, search) {
    super(page, per_page, column, direction, search);

    this.search = search;
    this.column = column;
    this.direction = direction;
    this.per_page = per_page;
    this.page = page;

    that = this;
  }
  componentDidMount() {}
  get_data() {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var msisdn = localStorage.getItem("ootel_msisdn");

    var search_value;

    var postData = {
      auth_token: ootel_auth_token,
      search: this.search,
      column: this.column,
      direction: this.direction,
      per_page: this.per_page,
      msisdn: msisdn,
      page: this.page,
      start:(this.page-1)*this.per_page
    };
    var tab = $("#table").DataTable();
    try {
      tab.destroy();
    } catch (e) {}

    tab = $("#table").DataTable({
      processing: true,
      serverSide: true,
      bPaginate: true, // Pagination True
      sPaginationType: "full_numbers", // And its type.
      iDisplayLength: 10,
      //Initial no order.
      search: {
        search: "",
      },
      ajax: {
        //  "url": "get_completed_tests_for_print_backup.php",
        url: url.server_url + "/get_report_history",

        type: "POST",
        dataType: "JSON",
        data: {
          auth_token: ootel_auth_token,
          column: this.column,
          direction: this.direction,
          per_page: this.per_page,
          msisdn: msisdn,
          page: this.page,
        },

        dataSrc: function(json1) {
          var return_data = new Array();
          var data = json1.data;
          var data1 = json1.data1;
          var k = 1;
          for (var i = 0; i < data.length; i++) {
            
            

            return_data.push({
              sl_no: k++,
              date: data[i].created_date,
              role: data[i].members_role,
              name: data[i].members_name,
              mobile: data[i].members_msisdn,
              issue: data[i].issue_name,
              description: data[i].description,
              recordsTotal: 11,
              recordsFiltered: 11,
            });
          }
          $("#table_filter")
            .find("input")
            .css({ width: "700px" });
          $("#table_filter")
            .find("input")
            .attr("placeholder", "Search  Report");

          return return_data;
        },
      },
      createdRow: function(row, data, index) {},
      columnDefs: [
        {
          targets: [0, 1], //first column / numbering column
          orderable: false, //set not orderable
        },
      ],
      columns: [
        { data: "sl_no" },
        { data: "date" },
        { data: "role" },
        { data: "name" },
        { data: "mobile" },
        { data: "issue" },
        { data: "description" }
      ],
    });
  }
}

class clk {
  hasClass(elem, className) {
    try {
      return elem.className.split(" ").indexOf(className) > -1;
    } catch (e) {
      return -1;
    }
  }
}
