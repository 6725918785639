import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar_hotel from "components/Sidebar/Sidebar.js";
import Sidebar_employee from "components/Sidebar/Sidebar_employee.js";
import Sidebar_vendor from "components/Sidebar/Sidebar_vendor.js";
import Sidebar_supplier from "components/Sidebar/Sidebar_supplier.js";

import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import bgImage from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo001.png";

let ps;
var ootel_auth_token = localStorage.getItem("ootel_auth_token");
var role = localStorage.getItem("ootel_role");
var switchRoutes = "";
var switchRoutes_hotel_owner;
var switchRoutes_employee;
var switchRoutes_vendor;
var switchRoutes_supplier;

try {
  switchRoutes_hotel_owner = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "/hotel_owners") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              component={() => <prop.component name="jagadish" />}
              data="z"
              key={key}
            />
          );
        }

        return null;
      })}

      <Redirect from="/hotel_owner" to="/hotel_owner/dashboard" />
    </Switch>
  );

  switchRoutes_employee = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "/employees") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              component={() => <prop.component name="jagadish" />}
              data="z"
              key={key}
            />
          );
        }

        return null;
      })}

      <Redirect from="/employees" to="/employees/dashboard" />
    </Switch>
  );

  switchRoutes_vendor = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "/vendors") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              component={() => <prop.component name="jagadish" />}
              data="z"
              key={key}
            />
          );
        }

        return null;
      })}

      <Redirect from="/vendors" to="/vendors/dashboard" />
    </Switch>
  );

  switchRoutes_supplier = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "/suppliers") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              component={() => <prop.component name="jagadish" />}
              data="z"
              key={key}
            />
          );
        }

        return null;
      })}

      <Redirect from="/suppliers" to="/suppliers/dashboard" />
    </Switch>
  );
} catch (e) {}

var Sidebar;

if (role == "hotel_owner") {
  switchRoutes = switchRoutes_hotel_owner;
  Sidebar = Sidebar_hotel;
}

if (role == "employee") {
  switchRoutes = switchRoutes_employee;
  Sidebar = Sidebar_employee;
}
if (role == "vendor") {
  switchRoutes = switchRoutes_vendor;
  Sidebar = Sidebar_vendor;
}

if (role == "supplier") {
  switchRoutes = switchRoutes_supplier;
  Sidebar = Sidebar_supplier;
}

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  // styles
  const classes = useStyles();

  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        // logoText={"iOtel"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer /> : null}
      </div>
    </div>
  );
}
