import React, { useState, useCallback, useEffect, useRef } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import "bootstrap/dist/css/bootstrap.min.css";
import url from "assets/js/config.js";

import CustomTabs from "components/CustomTabs/CustomTabs.js";
import BugReport from "@material-ui/icons/BugReport";
import Select from "react-select";
import $ from "jquery";
import MicroModal from "micromodal";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

var ht;
var is_catering;
var catering_type;
var capacity;
var that;
var business_description;
var establishment_type;
var specialization_area;
var alt_msisdn;
var email_id;
var website;
var no_of_employees;
var year_of_establishment;
var business_name;
var state;
var address;
var address1;
var name;
var images1;
var msisdn;
var experience_area;
var city;
var locality;
var pin;
var is_catering = "no";
var select_options;
var grand_data;

var job_title;
var skill_set;
var experience;
var locality;
var city;

const mystyle = {
  width: "250px",
  fontSize: "14px",
};
const mystyle1 = {
  fontSize: "14px",
};
const FileInput = (props) => (
  <input type="file" name="file" multiple value={props.value} />
);

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

var form_value = 0;

var that;
export default class Welcome extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    document
      .querySelector("#btnAddEmployee")
      .addEventListener("click", function(e) {
        document.querySelector("#tblGetEmployee").style.display = "none";
        document.querySelector("#tblAddEmployee").style.display = "block";
        document.querySelector("#btnBack").style.display = "block";
      });

    document.querySelector("#btnBack").addEventListener("click", function(e) {
      document.querySelector("#tblGetEmployee").style.display = "block";
      document.querySelector("#tblAddEmployee").style.display = "none";
      document.querySelector("#btnBack").style.display = "none";
    });
  }

  render() {
    return (
      <div>
        <button
          className="btn btn-primary"
          id="btnBack"
          style={{
            float: "left",
            fontSize: "18px",
            marginBottom: "20px",
            display: "none",
          }}
        >
          Back
        </button>
        <button
          className="btn btn-primary"
          id="btnAddEmployee"
          style={{ float: "right", fontSize: "18px", marginBottom: "20px" }}
        >
          Add New
        </button>
        <br />
        <br />
        <div id="tblGetEmployee" style={{ display: "block" }}>
          <Table />
        </div>
        <div id="tblAddEmployee" style={{ display: "none" }}>
          <Add_employeedetails />
        </div>
      </div>
    );
  }
}

class Add_employeedetails extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    // new get_multi().get_data(function(data) {
    //   grand_data = data;
    // });

    try {
      document
        .querySelector("#add_employee")
        .addEventListener("click", function(e) {
          e.preventDefault();

          var add_date = document.querySelector("#add_date").value;
          var employee_name = document.querySelector("#employee_name").value;
          var amount = document.querySelector("#amount").value;
          var remarks = document.querySelector("#remarks").value;
          try {
            if (
              add_date == "" ||
              employee_name == "" ||
              amount == "" ||
              remarks == ""
            ) {
              alert("All fields are Required");
              return;
            }
          } catch (e) {
            alert("All fields are Required");
            return;
          }

          var d = {
            add_date: add_date,
            employee_name: employee_name,
            amount: amount,
            remarks: remarks,
            employeesalaryid: 0,
          };
          var x = new add(d);
          x.get_data();
        });
    } catch (e) {}
  }
  render() {
    return (
      <div>
        <h6>Employee Salary</h6>

        <GridContainer>
          <GridItem xs={12} sm={6} md={1}></GridItem>
          <GridItem xs={12} sm={6} md={5}>
            <table
              id="employeesalary_table"
              border="0"
              style={{ width: "100%", fontSize: "13px" }}
            >
              <thead>
                <tr>
                  <th>
                    Date :
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="date"
                      id="add_date"
                      style={{ width: "200px" }}
                    />
                  </th>
                </tr>
                <br />
                <tr>
                  <th>
                    Employee Name : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="text"
                      id="employee_name"
                      placeholder="Enter Name..."
                      style={{ width: "280px" }}
                    />
                  </th>
                </tr>
                <br />
                <tr>
                  <th>
                    Amount : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      type="text"
                      id="amount"
                      placeholder="Enter Amount..."
                      style={{ width: "280px" }}
                    />
                  </th>
                </tr>
                <br />
                <tr>
                  <th>
                    Remarks :
                    <textarea
                      id="remarks"
                      placeholder="Enter Remarks..."
                      style={{
                        width: "280px",
                        float: "right",
                        marginRight: "10px",
                      }}
                    />
                  </th>
                </tr>
                <br />
                <tr>
                  <th style={{ paddingTop: "32px", paddingLeft: "160px" }}>
                    <button
                      className="btn btn-primary"
                      id="add_employee"
                      style={{ width: "150px", fontSize: "16px" }}
                    >
                      Submit
                    </button>
                  </th>
                </tr>
                <br />
              </thead>
              <tbody></tbody>
            </table>
          </GridItem>
          <GridItem xs={12} sm={6} md={1}></GridItem>
        </GridContainer>
      </div>
    );
  }
}

var d;
class App extends React.Component {
  constructor(props) {
    super(props);
    try {
      for (var i = 0; i < props.data.length; i++) {
        props.data[i].label = props.data[i].name;
      }
    } catch (e) {}

    this.options = props.data;
    this.item = "";
    that = this;
  }
  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    d = selectedOption;
    console.log(`Option selected:`, selectedOption);
    try {
      document.querySelector("#add_date").value = selectedOption.add_date;
      document.querySelector("#employee_name").value =
        selectedOption.employee_name;
      document.querySelector("#amount").value = selectedOption.amount;
      document.querySelector("#remarks").value = selectedOption.remarks;
    } catch (e) {}
  };
  get_data() {
    return d;
  }
  render() {
    const { selectedOption } = this.state;

    return (
      <Select
        value={selectedOption}
        onChange={this.handleChange}
        options={this.options}
      />
    );
  }
}
class add extends React.Component {
  constructor(props) {
    super(props);
    this.add_date = props.add_date;
    this.employee_name = props.employee_name;
    this.amount = props.amount;
    this.remarks = props.remarks;
    this.employeesalaryid = props.employeesalaryid;
  }
  get_data(cb) {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var msisdn = localStorage.getItem("ootel_msisdn");
    var postData = {
      add_date: this.add_date,
      employee_name: this.employee_name,
      amount: this.amount,
      remarks: this.remarks,
      msisdn: msisdn,
      auth_token: ootel_auth_token,
      date: this.date,
      employeesalary_id: this.employeesalaryid,
    };
    // alert(postData);
    const rawResponse = fetch(url.server_url + "/add_employeesalarydetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((data) => {
        try {
          if (data.error.length != 0) {
            alert(data.error);
            return;
          }
        } catch (e) {}
        alert(data.data);
        document.location.reload();
      })
      .catch((err) => {
        console.error("Error: ", err);
      });
  }
}

class get_search_data extends React.Component {
  constructor(page, per_page, column, direction, search, from_date, to_date) {
    super(page, per_page, column, direction, search, from_date, to_date);

    this.search = search;
    this.column = column;
    this.direction = direction;
    this.per_page = per_page;
    this.page = page;
    this.from_date = from_date;
    this.to_date = to_date;

    that = this;
  }

  get_data() {
    var ootel_auth_token = localStorage.getItem("ootel_auth_token");
    var msisdn = localStorage.getItem("ootel_msisdn");
    var role = localStorage.getItem("ootel_role");

    // alert(this.from_date);
    // alert(this.to_date)

    var search_value;

    var tab = $("#table").DataTable();
    try {
      tab.destroy();
    } catch (e) {}

    tab = $("#table").DataTable({
      processing: true,
      serverSide: true,
      bPaginate: true, // Pagination True
      sPaginationType: "full_numbers", // And its type.
      iDisplayLength: 10,
      //Initial no order.
      search: {
        search: "",
      },
      ajax: {
        //  "url": "get_completed_tests_for_print_backup.php",
        url: url.server_url + "/get_employeesalarydetails",

        type: "POST",
        dataType: "JSON",
        data: {
          auth_token: ootel_auth_token,
          column: this.column,
          direction: this.direction,
          per_page: this.per_page,
          role: "hotel_owner",
          msisdn: msisdn,
          page: this.page,
          from_date: this.from_date,
          to_date: this.to_date,
        },

        dataSrc: function(json1) {
          var return_data = new Array();
          var data = json1.data;
          var data1 = json1.data1;
          var k = 1;
          for (var i = 0; i < data.length; i++) {
            var x = "<input type='text' value='" + data[i].location + "'>";

            return_data.push({
              sino: i + 1,
              date: data[i].post_date,
              add_date: data[i].add_date,
              employee_name: data[i].employee_name,
              amount: data[i].amount,
              remarks: data[i].remarks,
              action:
                '<a href="#" style="color: white;background-color: #007bff;font-size: 12px;padding: 6px;border-radius: 6px;margin-left: 34px;" employeesalaryid="' +
                data[i].id +
                '" add_date="' +
                data[i].add_date +
                '" employee_name="' +
                data[i].employee_name +
                '" amount="' +
                data[i].amount +
                '" remarks="' +
                data[i].remarks +
                '" id="btnViewEmployeesalarydetails" action="edit">Edit</a>',

              recordsTotal: 11,

              recordsFiltered: 11,
            });
          }
          $("#table11_filter")
            .find("input")
            .css({ width: "700px", "margin-left": "-80%" });
          $("#table11_filter")
            .find("input")
            .attr("placeholder", "Search  Item");

          return return_data;
        },
      },
      createdRow: function(row, data, index) {},
      columnDefs: [
        {
          targets: [0, 1], //first column / numbering column
          orderable: false, //set not orderable
        },
      ],
      columns: [
        { data: "sino" },
        { data: "date" },
        { data: "add_date" },
        { data: "employee_name" },
        { data: "amount" },
        { data: "remarks" },
        { data: "action" },
      ],
    });
  }
}

class Table extends React.Component {
  componentDidMount() {
    var c = new get_search_data("1", "10", "id", "desc", "", "", "");
    c.get_data();

    MicroModal.init({
      onShow: (modal) => console.info("${modal.id} is shown"),
      onClose: (modal) => console.info("${modal.id} is hidden"),
      openTrigger: "data-custom-open", // [3]
      closeTrigger: "data-custom-close", // [4]
      disableScroll: true, // [5]
      disableFocus: false, // [6]
      awaitOpenAnimation: false, // [7]
      awaitCloseAnimation: false, // [8]
      debugMode: true, // [9]
    });

    document.querySelector("#getdetails").addEventListener("click", function() {
      var from_date = document.querySelector("#from_date").value;
      var to_date = document.querySelector("#to_date").value;

      var c = new get_search_data(
        "1",
        "10",
        "id",
        "desc",
        "",
        from_date,
        to_date
      );
      c.get_data();
    });
    document.querySelector("#table").addEventListener("click", function(e) {
      var employeesalaryid = e.target.getAttribute("employeesalaryid");
      var action = e.target.getAttribute("action");
      if (employeesalaryid != null) {
        document.querySelector("#add_date1").value = e.target.getAttribute(
          "add_date"
        );
        document.querySelector("#employee_name1").value = e.target.getAttribute(
          "employee_name"
        );
        document.querySelector("#amount1").value = e.target.getAttribute(
          "amount"
        );
        document.querySelector("#remarks1").value = e.target.getAttribute(
          "remarks"
        );

        MicroModal.show("modal-1");
        if (action == "view") {
          document
            .querySelector("#modal1Fields")
            .setAttribute("disabled", "disabled");
          document.querySelector("#btnedit").style.display = "none";
          document.querySelector("#btnclose").style.display = "block";
        } else {
          document.querySelector("#btnedit").style.display = "block";
          document.querySelector("#btnclose").style.display = "none";
          document.querySelector("#modal1Fields").removeAttribute("disabled");
          document
            .querySelector("#modal1Fields")
            .setAttribute("employeesalaryid", employeesalaryid);
        }
      }
    });
    document.querySelector("#btnclose").addEventListener("click", function(e) {
      e.preventDefault();
      MicroModal.close("modal-1");
    });

    document.querySelector("#btnedit").addEventListener("click", function(e) {
      e.preventDefault();
      var employeesalary_id = document
        .querySelector("#modal1Fields")
        .getAttribute("employeesalaryid");
      var add_date = document.querySelector("#add_date1").value;
      var employee_name = document.querySelector("#employee_name1").value;
      var amount = document.querySelector("#amount1").value;
      var remarks = document.querySelector("#remarks1").value;

      try {
        if (
          add_date == "" ||
          employee_name == "" ||
          amount == "" ||
          remarks == ""
        ) {
          alert("All fields are Required");
          return;
        }
      } catch (e) {
        alert("All fields are Required");
        return;
      }
      var d = {
        add_date: add_date,
        employee_name: employee_name,
        amount: amount,
        remarks: remarks,
        employeesalaryid: employeesalary_id,
      };
      var x = new add(d);
      x.get_data();
      MicroModal.close("modal-1");
    });

    document
      .querySelector("#export_report")
      .addEventListener("click", function(e) {
        e.preventDefault();

        var ootel_auth_token = localStorage.getItem("ootel_auth_token");
        var msisdn = localStorage.getItem("ootel_msisdn");
        var from_date = document.querySelector("#from_date").value;
        var to_date = document.querySelector("#to_date").value;
        var csvData = [];
        var fileName = "Employee Salary";

        var postData = {
          auth_token: ootel_auth_token,
          column: "id",
          search: "",
          direction: "desc",
          length: 1,
          role: "hotel_owner",
          msisdn: msisdn,
          start: 0,
          page: 1,
          per_page: 1,
          to_date: to_date,
          from_date: from_date,
          isExport: "Y",
        };

        const rawResponse1 = fetch(
          url.server_url + "/get_employeesalarydetails",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          }
        )
          .then((res) => res.json())
          .then((result) => {
            var data = result.data;
            var data1 = result.data1;
            // alert(data.length);
            for (var i = 0; i < data.length; i++) {
              csvData.push({
                SiNo: i + 1,
                Date: data[i].post_date,
                AddDate: data[i].add_date,
                EmployeeName: data[i].employee_name,
                Amount: data[i].amount,
                Remarks: data[i].remarks,
              });
            }

            console.log(csvData);
            const fileType =
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const fileExtension = ".xlsx";

            const ws = XLSX.utils.json_to_sheet(csvData);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, {
              bookType: "xlsx",
              type: "array",
            });
            const exceldata = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(exceldata, fileName + fileExtension);
          })
          .catch((err) => {
            console.error("Error: ", err);
          });
      });
  }

  render() {
    return (
      <div className="cl">
        From: &nbsp;
        <input type="date" id="from_date" />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To: &nbsp;
        <input type="date" id="to_date" />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button className="btn btn-primary" id="getdetails">
          Submit
        </button>
        <br />
        <br />
        <table className="table table-striped table-bordered" id="table">
          <thead>
            <tr style={{ backgroundColor: "#8fcbcd" }}>
              <th>Si No</th>
              <th>Post Date</th>
              <th>Date</th>
              <th>Employee Name</th>
              <th>Amount</th>
              <th>Remarks</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
        <br />
        <br />
        <button
          id="export_report"
          className="btn btn-primary"
          style={{ width: "110px", fontSize: "15px", float: "right" }}
        >
          Export Excel
        </button>
        <br />
        <br />
        <div className="modal micromodal-slide" id="modal-1" aria-hidden="true">
          <div className="modal__overlay" tabindex="-1" data-micromodal-close>
            <div
              style={{ width: "100%" }}
              className="modal__container"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-1-title"
            >
              <header className="modal__header">
                <h4 v="modal__title" id="modal-1-title">
                  Employee Salary Details
                </h4>
                <button
                  className="modal__close"
                  aria-label="Close modal"
                  data-micromodal-close
                ></button>
              </header>
              <main className="modal__content" id="modal-1-content">
                <form id="ViewEdit_form" method="post">
                  <fieldset id="modal1Fields">
                    Date :
                    <br />
                    <input
                      type="date"
                      id="add_date1"
                      className="form-control"
                    />
                    <br />
                    Employee Name :
                    <br />
                    <input
                      type="text"
                      id="employee_name1"
                      className="form-control"
                    />
                    <br />
                    Amount :
                    <br />
                    <input type="text" id="amount1" className="form-control" />
                    <br />
                    Remarks :
                    <br />
                    <textarea id="remarks1" className="form-control" />
                  </fieldset>
                  <button class="btn btn-primary" id="btnedit">
                    Save
                  </button>
                  <button class="btn btn-primary" id="btnclose">
                    Close
                  </button>
                </form>
              </main>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
class clk {
  hasClass(elem, className) {
    try {
      return elem.className.split(" ").indexOf(className) > -1;
    } catch (e) {
      return -1;
    }
  }
}
