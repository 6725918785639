/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";
import { whiteColor } from "assets/jss/material-dashboard-react";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        
        <p className={classes.right} style={{color: "white"}} >
          <span>
            &copy; {1900 + new Date().getYear()}{" "}
            <a
              href=""
              target="_blank"
              className={classes.a}
            >
             All Rights Reserved | iOtel Infotech Pvt Ltd
            </a>
            
          </span>
        </p>
      </div>
    </footer>
  );
}
