/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.js";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SendIcon from '@material-ui/icons/Send';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import StarBorder from '@material-ui/icons/StarBorder';


// icons for side dashboardmenu
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DetailsIcon from "@material-ui/icons/Details";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';


import "assets/css/styles.css";
const useStyles = makeStyles(theme => ({
  root: {
   
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
 const my_style={
      fontSize:"16px",textAlign:"center",paddingTop:"10px"
  };
export default function Sidebar(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
 const state = {
   open: true,
   open1: true
  };

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClick1 = () => {
    setOpen1(!open1);
  };

  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  const { color, logo, image, logoText, routes } = props;
   var activePro = " ";
        var listItemClasses;
        if (routes.path === "/upgrade-to-pro") {
          activePro = classes.activePro + " ";
          listItemClasses = classNames({
            [" " + classes[color]]: true
          });
        } else {
          listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(routes.layout + routes.path)
          });
        }
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(routes.layout + routes.path)
        });
  var links = (
          
                 <List
         
     
      aria-labelledby="nested-list-subheader"
      
      className={classes.root}
    >
     <NavLink
            to={"/suppliers/dashboard"}
            className={classes.item}
         
            //key={key}
          >
      <ListItem button >
     
        {/* <ListItemIcon>
          <SendIcon />
        </ListItemIcon> */}
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      </NavLink>
      <NavLink
            to={"/suppliers/user"}
            className={ classes.item}
            //activeClassName=""
            //key={key}
          >
      <ListItem button>
        {/* <ListItemIcon>
          <DraftsIcon />
        </ListItemIcon> */}
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </ListItem>
      </NavLink>
	  <NavLink
            to={"/suppliers/complaint_box"}
            className={ classes.item}
            //activeClassName=""
            //key={key}
          >
      <ListItem button>
        {/* <ListItemIcon>
          <DraftsIcon />
        </ListItemIcon> */}
        <ListItemIcon>
          <QuestionAnswerIcon />
        </ListItemIcon>
        <ListItemText primary="Complaint Box" />
      </ListItem>
      </NavLink>
	  
      
      <ListItem button onClick={handleClick}>
        {/* <ListItemIcon>
          <InboxIcon />
        </ListItemIcon> */}
        <ListItemIcon>
          <ArrowDropDownCircleIcon />
        </ListItemIcon>
        <ListItemText primary="Utilities" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit={true}>
        <List disablePadding>
                 
          <NavLink
            to={"/suppliers/reporting"}
            className={classes.item}
            //activeClassName=""
            //key={key}
          >
            <ListItem button className={classes.nested}>
              {/* <ListItemIcon>
          <DraftsIcon />
        </ListItemIcon> */}
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Reporting" />
            </ListItem>
          </NavLink>
        </List>
      </Collapse>

	  
      <ListItem button onClick={handleClick1}>
        {/* <ListItemIcon>
          <InboxIcon />
        </ListItemIcon> */}
        <ListItemIcon>
          <DetailsIcon />
        </ListItemIcon>
        <ListItemText primary="Details" />
        {open1 ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open1} timeout="auto" unmountOnExit={true}>
        <List  disablePadding>
        <NavLink
            to={"/suppliers/service_providers"}
            className={ classes.item}
            //activeClassName=""
            //key={key}
          >
      <ListItem button className={classes.nested}>
        {/* <ListItemIcon>
          <DraftsIcon />
        </ListItemIcon> */}
        <ListItemIcon>
          <SettingsApplicationsIcon />
        </ListItemIcon>
        <ListItemText primary="Service Providers" />
      </ListItem>
      </NavLink>
      
      <NavLink
            to={"/suppliers/vendors"}
            className={ classes.item}
            //activeClassName=""
            //key={key}
          >
      <ListItem button className={classes.nested}>
        {/* <ListItemIcon>
          <DraftsIcon />
        </ListItemIcon> */}
        <ListItemIcon>
           <RestaurantMenuIcon />
        </ListItemIcon>
        <ListItemText primary="Vendors" />
      </ListItem>
      </NavLink>
      
        </List>
      </Collapse>
    </List>
                
                );
  var brand = (
    <div className={classes.logo}>
      <a
        href="#"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
        target="_blank"
      >
        <div className={classes.logoImage}  style={my_style}>
          <img src={logo} alt="" className={classes.img} />
           {logoText}
        </div>
       
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
