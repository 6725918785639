import React, { useState, useEffect } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import url from "assets/js/config.js";

// *********** icons for dashboard inner boxs ***********//
import ListAltIcon from "@material-ui/icons/ListAlt";
import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import PageviewIcon from "@material-ui/icons/Pageview";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ContactsIcon from "@material-ui/icons/Contacts";

// *********** Table Scrollbar ************ //
import TableScrollbar from "react-table-scrollbar";

// ********** News Ticker **************//
import Ticker from "react-ticker";
import Marquee from "react-marquee-master";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import { bugs, website, server } from "variables/general.js";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { css } from "jquery";

const useStyles = makeStyles(styles);


const GeAnnouncmentsFromAPI = () => {
  const [announcments, setAnnouncments] = useState("");
  useEffect(() => {
    async function fetchData() {
      var ootel_auth_token = localStorage.getItem("ootel_auth_token");
      var msisdn = localStorage.getItem("ootel_msisdn");
      var postData = {
        msisdn: msisdn,
        auth_token: ootel_auth_token,
        roles: "employee",
      };
      var arr=[];
      const announcmentsFromAPI = await fetch(url.server_url + "/get_announcement_byrole", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      }).then((res) => res.json())
      .then((data) => {
        try {
          if (data.error.length != 0) {
            alert(data.error);
            return;
          }
        } catch (e) {}
        if (data.data.length > 0) {
           arr=[]
          for (var i = 0; i < data.data.length; i++) {
            arr.push(data.data[i].content);
          }              
        }
      })
      .catch((err) => {
        console.error("Error: ", err);
      });
      setAnnouncments(arr);
    }     
    fetchData();
  }, []);
  // A placeholder is needed, to tell react-ticker, that width and height might have changed
  // It uses MutationObserver internally
  //alert(announcments);
  return announcments ? (
   
    <p style={{ whiteSpace: "nowrap" }}>{ announcments.join(" , ")} , </p>
  ) : (
    <p style={{ visibility: "hidden" }}>Placeholder</p>
  );
};


export default function Dashboard() {
  const classes = useStyles();
  return (
    <div>
      <div
        style={{
          backgroundColor: "lightgray",
          paddingTop: "5px",
          height: "42px",
          border: "3px solid #26537c",
          borderRadius: "8px",
        }}
      >
        <Ticker offset="run-in" speed={5}>
          {({}) => <GeAnnouncmentsFromAPI />}
        </Ticker>
      </div>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={12} md={2}></GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <a href="#">
            <Card chart style={{ backgroundColor: "rgb(84 209 233)" }}>
              <CardHeader stats icon>
                <span
                  stats
                  icon
                  style={{
                    float: "right",
                    marginTop: "65px",
                    marginBottom: "20px",
                  }}
                >
                  <CardIcon
                    color="info"
                    style={{
                      background:
                        "repeating-radial-gradient(rgb(77 228 231), transparent 100px)",
                      boxShadow:
                        "rgba(0, 0, 0, 0.14) 0px 4px 20px 0px, rgb(68 202 219 / 40%) 0px 7px 10px -5px",
                    }}
                  >
                    <PageviewIcon />
                  </CardIcon>
                </span>
                <h6
                  className={classes.cardTitle}
                  style={{ float: "left", paddingTop: "70px", color: "white" }}
                >
                  Search Jobs
                </h6>
              </CardHeader>
              <CardBody>
                {/*<p className={classes.cardCategory}>Last Campaign Performance</p> */}
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> campaign sent 2 days ago
                </div>
              </CardFooter>
            </Card>
          </a>
        </GridItem>
        <GridItem xs={12} sm={12} md={1}></GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <a href="#">
            <Card chart style={{ backgroundColor: "darkorange" }}>
              <CardHeader stats icon>
                <span
                  stats
                  icon
                  style={{
                    float: "right",
                    marginTop: "65px",
                    marginBottom: "20px",
                  }}
                >
                  <CardIcon
                    color="info"
                    style={{
                      background:
                        "repeating-radial-gradient(rgb(244 167 103), transparent 100px)",
                      boxShadow:
                        "rgba(0, 0, 0, 0.14) 0px 4px 20px 0px, rgb(244 156 27 / 40%) 0px 7px",
                    }}
                  >
                    <ListAltIcon />
                  </CardIcon>
                </span>
                <h6
                  className={classes.cardTitle}
                  style={{ float: "left", paddingTop: "70px", color: "white" }}
                >
                  Abroad Jobs
                </h6>
              </CardHeader>
              <CardBody>
                {/*<p className={classes.cardCategory}>Last Campaign Performance</p> */}
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> campaign sent 2 days ago
                </div>
              </CardFooter>
            </Card>
          </a>
        </GridItem>
        <GridItem xs={12} sm={12} md={1}></GridItem>
      </GridContainer>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={12} md={2}></GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <a href="#">
            <Card chart style={{ backgroundColor: "crimson" }}>
              <CardHeader stats icon>
                <span
                  stats
                  icon
                  style={{
                    float: "right",
                    marginTop: "65px",
                    marginBottom: "20px",
                  }}
                >
                  <CardIcon
                    color="info"
                    style={{
                      background:
                        "repeating-radial-gradient(rgb(242, 118, 118), transparent 100px)",
                      boxShadow:
                        "rgba(0, 0, 0, 0.14) 0px 4px 20px 0px, rgb(244 87 71 / 63%) 0px 7px 10px -5px",
                    }}
                  >
                    <AccountBalanceWalletIcon />
                  </CardIcon>
                </span>
                <h6
                  className={classes.cardTitle}
                  style={{ float: "left", paddingTop: "70px", color: "white" }}
                >
                  E-Wallet
                </h6>
              </CardHeader>
              <CardBody>
                {/*<p className={classes.cardCategory}>Last Campaign Performance</p> */}
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> campaign sent 2 days ago
                </div>
              </CardFooter>
            </Card>
          </a>
        </GridItem>

        <GridItem xs={12} sm={12} md={1}></GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <a href="#">
            <Card chart style={{ backgroundColor: "rgb(90 24 160)" }}>
              <CardHeader stats icon>
                <span
                  stats
                  icon
                  style={{
                    float: "right",
                    marginTop: "65px",
                    marginBottom: "20px",
                  }}
                >
                  <CardIcon
                    color="info"
                    style={{
                      background:
                        "repeating-radial-gradient(rgb(190 148 227 / 87%), transparent 100px)",
                      boxShadow:
                        "rgba(0, 0, 0, 0.14) 0px 4px 20px 0px, rgb(140 104 161 / 64%) 0px 7px 10px -5px",
                    }}
                  >
                    <ContactsIcon />
                  </CardIcon>
                </span>
                <h6
                  className={classes.cardTitle}
                  style={{ float: "left", paddingTop: "64px", color: "white" }}
                >
                  Employment <br />{" "}
                  <span style={{ marginRight: "46px" }}>History</span>
                </h6>
              </CardHeader>
              <CardBody>
                {/* <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p> */}
              </CardBody>
              <CardFooter chart>
                <div className={classes.stats}>
                  <AccessTime /> updated 4 minutes ago
                </div>
              </CardFooter>
            </Card>
          </a>
        </GridItem>
      </GridContainer>
    </div>
  );
}
